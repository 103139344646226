import React, { useState, useContext, useEffect } from 'react';
import './App.css';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  Link
} from 'react-router-dom';

import UserContext from './context/UserContext';
import AuthContext from './context/AuthContext';

import axios from 'axios';
import Cookies from 'js-cookie';
import url from './api/url';
import PrivateRoute from './PrivateRoute';

import DashboardLayout from './components/dashboard/DashboardLayout';
import Forgot from './components/authentication/Forgot';

import EposLayout from './components/dashboard/epos/EposLayout'; 

import 'react-toastify/dist/ReactToastify.css';
import './assets/vendor/icon-set/style.css'
import AddTeamSignup from './components/authentication/verification/AddTeamSignup';
import MainLoader from './components/general/loaders/MainLoader';
import makeApolloClient from './apollo/apollo';
import { ApolloProvider } from '@apollo/client';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { darkThemeOptions, lightThemeOptions, theme } from './constants/theme';
import { CssBaseline } from '@mui/material';
import AuthContainer from './components/authentication/AuthContainer';


function App() {

  const [userData, setUserData] = useState({ loaded: false });
  const [auth, setAuth] = useState(false);

  const [loading, setLoading] = useState(true);

  const [userSet, setUserSet] = useState(false);
  const [client, setClient] = useState(null);

  // useEffect(() => {
  //   const script = document.createElement("script");

  //       script.src="//js.hs-scripts.com/8350181.js";
  //       script.async = true;

  //       document.body.appendChild(script);
  // }, [])

  const createApolloClient = async() => {
    let client = await makeApolloClient();

    if(client.status === 401) {
      return setAuth(false);
    }

    setClient(client)
  }

  useEffect(() => {

    if(auth) {
      createApolloClient();
    } else {
      return
    }
  }, [auth])


  useEffect(() => {

    window.Intercom('shutdown');

    if(!userSet) {

      window.Intercom('boot', { 
  app_id: 'l5s8p5uj' 
});
      return;
    } else {

      window.Intercom('boot', {
     
  app_id: "l5s8p5uj",
  name: `${userData.first_name} ${userData.last_name}`,
  email: userData.email,
  user_id: userData.vizopay_id,
  phone: userData.phone,
  website: userData.website_url,
    user_type: "company",
    company_id: userData.company_id,
    stripe_id: userData.stripe_account_id,
    total_revenue: `£${(userData.total_revenue/100).toFixed(2)}`,
    carbon_offset: userData.carbon_offset,
    donation_fee: userData.donation_fee,
    can_accept_payments: userData.vizopay_status?.can_accept_payments,
    verification_passed: userData.vizopay_status?.verification_passed,


      })


      window.Intercom('update', {
     
  app_id: "l5s8p5uj",
  name: `${userData.first_name} ${userData.last_name}`,
  email: userData.email,
  user_id: userData.vizopay_id,
  phone: userData.phone,
  website: userData.website_url,
    user_type: "company",
    company_id: userData.company_id,
    stripe_id: userData.stripe_account_id,
    total_revenue: `£${(userData.total_revenue/100).toFixed(2)}`,
    carbon_offset: userData.carbon_offset,
    donation_fee: userData.donation_fee,
    can_accept_payments: userData.vizopay_status?.can_accept_payments,
    verification_passed: userData.vizopay_status?.verification_passed,



      })


    }

  }, [userData, userSet])
  
  const FetchUserData = async() => {

    const token = Cookies.get('token')

    await axios.get(`${url}/business/data/dashboard`,
        {
          headers: {
            'x-auth-token': token
          }
        }
      ).then(res => {
        setUserData(res.data) 
        setUserSet(true);
        setAuth(true);
      }).catch(error => {
        setAuth(false);
      })
  }

  useEffect(() => {

    const token = Cookies.get('token')

    const authenticate = async () => {
      await axios.get(`${url}/business/data/dashboard`,
        {
          headers: {
            'x-auth-token': token,

          }
        }
      ).then(res => {
        setUserData(res.data) 
        setUserSet(true);
        setAuth(true);
        setLoading(false)
      }).catch(error => {
        setAuth(false);
        setUserSet(false)
        setLoading(false)
      })
    }

    authenticate();

  }, [auth])

  const [mode, setMode] = React.useState("light");


  const lightTheme = React.useMemo(
    () =>
      createTheme(lightThemeOptions),
    [mode]
  );

  const darkTheme = React.useMemo(
    () =>
      createTheme(darkThemeOptions),
    [mode]
  );



    return (
      <>
        <ThemeProvider theme={mode==="light" ? lightTheme : darkTheme}>
    <CssBaseline />

      <AuthContext.Provider value={{ auth, setAuth }}>
        <UserContext.Provider value={{ userData, setUserData }}>
          <Router>
            {
              loading ? <MainLoader />
                :
                <>
                  <Switch>
                    <Route exact path="/team/signup" component={AddTeamSignup} />
                    <Route exact path="/forgot" component={Forgot} />
                    <Route exact path="/forgot/:code" component={Forgot} />

                    <Route exact path="/login">
                      {auth ? <Redirect to={{
                        pathname: "/",
                        state: { from: "/login" }
                      }} /> : <AuthContainer />}

                    </Route>

                    <Route exact path="/charge">
                      <PrivateRoute component={EposLayout} />
                    </Route>
                      {
                        client ?
                        <ApolloProvider client={client}>
                         <PrivateRoute path="/" component={() => <DashboardLayout FetchUserData={FetchUserData} userSet={userSet} />} />
                        </ApolloProvider>
                      :

                        !auth ?

                        <Redirect to={{
                          pathname: "/login",
                          state: { from: "/" }
                        }} />

                        :
                        <MainLoader />
                      }
                  </Switch>
                </>
            }
          </Router>
        </UserContext.Provider>
      </AuthContext.Provider>
      </ThemeProvider>
    </>
  );
}


export default App;