import { Grid, TextField, Typography } from '@mui/material'
import { Box, Stack } from '@mui/system'
import React from 'react'
import BasicDatePicker from '../../../../../elements/DatePicker'
import RewardSummary from '../rewards/RewardSummary'

type Props = {}

export const CampaignSummary = (props: Props) => {
  return (
   <RewardSummary />
  )
}