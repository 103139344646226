import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { ISidebarMenu, items } from './SideMenu'
import './SideNavigator.css'

import {Link, useLocation} from 'react-router-dom';

type Props = {
    item: ISidebarMenu
}



const SideItem = ({
    item
}: Props) => {

    let location = useLocation();

    React.useEffect(() => {
        console.log("h", location.pathname)
    }, [location])

  return (
    <Link to={item.route} className={item.route === location.pathname ? "sideItemsContainerSelected" : "sideItemsContainer"}>
        <FontAwesomeIcon color="#fff" icon={item.icon}/>
        <span style={styles.text}>{item.text}</span>
    </Link>
  )
}

export default SideItem

const styles = {
    text: {
        color: '#fff',
        fontSize: 16,
        fontWeight: '500',
        marginLeft: 10
    }
}