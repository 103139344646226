import React from 'react'

function StoreBanner(props) {

    const { match } = props;

    return (
        <div>
            
        </div>
    )
}

export default StoreBanner
