import React from 'react'
import invoiceLogo from '../../assets/images/invoice.svg'

function Welcome({setInvoice}) {
    return (
        <main id="content" role="main" className="main">
        {/* Content */}
        <div className="content @@layoutBuilder.header.containerMode">
          <div className="row justify-content-sm-center text-center py-10">
            <div className="col-sm-7 col-md-5">
              <img className="img-fluid mb-5" src={invoiceLogo} alt="Image Description" style={{maxWidth: '18rem'}} />
              <h1>Invoice generator!</h1>
              <p>Sending an invoice is the easiest and quickest way to start showcasing your constibution to sustainability.</p>
              <a onClick={() => {setInvoice(true)}} className="btn btn-success">Create an invoice</a>
            </div>
          </div>
          {/* End Row */}
        </div>
        {/* End Content */}
      </main>
    )
}

export default Welcome
