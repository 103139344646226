import React, {useState, useContext} from 'react'
import './navigation.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome, faCreditCard, faFileInvoice, faWallet, faTable, faAngleDown, faAngleUp, faUser } from '@fortawesome/free-solid-svg-icons'
import {Link, useRouteMatch} from 'react-router-dom'
import {Button, Row, Col, Container} from 'reactstrap';

import logo from '../../../assets/images/Vizopay Logo-01.png'

import SideBarSettings from './menus/SideBarSettings'

import UserContext from '../../../context/UserContext';


function SideBar() {

    const [menuSettings, setMenuSettings] = useState(false);
    const {userData} = useContext(UserContext)


    const sideMenu = [
        {
            route: '/',
            external: null,
            icon: faHome,
            text: 'Home'
        },
        {
            route: '/payments',
            external: null,
            icon: faCreditCard,
            text: 'Payments'
        },
        {
            route: '/customers',
            external: null,
            icon: faUser,
            text: 'Customers'
        },
        // {
        //     route: '/campaigns',
        //     external: null,
        //     icon: faTable,
        //     text: 'Campaigns'
        // },
        // {
        //     route: '/invoice',
        //     external: null,
        //     icon: faFileInvoice,
        //     text: 'Invoice'
        // },
        {
            route: '/charge',
            external: 'https://terminal.vizopay.com',
            icon: faWallet,
            text: 'ePOS'
        },
        {
            route: '/stores',
            external: null,
            icon: faTable,
            text: 'Stores'
        },
        // {
        //     route: '/products',
        //     external: null,
        //     icon: faTable,
        //     text: 'Products'
        // },
    ]


    const unverifiedSideMenu = [
        {
            route: '/',
            external: null,
            icon: faHome,
            text: 'Home'
        },
        {
            route: '/payments',
            external: null,
            icon: faCreditCard,
            text: 'Payments'
        },
        {
            route: '/customers',
            external: null,
            icon: faUser,
            text: 'Customers'
        },
        {
            route: '/campaigns',
            external: null,
            icon: faTable,
            text: 'Stores'
        },
        // {
        //     route: '/invoice',
        //     external: null,
        //     icon: faFileInvoice,
        //     text: 'Invoice'
        // },
        {
            route: '/stores',
            external: null,
            icon: faTable,
            text: 'Stores'
        },
        // {
        //     route: '/products',
        //     external: null,
        //     icon: faTable,
        //     text: 'Products'
        // },
    ]
    return (
        <div className="side-navigator">
            <div style={{fontSize:"1.7rem",color:"white",fontWeight:"600",paddingTop:"1rem"}}>{userData.company_name}</div>
            <div style={{paddingTop:"2rem"}}className="sidenav-menu">
                <div className="sidenav-wrapper">
                        {
                            userData.vizopay_status?

                            <> 
                            {userData.vizopay_status.verification_passed ? sideMenu.map(data => {
                            return(
                                !data.external ?

                                <Link to={data.route}>
                    <div className="flex-list">
                            <div className="icon-wrapper">
                                <FontAwesomeIcon icon={data.icon}/>
                            </div>
                            <span>
                                {data.text}
                            </span>
                    
                    </div>
                        </Link>

                        :

                        <a href={data.external} target="__blank">
                    <div className="flex-list">
                            <div className="icon-wrapper">
                                <FontAwesomeIcon icon={data.icon}/>
                            </div>
                            <span>
                                {data.text}
                            </span>
                    
                    </div>
                        </a>
                            )
                        }): unverifiedSideMenu.map(data => {
                            return(
                                !data.external ?

                                <Link to={data.route}>
                    <div className="flex-list">
                            <div className="icon-wrapper">
                                <FontAwesomeIcon icon={data.icon}/>
                            </div>
                            <span>
                                {data.text}
                            </span>
                    
                    </div>
                        </Link>

                        :

                        <a href={data.external} target="__blank">
                    <div className="flex-list">
                            <div className="icon-wrapper">
                                <FontAwesomeIcon icon={data.icon}/>
                            </div>
                            <span>
                                {data.text}
                            </span>
                    
                    </div>
                        </a>
                            )
                        })
                        
                        
                        }
                            </>

                            :

                            null
                        }

                </div>
            </div>
            <div>
                <span style={{color: '#fff'}}>v1.0.0</span>
            </div>
            <div>
                {
                    menuSettings

                    ?

                    <SideBarSettings menuSettings={menuSettings} setMenuSettings={setMenuSettings} />

                    :

                    null

                }
            </div>
             
        </div>
    )
}

export default SideBar
