import React from 'react'

function InvoiceBillTo() {
    return (
                          <div className="row mb-3">
                    <div className="col-md-5">
                      {/* Form Group */}
                      <div className="form-group">
                        <label htmlFor="invoiceAddressToLabel" className="input-label">Bill to:</label>
                        <textarea className="form-control" placeholder="Who is this invoice from?" id="invoiceAddressToLabel" aria-label="Who is this invoice from?" rows={3} defaultValue={""} />
                      </div>
                      {/* End Form Group */}
                    </div>
                    <div className="col-md-7 align-self-md-end">
                      {/* Form Group */}
                      <div className="form-group">
                        <dl className="row align-items-sm-center mb-3">
                          <dt className="col-sm-4 col-md text-sm-right mb-2 mb-sm-0">Invoice date:</dt>
                          <dd className="col-sm-8 col-md-auto mb-0">
                            {/* Flatpickr */}
                            <div id="invoiceDateFlatpickr" className="js-flatpickr flatpickr-custom input-group input-group-merge" data-hs-flatpickr-options="{
                                &quot;appendTo&quot;: &quot;#invoiceDateFlatpickr&quot;,
                                &quot;dateFormat&quot;: &quot;d/m/Y&quot;,
                                &quot;wrap&quot;: true
                              }">
                              <div className="input-group-prepend" data-toggle>
                                <div className="input-group-text">
                                  <i className="tio-date-range" />
                                </div>
                              </div>
                              <input type="text" className="flatpickr-custom-form-control form-control" placeholder="Select dates" data-input defaultValue="29/06/2020" />
                            </div>
                            {/* End Flatpickr */}
                          </dd>
                        </dl>
                        <dl className="row align-items-sm-center">
                          <dt className="col-sm-4 col-md text-sm-right mb-2 mb-sm-0">Due date:</dt>
                          <dd className="col-sm-8 col-md-auto mb-0">
                            {/* Flatpickr */}
                            <div id="invoiceDueDateFlatpickr" className="js-flatpickr flatpickr-custom input-group input-group-merge" data-hs-flatpickr-options="{
                                &quot;appendTo&quot;: &quot;#invoiceDueDateFlatpickr&quot;,
                                &quot;dateFormat&quot;: &quot;d/m/Y&quot;,
                                &quot;wrap&quot;: true
                              }">
                              <div className="input-group-prepend" data-toggle>
                                <div className="input-group-text">
                                  <i className="tio-date-range" />
                                </div>
                              </div>
                              <input type="text" className="flatpickr-custom-form-control form-control" placeholder="Select dates" data-input defaultValue="29/06/2020" />
                            </div>
                            {/* End Flatpickr */}
                          </dd>
                        </dl>
                      </div>
                      {/* End Form Group */}
                    </div>
                  </div>
    )
}

export default InvoiceBillTo
