import React, { useState } from "react";
import { Row, Container, Col, Button} from 'reactstrap';
import {PDFDownloadLink} from '@react-pdf/renderer';

import './reports.css';
import ReportPDF from "./additional/ReportsPDF";
import { useQuery } from "@apollo/client";
import { FETCH_INVOICE_DETAILS } from "../../../apollo/queries";
import ReportsPDFNew from "./additional/ReportsPDFNew";


interface IReportHero {
    totalVolume?: number;
    platformFee?: number; 
    carbonOffset?: number;
    dateFrom?: Date;
    dateEnd?: Date;
}

let dollarUSLocale = Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' });

function ReportsSummary({totalVolume, platformFee, carbonOffset, dateEnd, dateFrom}: IReportHero) {

  const {data, loading, error} = useQuery(FETCH_INVOICE_DETAILS);

  React.useEffect(() => {
console.log(data, "INVOICE")
  }, [data])

  return (
      <>
    <div >
      <Container fluid className="reports-summary-container">
        <div className="reports-component-card">
          
            <div className="reports-component-top-top">
              <h2> Summary </h2>
              <p> Shows the total sales you have received through Vizopay between the dates selected above. </p>
            </div>

            <div className="reports-component-top-grey">
              <div className="reports-component-amounts">
                <p> Gross Sales</p>
                <p> {dollarUSLocale.format(totalVolume - platformFee)} </p>
              </div>

              <div className="reports-component-amounts-inside">
                <p> Platform Fee</p>
                <p> -{dollarUSLocale.format(platformFee - carbonOffset)} </p>
              </div>

              <div className="reports-component-amounts-inside">
                <p> Carbon Offsetting </p>
                <p> -{dollarUSLocale.format(carbonOffset)} </p>
              </div>

              <div className="reports-component-amounts-inside">
                <p> Refunds / Promotional Vouchers </p>
                <p> {dollarUSLocale.format(platformFee)} </p>
              </div>

            </div> 

            <div className="reports-component-top">
            <div className="reports-component-amounts">
                <p> Net Sales (GBP) </p>
                <p className="reports-total"> {dollarUSLocale.format(totalVolume - platformFee + platformFee)} </p>
              </div>
            </div>            

        </div>

        <div className="reports-button-contaniner">
              <Button > 
              {
                loading ?

                "Please wait..."

              :
              <PDFDownloadLink 
                className="reports-button"
                document={<ReportsPDFNew data={data} totalVolume={totalVolume} platformFee={platformFee} carbonOffset={carbonOffset} dateFrom={dateFrom} dateEnd={dateEnd} />}
                fileName={`Report.pdf`}
                
                  >
                    {({ blob, url, loading, error }) =>
                  error ? console.log(error) : loading ? "Loading document..." : 
                  
                  "Download Report"
                }
            </PDFDownloadLink>
              }
            </Button>
        </div> 

      </Container>
    </div>

    </>
  );
}

export default ReportsSummary;