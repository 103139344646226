import React, {useContext,useState,useEffect} from 'react'
import axios from "axios";
import url from '../../../api/url';
import Cookies from 'js-cookie';
import AuthContext from "../../../context/AuthContext";
import UserContext from '../../../context/UserContext';

function SummaryRow() {

    const {userData} = useContext(UserContext);
    const [customers,setCustomers] = useState([]);
    const { auth, setAuth } = useContext(AuthContext);
    useEffect(() => {   
      const getCustomerData = async() => {
        await axios.get(`${url}/business/customers`, {
        headers: {
            'x-auth-token': Cookies.get('token')
        }
    }).then((res) => {
      setCustomers(res.data)
    }).catch((e) => {
      if(e.response.data.message === "jwt expired") {
        Cookies.remove('token');
        setAuth(false);
      }
      console.log(e);
    })
  }
  getCustomerData();

  },[]);




    const stats = [
      {
        title: "Trees Planted",
        quantity: Math.floor(userData.trees_planted),
        prependMetric: "",
        appendMetric: "",
        percentageChange: "",

      },
      {
        title: "CO2 Sequestered",
        quantity: userData.carbon_offset === 1000 ? ((userData.carbon_offset/1000).toFixed(2) + " tonne") : userData.carbon_offset > 1000 ? ((userData.carbon_offset/1000).toFixed(2) + " tonnes") : (userData.carbon_offset).toFixed(2) + " kg",
        prependMetric: "",
        appendMetric: "",
        percentageChange: "",
      },
      {
        title: "Customers",
        quantity: customers?.length,
        prependMetric: "",
        appendMetric: "",
        percentageChange: "",

      },
    ]

    return (
      <div style={{margin: "20px auto"}} className="row gx-2 gx-lg-3">
        
        {
          stats.map(data => {
            return(
              <div className="col-sm-12 col-lg-4 mb-3 mb-lg-5">
          {/* Card */}
          <a className="card card-hover-shadow h-100" href="#">
            <div className="card-body">
              <h5 className="card-subtitle">{data.title}</h5>
              <div className="row align-items-center gx-2 mb-1">
                <div className="col-6">
                  <span style={{fontSize: '1.4rem'}} className=" h3">{data.quantity.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                </div>
                <div className="col-6">
                  {/* Chart */}
                  <div className="chartjs-custom" style={{height: '3rem'}}>
                    <canvas className="js-chart" data-hs-chartjs-options="{
                                &quot;type&quot;: &quot;line&quot;,
                                &quot;data&quot;: {
                                   &quot;labels&quot;: [&quot;1 May&quot;,&quot;2 May&quot;,&quot;3 May&quot;,&quot;4 May&quot;,&quot;5 May&quot;,&quot;6 May&quot;,&quot;7 May&quot;,&quot;8 May&quot;,&quot;9 May&quot;,&quot;10 May&quot;,&quot;11 May&quot;,&quot;12 May&quot;,&quot;13 May&quot;,&quot;14 May&quot;,&quot;15 May&quot;,&quot;16 May&quot;,&quot;17 May&quot;,&quot;18 May&quot;,&quot;19 May&quot;,&quot;20 May&quot;,&quot;21 May&quot;,&quot;22 May&quot;,&quot;23 May&quot;,&quot;24 May&quot;,&quot;25 May&quot;,&quot;26 May&quot;,&quot;27 May&quot;,&quot;28 May&quot;,&quot;29 May&quot;,&quot;30 May&quot;,&quot;31 May&quot;],
                                   &quot;datasets&quot;: [{
                                    &quot;data&quot;: [21,20,24,20,18,17,15,17,18,30,31,30,30,35,25,35,35,40,60,90,90,90,85,70,75,70,30,30,30,50,72],
                                    &quot;backgroundColor&quot;: [&quot;rgba(55, 125, 255, 0)&quot;, &quot;rgba(255, 255, 255, 0)&quot;],
                                    &quot;borderColor&quot;: &quot;@@vars.style.color&quot;,
                                    &quot;borderWidth&quot;: 2,
                                    &quot;pointRadius&quot;: 0,
                                    &quot;pointHoverRadius&quot;: 0
                                  }]
                                },
                                &quot;options&quot;: {
                                   &quot;scales&quot;: {
                                     &quot;yAxes&quot;: [{
                                       &quot;display&quot;: false
                                     }],
                                     &quot;xAxes&quot;: [{
                                       &quot;display&quot;: false
                                     }]
                                   },
                                  &quot;hover&quot;: {
                                    &quot;mode&quot;: &quot;nearest&quot;,
                                    &quot;intersect&quot;: false
                                  },
                                  &quot;tooltips&quot;: {
                                    &quot;postfix&quot;: &quot;k&quot;,
                                    &quot;hasIndicator&quot;: true,
                                    &quot;intersect&quot;: false
                                  }
                                }
                              }">
                    </canvas>
                  </div>
                  {/* End Chart */}
                </div>
              </div>
              {/* End Row */}
            </div>
          </a>
          {/* End Card */}
        </div>
            )
          })
        }
        
      </div>
    )
}

export default SummaryRow
