import React, {useState, useContext} from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome, faCreditCard, faFileInvoice, faWallet, faTable, faAngleDown, faAngleUp, faUser } from '@fortawesome/free-solid-svg-icons'
import {Link, useRouteMatch} from 'react-router-dom'
import { Row, Col, Container} from 'reactstrap';

import logo from '../../../assets/images/Vizopay Logo-01.png'

import SideBarSettings from '../menus/SideBarSettings'

import UserContext from '../../../../context/UserContext';

import './SideNavigator.css'
import { items } from './SideMenu';
import SideItem from './SideItem';
import { Button } from '@mui/material';
import AuthContext from '../../../../context/AuthContext';
import { Logout } from '../../../../utils/auth';
import Cookies from 'js-cookie';

type Props = {
    
}

function SideNavigator() {

    const [menuSettings, setMenuSettings] = useState(false);
    const {userData} = useContext(UserContext)
    const {setAuth} = useContext(AuthContext);

    const handleLogout = () => {
        Cookies.remove('token');

        setAuth(false);
    }

    return (
        <div className="sideNavigator">
            <div style={{fontSize:"1.7rem",color:"white",fontWeight:"600",paddingTop:"1rem"}}>{userData.company_name}</div>
  
            <div style={{
                display: 'flex',
                width: '100%',
                flexDirection: 'column',
                marginTop: 20
            }}>
            {
                items.map((item) => {
                    return(
                        <SideItem item={item}/>
                    )
                })
            }
            </div>
            
            <div style={{
                position: 'absolute',
                bottom: 20,
                display: 'flex',
                width: '100%',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column'
            }}>
                <div style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    paddingBottom: 20,
                    paddingTop: 20,
                }}>
                    <Button onClick={() => handleLogout()} style={{
                        width: '90%',
                        height: 44
                    }} variant="outlined" color="error">Logout</Button>
                </div>
                <span style={{color: '#fff'}}>v1.1.0</span>
            </div>
             
        </div>
    )
}

export default SideNavigator
