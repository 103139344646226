import React, { useState, useContext, useEffect } from 'react'
import { Container, Row, Col, Form, FormGroup, Input, Label, Button, Tooltip } from 'reactstrap';
import Slider from '@material-ui/core/Slider';
import url from '../../../api/url.js';
import Cookies from "js-cookie"
import axios from 'axios';
import "./profileSettings.css";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import AuthContext from '../../../context/AuthContext.js';
import UserContext from '../../../context/UserContext';
import InputAppend from '../../general/input/InputAppend';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy, faCheck } from '@fortawesome/free-solid-svg-icons'
import AlertSuccess from '../../general/alerts/AlertSuccess';

import { ToastContainer, toast } from 'react-toastify';

function PlatformSettings() {

  const { userData } = useContext(UserContext);
  const { auth, setAuth } = useContext(AuthContext);
  const [donationFee, setDonationFee] = useState();
  const [submitted,setSubmitted] = useState(false);

  const marks = [
    {
      value: 0.005,
      label: '0.5%',
    },
    {
      value: 0.01,
      label: '1%',
    },
    {
      value: 0.015,
      label: '1.5%',
    },
    {
      value: 0.02,
      label: '2%',
    },
    {
      value: 0.025,
      label: '2.5%',
    },
    {
      value: 0.03,
      label: '3%',
    },
    {
      value: 0.035,
      label: '3.5%',
    },
    {
      value: 0.04,
      label: '4%',
    },
    {
      value: 0.045,
      label: '4.5%',
    },
    {
      value: 0.05,
      label: '5%',
    },
    {
      value: 0.055,
      label: '5.5%',
    },
    {
      value: 0.06,
      label: '6%',
    },
    {
      value: 0.065,
      label: '6.5%',
    },
    {
      value: 0.07,
      label: '7%',
    },
    {
      value: 0.075,
      label: '7.5%',
    },
    {
      value: 0.08,
      label: '8%',
    },
    {
      value: 0.085,
      label: '8.5%',
    },
    {
      value: 0.09,
      label: '9%',
    },
    {
      value: 0.095,
      label: '9.5%',
    },
    {
      value: 0.1,
      label: '10%',
    },
  ];
  const handleSubmit = async (event) => {
    event.preventDefault();

    if(donationFee) {
        await axios.put(`${url}/business/settings/donation`,{donation_fee:donationFee}, {
            headers: {
                "x-auth-token": Cookies.get("token")
            }
        })
        .then(res => {
            if(res.status == 204) {
                setSubmitted(true);
            }
        })
        .catch((e) => 
        {
          if(e.response.data.message === "jwt expired") {
            Cookies.remove('token');
            setAuth(false);
          }
          console.log(e)
        }
        
        ) 
    }
    }


/*
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const [clipboard, setClipboard] = useState({
    value: '',
    copied: false
  });

  const [click, setClick] = useState(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);

  useEffect(() => {

    if (clipboard.copied) {
      toast.info("Copied to clipboard!");
    }

    const interval = setInterval(() => {
      setClipboard({
        value: '',
        copied: false
      })
    }, 4000)

    return () => clearInterval(interval)

  }, [clipboard])
  */

  return (
    <Form onSubmit={handleSubmit}>
      <Col>
        <FormGroup>
          <Label>Vizopay id</Label>
          <Input readOnly placeholder={userData.vizopay_id} />
        </FormGroup>
        {/*
        <FormGroup>

          <CopyToClipboard text={userData.public_api_key}
            onCopy={() => setClipboard({ value: userData.public_api_key, copied: true })}>

            <InputAppend readOnly={true} id="inputAppend" clickable value={userData.public_api_key} label="Public API Key" icon={<FontAwesomeIcon icon={clipboard.copied ? faCheck : faCopy} />} />

          </CopyToClipboard>
        </FormGroup>

        <FormGroup>
          <Button>Reveal Secret Key</Button>
        </FormGroup>
        */}
        {/* <FormGroup>
          <Label>Donation Amount</Label>
          <div className="slider-container">
            <Slider
              onChange={(e, newValue) => setDonationFee(newValue)}
              color="#65ccb8"
              defaultValue={`${userData.donation_fee}`}
              aria-labelledby="range-slider"
              step={0.005}
              min={0.005}
              max={0.1}
              marks={marks}

            />
            <Button >Save</Button>
            {submitted && <div className='success-message'>Donation Amount Updated Successfully!!</div>}
          </div>
        </FormGroup> */}
        {/*
        <FormGroup>
          <Label>Donation Allotment</Label>
          <Row>
            <Col>
              <span>Technology</span>
              <Input />
            </Col>
            <Col>
              <span>Traditional</span>
              <Input />
            </Col>
            <Col>
              <span>Verified Carbon Capture</span>
              <Input />
            </Col>
          </Row>
        </FormGroup>
        */}
        <FormGroup>
          <Label>Status</Label>
          <Input readOnly value={userData.vizopay_status.verification_passed === true ? "Active" : "Inactive"} />
        </FormGroup>
      </Col>
      <Col>

      </Col>

    </Form>
  )
}

export default PlatformSettings
