import React, { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import InputBase from '@mui/material/InputBase';
import { Box, styled } from '@mui/material';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import SettingsIcon from '@material-ui/icons/Settings';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import HelpIcon from '@material-ui/icons/Help';
import Tooltip from '@mui/material/Tooltip';
import Cookies from 'js-cookie';
import AuthContext from '../../../context/AuthContext';
import { useHistory } from "react-router-dom";
import MobileMenu from './menus/MobileMenu';
import { SwipeableDrawer } from '@mui/material';
import Badge from '@mui/material/Badge';
import AccountCircle from '@material-ui/icons/AccountCircle';

import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import MailIcon from '@material-ui/icons/Mail';
import NotificationsIcon from '@material-ui/icons/Notifications';
import MoreIcon from '@material-ui/icons/MoreVert';
import {ClickAwayListener} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Input, InputGroupAddon, InputGroupText, InputGroup } from 'reactstrap';
import Account from './menus/Account';
import SearchSuggest from './SearchSuggest';
import { Link } from 'react-router-dom';

const useStyles = styled((theme) => ({
  root: {
    flexGrow: 1,
    borderBottom: '1px solid #eceff1',
  },
  fullmenu:{
    display: 'none',
    [theme.breakpoints.up(800)]: {
      display: 'flex',
    },
  },
  mobileMenu:{
    display: 'flex',
    justifyContent:"flex-end",
    [theme.breakpoints.up(800)]: {
      display: 'none',
    },
  },
  mobileMenuContainer:{
    [theme.breakpoints.up(800)]: {
      display: 'none',
    }
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    display: 'none',
    [theme.breakpoints.up(800)]: {
      display: 'block',
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.common.white,
    },
    marginLeft: 0,
    width: '100%',
    display:'none',
    [theme.breakpoints.up(800)]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}));

export default function TopNav({ openDrawer, setDrawer }) {
  const classes = useStyles();
  const history = useHistory();
  const [openMenu,setOpenMenu] = useState(false);
  const [accountContainer, setAccountContainer] = useState(false)
  const [searchSuggest, setSearchSuggest] = useState(false)

  const [searchSpinner, setSearchSpinner] = useState(false);

  const [searchSuggestion, setSearchSuggestion] = useState('');

  const { auth, setAuth } = React.useContext(AuthContext)


  const handleLogout = () => {
    Cookies.remove('token');
    setAuth(false);
  }

  function searching(e) {
    if (e.length > 4) {
      history.push(`/search?query=${e}`);
    }
  }

  function focusing(e) {
  }

 
 

 // const [nav]

  //<div className={navOpen ? "mobile-nav-open" : "mobile-nav-closed"}>

  //</div>
  function handleClickAway()  {
    setOpenMenu(false);
};


  return (
    <>
      <Box flexGrow={1}>
        <AppBar style={{backgroundColor: '#fff',boxShadow: 'none'}} position="static" >

          <Toolbar className={classes.fullmenu} >
            <Typography className={classes.title} variant="h6" noWrap>
              <div className="search-container">
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText style={{ backgroundColor: 'transparent' }}>
                      <SearchIcon />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input id="search-bar"
                    autoComplete="off"
                    onClick={() => setSearchSuggest(true)}
                    onChange={(e) => searching(e.target.value)}
                    onFocus={(e) => focusing(e.target.value)}
                    className="top-search"
                    type="search"
                    placeholder="Search.." />
                </InputGroup>
              </div>
            </Typography>
                       
            <IconButton onClick={(e) => {window.location = 'mailto:support@vizopay.com'}}
              style={{ color: '#6cabab' }}
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="open drawer">
              <HelpIcon />
            </IconButton>
            <Link style={{ color: '#6cabab' }} to="/settings"><IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="open drawer"
            >
              <SettingsIcon />
            </IconButton></Link>

            <IconButton
              style={{ color: '#6cabab' }}
              onClick={() => setAccountContainer(!accountContainer)}
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="open drawer"
            >
              <AccountCircleIcon />
            </IconButton>

          </Toolbar>
          <Toolbar className={classes.mobileMenu}>
            <IconButton>
              {
                openMenu?
                <CloseIcon onClick={() => setOpenMenu(false)} /> 
                :
                <MenuIcon onClick={() => setOpenMenu(true)}/>
              }
            </IconButton>
           </Toolbar>
        </AppBar>
      </Box>
      
      
      {
        openMenu?  
        <ClickAwayListener onClickAway={handleClickAway}>
          <div>
          <MobileMenu handleClickAway={handleClickAway} className={classes.mobileMenuContainer} />
          </div>
          </ClickAwayListener>
          :
         null
        }
       
        
      
      <Account setAccountContainer={setAccountContainer} accountContainer={accountContainer} />
      <SearchSuggest setSearchSuggestion={setSearchSuggestion} searchSuggest={searchSuggest} setSearchSuggest={setSearchSuggest} />
    </>
  );
}