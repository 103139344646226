import { Box, Button, Card, CardActions, CardContent, LinearProgress, Typography } from '@mui/material'
import React from 'react'
import CampaignStepper from './CampaignStepper'
import HeroImg from '../CampaignHero.png'
import { Stack } from '@mui/system'

type Props = {
  activeStep: number;
  setActiveStep: Function;
}

const CampaignCard = ({
  activeStep,
  setActiveStep
}: Props) => {



  React.useEffect(() => {
    console.log(activeStep);
  })

  return (
    <Box sx={{ minWidth: 275, height: '70vh',  justifyContent: 'space-between' }}>
    <Box sx={{height: '100%', overflowY: 'scroll'}}>
    <React.Fragment>
    <Stack spacing={1}>
    <Stack spacing={2}>
    <Typography sx={{ fontSize: 14, marginTop: 4 }} fontWeight="700" color="text.secondary" gutterBottom>
        Let's build an awesome campaign to grow your business 🚀
      </Typography>

      <Box sx={{width: '100%', height: '100%'}}>
        <CampaignStepper activeStep={activeStep} setActiveStep={setActiveStep} />
      </Box>
    </Stack>

    </Stack>
  </React.Fragment>
    </Box>

  </Box>
  )
}

export default CampaignCard