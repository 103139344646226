import { Typography, Button, Grid } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { Box, Stack } from '@mui/system'
import React from 'react'
import DayCard from './DayCard'
import { useQuery } from '@apollo/client';
import { FETCH_TRANSACTIONS_CUSTOMERS } from '../../../../../apollo/queries';
import { DayOfWeek } from '../../../../../utils/customers';
import { IPayments } from '../../../../../constants/types/types';
import WeeklyChart from '../../../customers/WeeklyChart';
import ChipSelect from '../Wizard/ChipSelect';


const DayRule = (Props) => {

  const rules = [
    {
        label: "Customer Type (coming soon)",
        rule_code: "customer_type"
    },
    {
        label: "Products (coming soon)",
        rule_code: "product",
        component: ""
    },
    {
        label: "Spend",
        rule_code: "spend",
        component: ""
    },
    {
        label: "Days of the week",
        rule_code: "days_of_week"
    },
    {
        label: "Time",
        rule_code: "time"
    },
]

  const [ruleCode, setRuleCode] = React.useState([]);
  const [activeComponent, setActiveComponent]= React.useState([]);

  React.useEffect(() => {
      console.log("AC", activeComponent)
   }, [activeComponent])

  React.useEffect(() => {
      console.log(ruleCode, "RC")
      console.log(activeComponent, "RCA")

  }, [ruleCode])

  const ruleNames = [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday"
    ]

   const {data, loading, error} = useQuery(FETCH_TRANSACTIONS_CUSTOMERS, {
    variables: {
      "query": {
        "status": "succeeded"
      },
      "sortBy": "CREATED_DESC",
      "limit": 500
    },
   })

   const [transactions, setTransactions] = React.useState<IPayments[]>([])


   React.useEffect(() => {
     if(loading) {
      return;
     } else {
      setTransactions(DayOfWeek(data.transactions));
     }
   }, [data])

  return (


  
    <Box >

    <Typography sx={{padding: "0 0 1% 0"}} variant='subtitle1'>Let's set some Day's of Week rules! Select the day of the week you would like to increase volume on. </Typography> 
    
    <Stack paddingY={2} spacing={4}>
      <Typography variant="h5">Current Daily Activity</Typography>
      <WeeklyChart bestDay={transactions}/>
    </Stack>

    <ChipSelect 
    array={ruleNames}
    label="Select Days"
    onChange={(e) => setRuleCode(e)}
    />




    </Box>


  )
}

export default DayRule