import React, {useState, useEffect} from 'react'
import Items from './Items';

function AddItem() {

    const [quantity, setQuantity] = useState(0)

            const [fields, setFields] = useState([{
                name: null,
                description: '',
                quantity: 1,
                rate: 0,

            }
        ]);

    
    function handleChange(i, event) {
    const values = [...fields];
    values[i].name = event.target.value;
    setFields(values);
  }

  function handleAdd() {
    const values = [...fields];
    values.push({
                name: null,
                description: '',
                quantity: 1,
                rate: 0,

            });
    setFields(values);
  }

  function handleRemove(i) {
    const values = [...fields];
    values.splice(i, 1);
    setFields(values);
  }

    return (
                          <div className="js-add-field">
                    {/* Title */}
                    <div className="bg-light border-bottom p-2 mb-3">
                      <div className="row">
                        <div className="col-sm-4">
                          <h6 className="card-title text-cap">Item</h6>
                        </div>
                        <div className="col-sm-3 d-none d-sm-inline-block">
                          <h6 className="card-title text-cap">Quantity</h6>
                        </div>
                        <div className="col-sm-2 d-none d-sm-inline-block">
                          <h6 className="card-title text-cap">Rate</h6>
                        </div>
                        <div className="col-sm-2 d-none d-sm-inline-block">
                          <h6 className="card-title text-cap">Amount</h6>
                        </div>
                      </div>
                    </div>
                    {/* End Title */}
                    {/* Content */}
                    {fields.map((field, idx) => {
        return (
          <div key={`${field}-${idx}`} onClick={(e) => console.log(e.target.key)}>
            <Items  handleChange={handleChange} handleRemove={handleRemove} idx={idx} quantity={quantity} setQuantity={setQuantity}/>
          </div>
        );
      })}
                      <a onClick={handleAdd} href="javascript:;" className="js-create-field form-link btn btn-sm btn-no-focus btn-ghost-primary">
                      <i className="tio-add" /> Add item
                    </a>
                    {/* End Add Phone Input Field */}
                  </div>
    )
}

export default AddItem
