import React, { useContext, useEffect, useState } from 'react';
import Veriff from '@veriff/js-sdk';

import { QRCode } from 'react-qrcode-logo';

import UserContext from '../../../context/UserContext';

function VeriffElement() {


    const [veriffUrl, setVeriffUrl] = useState('')
    const { userData } = useContext(UserContext);
    const veriff = Veriff({
        host: 'https://stationapi.veriff.com',
        apiKey: 'b281bebf-a660-4f43-a5e8-c3b5022ba4e0',
        parentId: 'veriff-root',
        onSession: function (err, response) {
            window.veriffSDK.createVeriffFrame({ url: response.verification.url });
            console.log(err)
        }
    }); 

    veriff.setParams({
        person: {
            givenName: userData.first_name,
            lastName: userData.last_name
        },
        vendorData: userData.email
    });

    useEffect(() => {

        veriff.mount()

    }, [])


    return (
        <div>
            <QRCode style={{ display: 'inline' }} qrStyle="dots" size="220" bgColor="#fff" fgColor="#65ccb8" value={veriffUrl} />
        </div>
    )
}

export default VeriffElement