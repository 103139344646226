import React, {useState,useContext} from 'react'
import { Col, Form, FormGroup, Input, Label, Button } from 'reactstrap';
import "./profileSettings.css";
import url from '../../../api/url.js';
import axios from 'axios';
import Cookies from "js-cookie";
import AuthContext from '../../../context/AuthContext';

function PasswordSettings() {
    const[values,setValues] = useState({
        current_password:"",
        new_password:"",
    })
    const [confirmPassword,setConfirmPassword] = useState("");
    const[submitted,setSubmitted] = useState(false);
    const[invalidPass,setInvalidPass] = useState(false);
    const { auth, setAuth } = useContext(AuthContext);

    const handleSubmit = async (event) => {
        event.preventDefault();
    
        if(values.current_password && values.new_password ) {
            await axios.put(`${url}/business/settings/password`,values, {
                headers: {
                    "x-auth-token": Cookies.get("token")
                }
            })
            .then(res => {
                if(res.status == 204) {
                    setSubmitted(true);
                }
            })
            .catch((e) => {
                if(e.response.status == 400){
                    setInvalidPass(true);
                }
                if(e.response.data.message === "jwt expired") {
                    Cookies.remove('token');
                    setAuth(false);
                }
            }) 
        }
        }

    return (
        <Form onSubmit={handleSubmit}>
            <Col>
                <FormGroup>
                    <Label>Current Password</Label>
                    <Input onChange={(e) => setValues((values) => ({...values,current_password:e.target.value}))} type={"password"} />
                    {invalidPass && <span className='error'>Your Current Password is Invalid</span>}
                </FormGroup>
                <FormGroup>
                    <Label>New Password</Label>
                    <Input onChange={(e) => setValues((values) => ({...values,new_password:e.target.value}))} type={"password"} />
                </FormGroup>
                <FormGroup>
                    <Label>Confirm New Password</Label>
                    <Input  onChange={event => setConfirmPassword(event.target.value)} type={"password"} />
                    {values.new_password !== confirmPassword && <span className='error'>New and Confirm New Password Dont Match</span>}
                </FormGroup>
                <FormGroup>
                    <Button>Update</Button>
                    {submitted && <div className='success-message'>Password Updated!!</div>}
                </FormGroup>
            </Col>
            <Col>
            </Col>
        </Form>
    )
}

export default PasswordSettings
