import React from 'react'

function InvoiceTotal() {
    return (
                          <div className="row justify-content-md-end mb-3">
                    <div className="col-md-8 col-lg-7">
                      <dl className="row text-sm-right">
                        <dt className="col-sm-6">Subtotal:</dt>
                        <dd className="col-sm-6">£0.00</dd>
                        <dt className="col-sm-6">Total:</dt>
                        <dd className="col-sm-6">£0.00</dd>
                        <dt className="col-sm-6 mb-1 mb-sm-0">Tax:</dt>
                        <dd className="col-sm-6">
                          {/* Input Group */}
                          <div className="select2-custom">
                            <div id="taxSelect" className="input-group input-group-merge">
                              <input type="number" className="form-control" placeholder={0.00} aria-label={0.00} />
                              <div className="input-group-append">
                                {/* Select */}
                                <select className="js-select2-custom select2-custom-right custom-select" size={1} style={{opacity: 0}} data-hs-select2-options="{
                                        &quot;minimumResultsForSearch&quot;: &quot;Infinity&quot;,
                                        &quot;dropdownAutoWidth&quot;: true,
                                        &quot;dropdownWidth&quot;: &quot;9rem&quot;
                                      }">
                                  <option value="discount2Filter1">Flat (£)</option>
                                  <option value="discount2Filter2" selected>Percent (%)</option>
                                </select>
                                {/* End Select */}
                              </div>
                            </div>
                          </div>
                          {/* End Input Group */}
                        </dd>
                        <dt className="col-sm-6 mb-1 mb-sm-0">Amount paid:</dt>
                        <dd className="col-sm-6">
                          {/* Input Group */}
                          <div className="input-group input-group-merge">
                            <div className="input-group-prepend">
                              <div className="input-group-text">
                                <i className="tio-dollar-outlined" />
                              </div>
                            </div>
                            <input type="number" className="form-control" placeholder={'0.00'} aria-label={'0.00'} />
                          </div>
                          {/* End Input Group */}
                        </dd>
                        <dt className="col-sm-6">Due balance:</dt>
                        <dd className="col-sm-6">£0.00</dd>
                      </dl>
                      {/* End Row */}
                    </div>
                  </div>
    )
}

export default InvoiceTotal
