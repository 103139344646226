import { Box, Card, CardContent, Grid, TextField, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import React from 'react'
import BasicDatePicker from '../../../../elements/DatePicker'

type Props = {
    children?: React.ReactNode;
}

const SectionCard = ({
    children
}: Props) => {
  return (
    <Card style={{backgroundColor: '#E2FCED'}}>
            <CardContent sx={{paddingX: 4, paddingY: 4}}>

                {children}

            </CardContent>
   
    </Card>
  )
}

export default SectionCard