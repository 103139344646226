import { createTheme, ThemeOptions } from '@material-ui/core/styles/createMuiStrictModeTheme';

export const lightThemeOptions: ThemeOptions = {
  palette: {
    type: 'light',
    primary: {
      main: '#65ccb8',
    },
    secondary: {
      main: '#135361',
    },
    error: {
      main: '#FF5649',
    },
    warning: {
      main: '#ff9800',
    },
    info: {
      main: '#6b83f9',
    },
    success: {
      main: '#6FC948',
    },
    divider: 'rgba(0,0,0,0.12)',
  },
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },
  shape: {
    borderRadius: 8,
  },
  typography: {
    h1: {
      fontWeight: 600,
    },
    h3: {
      fontWeight: 600,
    },
    h4: {
      fontWeight: 500,
    },
    h5: {
      fontWeight: 600,
    },
    h6: {
      fontWeight: 600,
    },
    h2: {
      fontWeight: 600,
    },
  }
};

export const darkThemeOptions: ThemeOptions = {
  palette: {
    type: 'dark',
    primary: {
      main: '#65ccb8',
    },
    secondary: {
      main: '#135361',
    },
    error: {
      main: '#cc657a',
    },
    warning: {
      main: '#ff9800',
    },
    info: {
      main: '#6b83f9',
    },
    success: {
      main: '#4caf50',
    },
    divider: 'rgba(0,0,0,0.12)',
    background: {
      default: '#222831',
      paper: '#434e5d',
    },
  },
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },
  shape: {
    borderRadius: 8,
  },
  typography: {
    h1: {
      fontWeight: 600,
    },
    h3: {
      fontWeight: 600,
    },
    h4: {
      fontWeight: 500,
    },
    h5: {
      fontWeight: 600,
    },
    h6: {
      fontWeight: 600,
    },
    h2: {
      fontWeight: 600,
    },
  }
};
