import React, { useState, useEffect,useContext } from 'react'
import axios from "axios";
import AuthContext from '../../../context/AuthContext';
import url from '../../../api/url';
import Cookies from 'js-cookie';
import "./campaign.css"
import CampaignHero from './CampaignHero';
import CampaignObjectives from './CampaignObjectives';
import CampaignType from './CampaignType';
import CampaignLength from './CampaignLength';
import OrderSize from './OrderSize.png'
import Gain from './Gain.png'
import BoostSales from './BoostSales.png'
import CampaignCard from './Campaign/CampaignCard';
import { Container } from '@mui/system';
import { Box, Button, Stack, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRocket } from '@fortawesome/free-solid-svg-icons';


function Campaign() {

    const [searchFilter, setSearchFilter] = useState('');
  const [spinner, setSpinner ] = useState(true);
  const { auth, setAuth } = useContext(AuthContext);

  const [objectiveIndex, setObjectiveIndex] = useState(0);

  const [campaignIndex, setCampaignIndex] = useState(0);

  const [campaignLengthIndex, setCampaignLengthIndex] = useState(0);

  useEffect(() => {
      setCampaignIndex(0)
  }, [objectiveIndex])

  useEffect(() => {
    setCampaignLengthIndex(0)
}, [objectiveIndex, campaignIndex])

  const objectives = [
    {
      key: 1,
      title: "Increase your average order size",
      image: OrderSize,
    },
    {
      key: 2,
      title: "Boost your sales at time/day",
      image: BoostSales,
    },
    {
      key: 3,
      title: "Gain & retain loyal customers",
      image: Gain,
    }
  ];


  let campaigns = [
   [
    {
    message: 'Double points for orders over ‘X’'
    },
   {
    message: 'Double stamps for orders over ‘X’'
   },
   {
    message: 'Stamp when you spend over ‘X’'
   },
   {
    message: 'Points when you spend over ‘X’'
   }
   ],
   [
    {
    message: 'Double stamps on your weakest day'
    },
    {
    message: 'Double points on your weakest day'
     },
     {
    message: 'Double points at custom times'
    },
    {
    message: 'Double stamps at custom times'
    }
   ],
   [
    {
    message: 'X% discount for students'
    },
    {
    message: 'A free ‘X’ on your 10th visit'
    },
    {
    message: 'Double points for power users'
    },
    {
    message: 'Double points for custom users'
    }
   ]
  ];

  let campaignLength = [
      {
        body:'1 month'
      },
      {
        body:'3 months'
      },
      {
        body:'6 months'
      },
      {
        body:'12 months'
      }
  ]

  const [startCampaign, setStartCampaign] = React.useState(false);

  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  }

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  }

return (

    <Container >
      {
        startCampaign ?

          <>

            <CampaignCard activeStep={activeStep} setActiveStep={setActiveStep} />
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Button
              color="inherit"
              disabled={activeStep === 0}
              variant='outlined' size="large"
              sx={{ mr: 1 }}
              onClick={handleBack}
            >
              Back
            </Button>
            <Box sx={{ flex: '1 1 auto' }} />
            <Button 
            onClick={handleNext} 
            color="info" variant='outlined' size="large">
              {'Next'}
            </Button>
          </Box>
          </>

        :

        <Box sx={{width: '100%', justifyContent: 'center', alignItems: 'center', display:'flex', minHeight: 300}} flexDirection="column" justifyContent="center" alignItems="center">
        <Stack spacing={6} width="100%" justifyContent="center" alignItems="center">
        <CampaignHero />
        <Typography variant='h5'>You have no active campaigns yet, let's create one now!</Typography>
        <Button style={{maxWidth: 240}} onClick={() => setStartCampaign(true)} variant='contained' color="info" size="large">Create Campaign <FontAwesomeIcon style={{marginLeft: 10}} icon={faRocket}/></Button>
        </Stack>
        </Box>


      }
    </Container>

)}

export default Campaign