import { Grid, TextField, Typography } from '@mui/material'
import { Box, Stack } from '@mui/system'
import React from 'react'
import BasicDatePicker from '../../../../../elements/DatePicker'
import RewardLayout from '../rewards/RewardLayout'

type Props = {}

export const CampaignRewards = (props: Props) => {
  return (
   <RewardLayout />
  )
}