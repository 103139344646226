import React, { useState } from "react";
import { Row, Container, Col} from 'reactstrap';
import Pie from './CustomerPie.png'
import Chart from './CustomerChart.png'
import LifecycleChart from "./LifecycleChart";
import WeeklyChart from "./WeeklyChart";

function BottomComponent(data, thisMonthData, bestDay) {

  return (
      <>

    <div >

        <Container fluid className="BottomContainer"> 
            <div className="BottomComponentLeft">
            <Col>
            <Row>
               
                <h1>Customer Lifecycle Stage</h1>
               
            </Row>

            <Row>
            
                <div style={{width: "100%", height: '100%'}}>
                    <LifecycleChart data={data} thisMonthData={thisMonthData}/>
                </div>
                
            </Row>
            </Col>
            </div>


            <div className="BottomComponent">
            <Col>
            <Row>
    
                <h1>Volume by Days</h1>
            
            </Row>

            <Row style={{padding:"0 10px"}}>
            
                {/* <img className="BottomComponentPieImg" src={Chart} /> */}
                <div style={{width: "100%", height: '100%'}}>
                    <WeeklyChart bestDay={data.bestDay}/>
                </div>
                
            </Row>
            </Col>
            </div>

        </Container>

    </div>

    </>
  );
}

export default BottomComponent;