import { Image, Text, View } from '@react-pdf/renderer'
import React from 'react'
import Logo from "./green.png"

type Props = {
    dateFrom: string | Date;
    dateTo: string | Date;
    email: string;
    company_name: string;
}

const ReportAddress = ({
    dateFrom,
    dateTo,
    email,
    company_name
}: Props) => {

    const styles = {
        text: {
            fontSize: '12px',
            fontWeight: 600,
            lineHeight: '1.5px'
        }
    }

  return (
    <View style={{
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        paddingVertical: 40
    }}>
         <View>
         <Text style={styles.text}>{company_name}</Text>
         <Text style={styles.text}>{email}</Text>

         </View>
         
         <View style={{
            display: 'flex',
            alignItems: 'flex-end'
         }}>
            <Text style={[styles.text]}>Vizopay Ltd</Text>
            <Text style={[styles.text]}>Date from: {new Date(dateFrom).toLocaleDateString()}</Text>
            <Text style={[styles.text]}>Date to {new Date(dateTo).toLocaleDateString()}</Text>
            {/* <Text style={[styles.text]}>Customer ID: 11828198298</Text> */}


         </View>
    </View>
  )
}

export default ReportAddress