import { faCreditCard, faFile, faHome, faTable, faUser, faWallet } from "@fortawesome/free-solid-svg-icons";

export interface ISidebarMenu {
    route: string;
    external: string | null;
    icon: any,
    text: string;
    selected: boolean;
}

export const items: ISidebarMenu[] = [
    {
        route: '/',
        external: null,
        icon: faHome,
        text: 'Home',
        selected: true
    },
    {
        route: '/payments',
        external: null,
        icon: faCreditCard,
        text: 'Payments',
        selected: false
    },
    {
        route: '/customers',
        external: null,
        icon: faUser,
        text: 'Customers',
        selected: false
    },
    // {
    //     route: '/campaigns',
    //     external: null,
    //     icon: faTable,
    //     text: 'Campaigns',
    //     selected: false
    // },
    // {
    //     route: '/invoice',
    //     external: null,
    //     icon: faFileInvoice,
    //     text: 'Invoice'
    // },
    {
        route: '/reports',
        external: null,
        icon: faFile,
        text: 'Reports',
        selected: false
    },
    {
        route: '/stores',
        external: null,
        icon: faTable,
        text: 'Stores',
        selected: false
    },
    // {
    //     route: '/products',
    //     external: null,
    //     icon: faTable,
    //     text: 'Products'
    // },
]