import { Image, StyleSheet, Text, View } from '@react-pdf/renderer'
import React from 'react'
import Logo from "./green.png"

type Props = {
    volume: number;
    fees: number;
}


let dollarUSLocale = Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' });


const Summary = ({
    volume,
    fees
}: Props) => {

    const styles = StyleSheet.create({
        text: {
            fontSize: '12px',
            fontWeight: 600,
            lineHeight: '1.5px'
        },
        row: {
            flex: 1,
            flexDirection: 'row',
            minHeight: 15,
            justifyContent: 'space-between',
            marginVertical: 8 
        }
    })

  return (
    <View style={{
        flexDirection: 'column',
        width: '100%',
        marginVertical: 10,
        height: '100%',
    }}>

         <View>
         <Text style={styles.text}>Summary:</Text>

         </View>
         
         <View style={{
            height: 20,
            width: '100%',
            backgroundColor: '#65ccb8',
            marginVertical: 10
         }}></View>

         <View style={{
            width: '100%'
         }}>

            <View style={styles.row}>
                <Text style={styles.text}>Gross Sales</Text>
                <Text style={styles.text}>{dollarUSLocale.format(volume)}</Text>
                
            </View>

            <View style={styles.row}>
            <Text style={styles.text}>Platform Fees</Text>
            <Text style={styles.text}>({dollarUSLocale.format(fees)})</Text>
            </View>

            <View style={styles.row}>
            <Text style={styles.text}>Refunds / Promotional Vouchers</Text>
            <Text style={styles.text}>{dollarUSLocale.format(fees)}</Text>
            </View>
            <View style={{
            width: '100%',
            height: "1px",
            backgroundColor: '#65ccb8',
            marginTop: 10
         }}></View>

         <View style={{
            flexDirection: 'column',
            width: '100%',
            alignItems: 'flex-end',
            paddingVertical: 20
         }}>
            <View style={{
                width: '50%',
                justifyContent: 'space-between',
                flexDirection: 'row',
                minHeight: 15,
                marginVertical: 8
            }}>
                <Text style={styles.text}>Net Sales</Text>
                <Text style={styles.text}>{dollarUSLocale.format((volume-fees+fees))}</Text>
            </View>
            <View style={{
                width: '50%',
                justifyContent: 'space-between',
                flexDirection: 'row',
                minHeight: 15,
                marginVertical: 8
            }}>
                <Text style={styles.text}>VAT</Text>
                <Text style={styles.text}>£0.00</Text>
            </View>
            <View style={{
                width: '50%',
                justifyContent: 'space-between',
                flexDirection: 'row',
                minHeight: 15,
                marginVertical: 8
            }}>
                <Text style={styles.text}>Amount payable</Text>
                <Text style={styles.text}>£0.00</Text>
            </View>
         </View>

         </View>


 
    </View>
  )
}

export default Summary