import { Box, Button, Container } from '@mui/material';
import React, { useEffect, useState } from 'react'
import AddStore from '../stores/AddStore';




function ConnectStore() {

    const [modal, setModal] = useState(false);
    


    return (
        <Box textAlign="center" justifyContent="center" alignItems="center" width="100%" alignSelf="center" >
            <AddStore modal={modal} setModal={setModal}/>
            <Button style={{alignSelf: 'center', justifySelf: 'center'}} size="large" variant="outlined" onClick={() => setModal(true)}>Connect Your First Store</Button>
        </Box>
    )
}

export default ConnectStore