import React, {useState, useEffect,useContext} from 'react'
import './stores.css';

import {Button, Container, Row, Col,Spinner } from 'reactstrap'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import AuthContext from '../../../context/AuthContext';
import Cookies from 'js-cookie';
import axios from 'axios';
import url from '../../../api/url';
import AddStore from './AddStore';

import {Link} from 'react-router-dom';

function StoresLayout() {

   
    const { auth, setAuth } = useContext(AuthContext);
    const [stores, setStores ] = useState([]);
    const [loading, setLoading] = useState(true);

    const [modal, setModal] = useState(false);

    useEffect(() => {
        
        const getStores = async() => {
            await axios.get(`${url}/business/stores`, {
                headers: {
                    'x-auth-token': Cookies.get("token")
                }
            }).then((res) => {

                setLoading(false);
                setStores(res.data.stores);


            }).catch((err) => {
                if(err.response.data.message === "jwt expired") {
                    Cookies.remove('token');
                    setAuth(false);
                  }
                setLoading(false);
                console.log(err.response);
            })
        }

        getStores();

    }, [])

    return (
        
            loading ?

            <div className="store-container">

                <div style={{padding: '10px auto'}}>
              
                <Spinner style={{color: '#65ccb8'}}/>
            
                    </div>

            </div>

            :

            <div className="store-container">
            <AddStore modal={modal} setModal={setModal}/>
            {
                stores.length<1 ?

                <div className="add-store-container">
                    <div style={{padding: '10px auto'}}>
                        <h3>You don't have any connected stores!</h3>
                    </div>
                    <div className="add-store-button">
                        <Button onClick={() => setModal(true)}>Add a store</Button>
                    </div>
                   
                </div>

                :

                <div className="active-stores-container">
               
                        <Row style={{marginBottom: 20}}>
                            {
                                stores.map((store) => {
                                    return(
                                        <Col style={{display: 'flex', justifyContent: 'center', margin: "20px auto"}} xl={4} key={store.id}> 
                                               <Link to={`/stores/${store.store_id}`}>
                                                   {/* Card */}
      <div className="card store-object" style={{maxWidth: '20rem', alignSelf: 'center',justifySelf: "center"}}>
        <img style={{height: "200px", width: "100%", objectFit: 'cover'}} className="card-img-top" src={`${url}/${store.image}`} alt="Card image cap" />
        <div className="card-body">
          <h3 className="card-title">{store.store_name}</h3>
          {store.active? 
          <p className="card-text">
            <span className="badge badge-soft-success">
                Active
              </span>
          </p> :
          <p className="card-text">
          <span className="badge badge-soft-danger">
              Not Active
            </span>
        </p>
          }
          <p>{store.store_address}</p>
          <p className="card-text">{store.store_description}</p>
          
        </div>
      </div>
      {/* End Card */}
                                               </Link>
                                        </Col>
                                    )
                                })
                            }
                            <Col style={{marginTop: 10, marginBottom: 20}} xl={4} style={{display: 'flex', justifyContent: 'center', margin: "20px auto"}} >
                                <div className="card new-store-object" style={{maxWidth: '20rem'}}>
        <div style={{height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}} className="card-body">
          
          <div className="add-store-button">
                        <Button onClick={() => setModal(true)}>Add a store</Button>
                    </div>
          
        </div>
      </div>
                            </Col>
                            
                        </Row>
                    
                </div>

            }
        </div>
        
    )
}

export default StoresLayout
