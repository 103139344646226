import React, {useEffect, useState} from 'react';
import {Container, Row, Col, Spinner} from 'reactstrap';


import './epos.css';

import EposNavbar from './epos-components/EposNavbar';
import { useQuery } from '@apollo/client';
import { FETCH_INVOICE } from '../../../apollo/queries';


const EposLayout = () => {

    const [createdFrom, setCreatedFrom] = useState();
    const [createdTo, setCreatedTo] = useState();


    const {data, loading, error, fetchMore} = useQuery(FETCH_INVOICE, {
        variables: {
            "query": {
                "status": "succeeded",
                "AND": [
                  {
                    "created_gte":createdFrom,
                    "created_lte": createdTo
                  }
                ]
              }
        }
    }) 

    useEffect(() => {

        fetchMore(
            {
                "query": {
                    "status": "succeeded",
                    "AND": [
                      {
                        "created_gte": createdFrom,
                        "created_lte": createdTo
                      }
                    ]
                  }
            }
            )

    }, [createdFrom, createdTo])

    return(
        <Container fluid>
            <Row>
               {
                loading ?

                <Spinner />

                :

                <div>
                    data...
                </div>
               }
            </Row>
        </Container>
    )
}

export default EposLayout;