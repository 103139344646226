import React from 'react'
import {Container, Row, Col} from 'reactstrap';

function CustomerBanner({payment}) {

    let date = new Date(Date.parse(payment.created))
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
  "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    return (
 
            <div>
                <Col>
                    <div>
                        <span>Date</span>
                        <span>{`${date.getDate()} ${monthNames[date.getMonth()]} ${date.toLocaleTimeString()}`}</span>
                    </div>
                </Col>
            </div>

    )
}

export default CustomerBanner
