import { useQuery } from '@apollo/client'
import { Grid, Stack, TextField, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import { FETCH_STORE, FETCH_STORES } from '../../../../../apollo/queries'

type Props = {}

const AppMessage = (props: Props) => {

    const {data, loading, error} = useQuery(FETCH_STORE);

    const [title, setTitle] = React.useState("Title");
    const [subtitle, setSubtitle] = React.useState("Write a description about your campaign to entice users.");

    if(loading && !data) {
        return(
            <Box></Box>
        )
    } else {
        return (
    

            <Grid container>
    
            <Grid display={"flex"} justifyContent="center" alignItems="center" item sm={6}>
                <Box width={270} sx={{backgroundColor: '#fff', overflow: 'hidden'}} height={550} borderColor="#222831" borderRadius={"20px"} border={4}>
                    <img style={{maxWidth: '270px', borderRight: 2, borderTopLeftRadius: "20px", marginLeft: -4, borderTopRightRadius: "20px", top: 220, opacity: 0.8, zIndex:0}} src={`https://server.vizopay.com/${data?.store.image}`}/>
                {/* <Box display="flex" height={40} width="100%" justifyContent="center" alignItems="center">
                    <div style={{width: "100px", position: 'absolute', top: 240, borderWidth:4, zIndex:10, borderRadius: 10, borderColor: '#222831', height:"14px", backgroundColor: '#222831', }}></div>
                </Box> */}
    
                <Stack padding={1} spacing={1}>
                    <Typography variant="h5">{data.store.store_name}</Typography>
                    <Typography variant="subtitle1">{data.store.store_description}</Typography>
                    <Box width="100%" height={120} sx={{backgroundColor:'#329292', borderRadius: '10px'}}>
                        <Stack spacing={1} padding={1}>
                            <Typography color="#fff" variant='h6'>{title}</Typography>
                            <Typography color="#fff" variant="subtitle2">{subtitle}</Typography>
                        </Stack>
                    </Box>


                </Stack>
            
                
                </Box>
            </Grid>
            <Grid display={"flex"} justifyContent="center" alignItems={"flex-start"} item sm={6}>
                <Stack width="100%" spacing={2}>
                    <Typography variant='h6' fontWeight={"600"}>App Message</Typography>
                    <Typography variant='subtitle1'>Let's design your app message to display to users on the Vizopay app.</Typography>
    
                    <TextField onChange={(e) => setTitle(e.target.value)} label="Title"/>
    
                    <TextField onChange={(e) => setSubtitle(e.target.value)} label="Message"/>
                </Stack>
    
            </Grid>
    
        </Grid>
        
      )
    }

}

export default AppMessage