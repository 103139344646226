import React, { useState } from "react";
import { Row, Container, Col} from 'reactstrap';

function TopComponent(props) {

  return (
      <>
    <div className="TopComponent">
    <Container fluid >

    <Row >
        <Col className="TopCol" style={{padding:"15px 0px 0 0px"}}>
            <div style={{backgroundColor: `${props.backgroundColor}`}} className="icon-container">
                {props.icon}
            </div>
        </Col>

        <Col>
            <h2>{props.title}</h2> 
            <h1>{props.body}</h1>
        </Col>

        <Col className="TopCol"> 
            {/* <button className="TopHoverButton">i</button> */}
            {/* <p className="TopComponentPer">↑{props.percentage}</p>  */}
        </Col>
    </Row>

    </Container>
    </div>

    </>
  );
}

export default TopComponent;