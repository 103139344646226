import React from 'react'
import Logo from '../../assets/images/Vizopay Logo-01.png'

function InvoiceHeader() {
    return (
        <div className="row justify-content-md-between">
                    <div className="col-md-4">
                        <img className="img-fluid mb-5" src={Logo} alt="Image Description" style={{width: '140px'}} />
              
                    </div>
                    <div className="col-md-5 text-md-right">
                      <h2>Invoice #</h2>
                      {/* Form Group */}
                      <div className="form-group d-md-flex justify-content-md-end">
                        <input type="text" className="form-control w-auto" placeholder aria-label defaultValue={'0982131'} />
                      </div>
                      {/* End Form Group */}
                      {/* Form Group */}
                      <div className="form-group">
                        <textarea className="form-control" placeholder="Who is this invoice from?" id="invoiceAddressFromLabel" aria-label="Who is this invoice from?" rows={3} defaultValue={""} />
                      </div>
                      {/* End Form Group */}
                    </div>
                  </div>
    )
}

export default InvoiceHeader
