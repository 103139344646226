import { Autocomplete, Card, CardContent, Grid, TextField, Typography } from '@mui/material'
import { Box, Stack } from '@mui/system'
import React from 'react'
import BasicDatePicker from '../../../../../elements/DatePicker'
import SpendRule from '../rules/SpendRule'

import RuleImage from '../../assets/rules.svg';
import ChipSelect from './ChipSelect'
import DayRule from '../rules/DayRule'

type Props = {}

export const CampaignRules = (props: Props) => {

    const ruleComponents = [
        <div>Coming soon...</div>,
        <div>Coming soon...</div>,
        <SpendRule />,
        <DayRule />,
        <div>Time</div>,
    ]

    const rules = [
        {
            label: "Customer Type (coming soon)",
            rule_code: "customer_type"
        },
        {
            label: "Products (coming soon)",
            rule_code: "product",
            component: ""
        },
        {
            label: "Spend",
            rule_code: "spend",
            component: ""
        },
        {
            label: "Days of the week",
            rule_code: "days_of_week"
        },
        {
            label: "Time",
            rule_code: "time"
        },
    ]

    const [ruleCode, setRuleCode] = React.useState([]);
    const [activeComponent, setActiveComponent]= React.useState([]);

    const CheckRules = () => {

        let array = [];

        ruleCode.map((ruleSelected) => {

            rules.map((rule, index) => {
                if(rule.label === ruleSelected) {
                    array.push(index)
                }
            }) 
           })

        setActiveComponent(array);
        
       }

    React.useEffect(() => {
       CheckRules();
    }, [ruleCode])

    React.useEffect(() => {
        console.log("AC", activeComponent)
     }, [activeComponent])

    React.useEffect(() => {
        console.log(ruleCode, "RC")
        console.log(activeComponent, "RCA")

    }, [ruleCode])

    const ruleNames = [
        "Customer Type (coming soon)",
        "Products (coming soon)",
        "Spend",
        "Days of the week",
        "Time"
      ]


  return (
    <Stack spacing={4}>
    <Box sx={{width: '100%'}}>
    <Typography variant="h5">Campaign Rules</Typography>
    <Typography variant='subtitle1'>Create rules for your campaign so you can influence customer behaviour. Rules help our AI tool suggest rewards to achieve campaign objectives.</Typography>
    </Box>
    <Box sx={{width: '100%'}}>
    {/* <Autocomplete
      disablePortal
      getOptionDisabled={(option) =>
        option === rules[0] || option === rules[1]
      }
      inputValue={ruleCode}
      onInputChange={(event, newInputValue) => {
        // console.log(newInputValue);
        setRuleCode(newInputValue);
      }}
      id="combo-box-rules"
      options={rules}
      sx={{ width: '100%' }}
      renderInput={(params) => <TextField {...params} label="Rules" />}
    /> */}
    <ChipSelect 
        array={ruleNames}
        label="Rules"
        onChange={(e) => setRuleCode(e)}
    />
    </Box>

    <Box sx={{width: '100%'}}>
        {
            ruleCode ?

            activeComponent.map((component) => {
                return(
                    <Card sx={{padding: 2, marginY: 2}}>
                        <CardContent>
                        {ruleComponents[component]}
                        </CardContent>
                    </Card>
                )
            })

            :

            <Stack spacing={2} justifyContent="center" alignItems="center">
                <Typography fontWeight='700'>Let's set some rules! </Typography>
                <img src={RuleImage} style={{width: '100%', maxWidth: 240}}/>
            </Stack>

        }
    </Box>

    <Typography variant='subtitle1'>We're always looking to add new rules so please reach out to us via chat if you want to see any!</Typography>

</Stack>
  )
}