import React from 'react'
import { Row, Col } from 'reactstrap';
import { useHistory } from "react-router-dom";
import { ClickAwayListener } from '@mui/material';

function SearchSuggest({ searchSuggest, setSearchSuggest }) {
    const [activeStep, setActiveStep] = React.useState(0);
    const [selected, setSelected] = React.useState({});
    const history = useHistory();

    const handleClickAway = (e) => {

        if(e.target.id === "search-bar") {
            setSearchSuggest(true)
        } else {
            setSearchSuggest(false);
        }

        
        if (searchSuggest) {
            setActiveStep(0);
        }
    };

    const suggestArray = [
        {
            text: "amount:",
            explanation: "e.g. 12.99",
            list: ['amount:10', 'amount:<20', 'amount:>49', 'amount:<100', 'amount:>100', 'amount:>400', 'amount:<500']
        },
        {
            text: "payment_id:",
            explanation: "e.g. vpi_8909bcfdb096134a0f3e",
            list: []
        },
        // {
        //     text: "Beneficiary Reference:",
        //     explanation: "",
        //     list: []
        // },
        // {
        //     text: "Remitter Reference:",
        //     explanation: "",
        //     list: []
        // },
        {
            text: "date:",
            explanation: "e.g. Yesterday",
            list: ['date:today', 'date:tommorow', 'date:yesterday']
        },
        {
            text: "status:",
            explanation: "e.g. pending",
            list: ['status:pending', 'status:incomplete', 'status:succeeded', 'status:blocked']
        },
        {
            text: "customer_email:",
            explanation: "e.g. john.deo@vizopay.com",
            list: []
        },
        // {
        //     text: "Customer ID:",
        //     explanation: "e.g. vpi_8909bcfdb096134a0f3e",
        //     list: []
        // },
    ]

    function toggle(e) {
        setActiveStep(activeStep + 1);
        setSelected(e);
    }

    function search(e) {
        setSearchSuggest(false);
        setActiveStep(0);
        setSelected({});
        history.push(`/search?query=${e}`);
    }

    function clickRow(e) {
        if (e) {
            document.getElementById('search-bar').value = `${e}`;
        }
    }

    return (
    
            
                searchSuggest ?
                    <ClickAwayListener onClickAway={handleClickAway}>
                        <div className="search-suggest-container">
                            <div className="search-suggest-heading">
                                <h3>Suggestions Filters</h3>
                            </div>
                            <div className="search-suggest-content">
                                {
                                    !activeStep ?
                                        <>
                                            {suggestArray.map(data => {
                                                return (
                                                    <Row onClick={() => {
                                                        clickRow(data.text);
                                                        toggle(data)
                                                    }}
                                                        className="suggestion-container">
                                                        <Col xs="6" className="suggestion-left-wrapper">
                                                            <span>{data.text}</span>
                                                        </Col>
                                                        <Col xs="6">
                                                            <small>{data.explanation}</small>
                                                        </Col>
                                                    </Row>
                                                )
                                            })}
                                        </>
                                        :
                                        (activeStep == 1 && selected.list.length) ?
                                            <>
                                                {selected.list.map(function (name, index) {
                                                    return <Row
                                                        onClick={() => { clickRow(name); toggle({}); search(name) }}
                                                        className="suggestion-container">
                                                        <Col xs="12" className="suggestion-left-wrapper">
                                                            <span>{name}</span>
                                                        </Col>
                                                    </Row>
                                                })}
                                            </>
                                            :
                                            <span></span>
                                }
                            </div>
                        </div>

                    </ClickAwayListener>
                    :
                    null
            
       
    )
}

export default SearchSuggest