import React, { useState } from "react";
import { Row, Container, Col, Button} from 'reactstrap';


function CampaignLength({campaigns, objectives, objectiveIndex, campaignLengthIndex, setCampaignLengthIndex, campaignLength}) {

  return (
      <>
    <div >

        <h1 className="CampaignTypeTitle" >Select campaign length</h1>

        <Container fluid className="CampaignContainer"> 
        {
            campaignLength?.map((campaign,i) => {
                return(


        <div onClick={() => setCampaignLengthIndex(i)} className={campaignLengthIndex === i ? "CampaignLengthActive" : "CampaignLength"}>
            <Col>
            <Row>
            
                <h1>{campaign.body}</h1>
                
            </Row>
            </Col>
        </div>

                )
            })

        }

        </Container>

        <p className="CampaignLengthText">Setting campaigns is a great way to boost sales and interact with your customers. If you are happy with your above campaign selection, click into the Save Changes button below, we will then let you know when your campaign starts!</p> 


        <Row>
                <div style={{padding: '30px 60px', display: 'flex', justifyContent: 'flex-end', width: '100%'}}>
                        <Button style={{width: '200px'}}>Save Changes</Button>
                </div>
        </Row>

    </div>

    </>
  );
}

export default CampaignLength;