import React, { useState, useEffect } from 'react';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { useHistory } from "react-router-dom";
import Typography from '@mui/material/Typography';
import AboutForm from './AboutForm';
import CodeVerify from './CodeVerify';
import ProductSelection from './ProductSelection';
import CompanyForm from './CompanyForm';
import axios from 'axios';
import url from '../../../api/url';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Box, Button, Grid, Stack } from '@mui/material';


function getSteps() {
  return ['Create your account ', 'Company information', 'Confirm your email'];
}

function Signup({ setLogin }) {
  const history = useHistory();
  const [activeStep, setActiveStep] = React.useState(0);
  const [spinner, setSpinner] = useState(false);

  const steps = getSteps();
  
      const [invalidEmail, setInvalidEmail] = useState(false)
    const [invalidPhone, setInvalidPhone] = useState(false)

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [phone, setPhone] = useState("");

  const [companyName, setCompanyName] = useState("");
  const [companyWebsite, setCompanyWebsite] = useState("");
  const [industry, setIndustry] = useState("");

  const [businessType, setBusinessType] = useState("")

  const [authRep, setAuthRep] = useState(true);

  const [code, setCode] = useState("");

  const[acceptPaymentsOnline, setAcceptPaymentsOnline] = useState(false);
  const[acceptPaymentsInStore, setAcceptPaymentsInStore] = useState(false);
  const[strongPassword,setStrongPassword] = useState(false);
  const [passwordMatch,setPasswordMatch]  = useState(false);

  React.useEffect(() => {
    const data = {
      first_name: firstName,
      last_name: lastName,
      email: email,
      phone: phone,
      password: password,
      confirmPassword: confirmPassword,
      company_name: companyName,
      website_url: companyWebsite,
      industry: industry,
      authorised_representative: authRep,
      type: businessType,
      accepts_payments_online:acceptPaymentsOnline,
      accepts_payments_instore:acceptPaymentsInStore
    }

    console.log(data)
  }, [firstName, lastName, email, phone, password, confirmPassword, companyName, companyWebsite, industry, authRep, businessType, acceptPaymentsInStore, acceptPaymentsOnline])


  const handleNext = async () => {

    const data = {
      first_name: firstName,
      last_name: lastName,
      email: email,
      phone: phone,
      password: password,
      confirmPassword: confirmPassword,
      company_name: companyName,
      website_url: companyWebsite,
      industry: industry,
      authorised_representative: authRep,
      type: businessType,
      accepts_payments_online:acceptPaymentsOnline,
      accepts_payments_instore:acceptPaymentsInStore
    }

    console.log(data)


    if (activeStep === 1) {
      setSpinner(true);
      await axios.post(`${url}/business/signup`, data).then(res => {
        setSpinner(false);
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }).catch(error => {
        setSpinner(false);
        toast.dark(error.response.data.message);
      })
    } else if (activeStep === 2) {
      setSpinner(true);
      await axios.post(`${url}/business/signup/verify_code`, {
        email: email,
        code: code
      }).then(response => {
        toast.dark("All done!");
        setSpinner(false);
        history.push("/login");
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }).catch(error => {
        setSpinner(false);
        toast.dark(error.response.data.message);
      })
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setLogin(true)
  };

  function getStepContent(stepIndex) {
    switch (stepIndex) {
      case 0:
        return (<AboutForm invalidEmail={invalidEmail} setInvalidEmail={setInvalidEmail} invalidPhone={invalidPhone} setInvalidPhone={setInvalidPhone} firstName={firstName} lastName={lastName} email={email} phone={phone} setFirstName={setFirstName} setLastName={setLastName} setEmail={setEmail} setPassword={setPassword} setConfirmPassword={setConfirmPassword} setPhone={setPhone} password={password} confirmPassword={confirmPassword} setStrongPassword={setStrongPassword} passwordMatch={passwordMatch} setPasswordMatch={setPasswordMatch}/>);
      case 1:
        return (<CompanyForm businessType={businessType} setBusinessType={setBusinessType} companyName={companyName} companyWebsite={companyWebsite} authRep={authRep} industry={industry} setCompanyName={setCompanyName} setCompanyWebsite={setCompanyWebsite} setIndustry={setIndustry} authRep={authRep} setAuthRep={setAuthRep} setAcceptPaymentsInStore = {setAcceptPaymentsInStore} setAcceptPaymentsOnline={setAcceptPaymentsOnline} acceptPaymentsOnline ={acceptPaymentsOnline} acceptPaymentsInStore={acceptPaymentsInStore}/>);
      case 2:
        return (<CodeVerify email={email} setCode={setCode} />);
      default:
        return 'Unknown stepIndex';
    }
  }

  return (
    <Box width="100%" maxWidth={500}>
      <ToastContainer
        hideProgressBar
        autoClose={2000}
        position="top-center"
      />
      <div >

        <Stack 
          direction="column"
          spacing={4}

        >
          {/* <div>
            <h1>Sign up</h1>
          </div> */}
          <Stepper style={{ backgroundColor: 'transparent' }} activeStep={activeStep} alternativeLabel>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>

          <div>
            {activeStep === steps.length ? (
              <Stack container spacing={2} >
                <Typography textAlign="center">All done! Click below to login.</Typography>
                <Button variant='contained' size="large" onClick={handleReset}>Login</Button>
              </Stack>
            ) : (
              <div className="button-container">
                <div>{getStepContent(activeStep)}</div>

                <Grid
                direction="row"
                spacing={1}
                container
                >
                  {
                    activeStep === steps.length - 1 ?

                      <Grid item xs={6}>
                        <Button
                     style={{width: "100%"}}
                     onClick={() => setActiveStep(0)}>Edit email</Button>
                      </Grid>
                      :

                      <Grid item xs={6}>

                      <Button
                        size="large"
                        variant="contained"
                        style={{minWidth: 100, width: '100%'}}
                        
                        disabled={activeStep === 0 || !strongPassword }
                        onClick={handleBack}
     
                      >
                        Back
                      </Button>

                      </Grid>
                  }

                  {activeStep === steps.length - 1 ?
                      <Grid item xs={6}>
                    <Button size="large" variant="contained" style={{ minWidth: 100, width: '100%' }} disableRipple onClick={handleNext}
                      disabled={!passwordMatch || !strongPassword || !firstName || !lastName || !email || !phone || !password || !confirmPassword || spinner }>
                      {
                        spinner ?
                          "Please wait.."
                          :
                          "Finish"
                      }
                    </Button>
                    </Grid>
                    :
                    <Grid item xs={6}>
                    <Button size="large" variant="contained" style={{ minWidth: 100, width: '100%' }} disableRipple onClick={handleNext}
                      disabled={!passwordMatch || !strongPassword || !firstName || !lastName || !email || !phone || !password || !confirmPassword || spinner }>
                      {
                        spinner ?
                          "Please wait.."
                          :
                          "Next"
                      }
                    </Button>
                    </Grid>
                  }
                </Grid>
                {
                  !activeStep ?
                    <span style={{ margin: '10px auto' }}>Already have an account?  <a className="auth-link" onClick={() => setLogin(true)}>Login</a></span>
                    :
                    ''
                }

              </div>
            )}
          </div>
        </Stack>

      </div>
    </Box>
  )
}

export default Signup