import React, {useState} from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMinus, faPlus, faTimes } from '@fortawesome/free-solid-svg-icons'

function Items({quantity, setQuantity, handleRemove, idx, handleChange}) {

    return (
        <div>
            <div className="row">
                      <div className="col-md-4">
                        <input type="text" className="form-control mb-3" placeholder="Item name" aria-label="Item name" />
                        <input type="text" className="form-control mb-3" placeholder="Description" aria-label="Description" />
                      </div>
                      <div className="col-12 col-sm-auto col-md-3">
                        {/* Quantity Counter */}
                        <div className="js-quantity-counter input-group-quantity-counter mb-3">
                          <input type="number" className="js-result form-control input-group-quantity-counter-control" value={quantity} />
                          <div className="input-group-quantity-counter-toggle">
                            <a onClick={() => setQuantity(quantity-1)} className="js-minus input-group-quantity-counter-btn" href="javascript:;">
                              <FontAwesomeIcon icon={faMinus}/>
                            </a>
                            <a onClick={() => setQuantity(quantity+1)} className="js-plus input-group-quantity-counter-btn" href="javascript:;">
                                <FontAwesomeIcon icon={faPlus}/>
                            </a>
                          </div>
                        </div>
                        {/* End Quantity Counter */}
                      </div>
                      <div className="col-12 col-sm col-md-2">
                        {/* Input Group */}
                        <div className="mb-3">
                          <input type="number" className="form-control" placeholder={'00'} aria-label={'00'} />
                        </div>
                        {/* End Input Group */}
                      </div>
                      <div className="col col-md-2">
                        <input type="number" className="form-control-plaintext mb-3" placeholder="£0.00" aria-label="£0.00" />
                        
                      </div>
                      <div style={{alignItems: 'center', justifyContent: 'center'}} className="col col-sm-1">
                             <div style={{paddingTop: '10px', cursor: 'pointer', display: 'flex', justifyContent: 'center', alignItems: 'center'}} onClick={() => handleRemove(idx)}>
              <FontAwesomeIcon icon={faTimes}/>
            </div>
                      </div>
                    </div>
                    
                    {/* End Content */}
                    {/* Container For Input Field */}
                    <div id="addInvoiceItemContainer" />
                   
                    {/* Add Phone Input Field */}

                      {/* End Content */}
      
        </div>
    )
}

export default Items
