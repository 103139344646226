import React, { useContext, useEffect, useRef } from 'react'
import Cookies from 'js-cookie';
import { Link, Redirect } from 'react-router-dom';
import AuthContext from '../../../../context/AuthContext';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import UserContext from '../../../../context/UserContext';

function Account({ accountContainer, setAccountContainer }) {

    const { auth, setAuth } = useContext(AuthContext)
    const { userData } = useContext(UserContext);

    const handleLogout = () => {
        Cookies.remove('token')
        setAuth(false);

    }

    const handleClickAway = () => {
        setAccountContainer(false);
    };

    return (
        <>
            {
                accountContainer ?

                    <ClickAwayListener onClickAway={handleClickAway}>
                        <div className="account-container">
                            <div className="account-header">
                                <h5>{`${userData.first_name} ${userData.last_name}`}</h5>
                                <span>Admin</span>
                            </div>
                            <div className="account-options">
                                <Link onClick={handleLogout}>Logout</Link>

                            </div>
                        </div>
                    </ClickAwayListener>

                    :

                    <div></div>


            }
        </>
    )
}

export default Account
