import React from 'react'

import { Table, Badge } from 'reactstrap';
import { Button } from '@mui/material';

import { useHistory } from 'react-router-dom';



import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUndo, faCheck } from '@fortawesome/free-solid-svg-icons'

import ModalAlert from './Refunds/ModalAlert';
import RefundModal from './Refunds/RefundModal';

function DataTable({ data, setPaymentId, paymentId }) {


  const history = useHistory();
  const [open, setOpen] = React.useState(false);

  const [id, setId] = React.useState('');
  const [amount, setAmount] = React.useState('');

  

  return (
    <> 
      <Table responsive borderless>
        <thead>
          <tr>
            <th>Amount</th>
            <th>Status</th>
            <th>Payment ID</th>
            <th>Customer</th>
            <th>Customer Email</th>
            <th>Date</th>
            <th>Details</th>
          </tr>
        </thead>
        <tbody>
          {data.map(data => {

            var status;
            var color;

            switch (data.status) {
              case "succeeded":
                status = "Succeeded"
                color = "#65ccb8"
                break;
              
              case "partial_refund":
                status="Partial Refund"
                color="#4b778d"
                break;

              case "refunded":
                status="Refunded"
                color="#ff8882"
                break;
              
              case "incomplete":
                status = "Incomplete"
                color = "#4b778d"
                break;
              
              case "pending":
                status = "Pending"
                color = "#bdc7c9"
                break;

              case "created":
                status = "Created"
                color = "#FFBF00"
                break;  

              case "blocked":
                status = "Blocked"
                color = "#ff8882"
                break;
            }

            const paymentDetailClick = () => {
              setId(data.id)
            }
 
            return (

              <tr className="table-row" key={data.id}>
                <td >{((data.original_amount * (1 - data.percentage_discount)) - data.points_reduction_amount) }</td>
                <td><Badge style={{ backgroundColor: color, fontFamily: 'Avenir', fontWeight: '600' }}>{status}</Badge></td>
                <td >{data.id}</td>
                <td >{data._id}</td>
                {
                  data.email ?
                  <td >{data.email}</td>
                  :
                  <td ></td>
                }
                <td >{data.created}</td>
                <td >
                  <Button onClick={() => { setOpen(true); paymentDetailClick()}} style={{ textTransform: 'none', backgroundColor: 'rgb(101, 204, 184)',color:'#FFFFFF' }}> <span style={{ margin: 'auto 5px' }}>Details</span></Button>
                </td>
              </tr>
            )
          })}
        </tbody>
      </Table>
      <RefundModal open={open} setOpen={setOpen} paymentId={id} amount={amount} />
    </>

  )
}

export default DataTable
