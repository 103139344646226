import React, { useState } from "react";
import { Row, Container, Col} from 'reactstrap';
import HeroImg from './CampaignHero.png'

function CampaignHero(props) {
  return (
      <>
    <div >
        <Container fluid className="CampaignContainer">

            <img className="CampaignImg" src={HeroImg} />

    

        </Container>
    </div>

    </>
  );
}

export default CampaignHero;