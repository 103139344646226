import React, { useEffect, useState,useContext } from 'react';

import { Spinner, Container, Row, Col, Badge } from 'reactstrap';

import axios from 'axios';
import url from '../../../api/url';
import Cookies from 'js-cookie';
import AuthContext from '../../../context/AuthContext';
import { Button } from '@material-ui/core';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUndo, faCheck, faCreditCard, faQuestion, faClipboard } from '@fortawesome/free-solid-svg-icons'
import CustomerBanner from './Payment Detail/CustomerBanner';

function PaymentDetail(props) {

    const [payment, setPayment] = useState(null);
    const [spinner, setSpinner] = useState(true);
    const { auth, setAuth } = useContext(AuthContext);
    const { match } = props

    useEffect(() => {
        const fetchPaymentDetail = async () => {
            await axios.get(`${url}/business/data/transactions/payment`, {
                headers: {
                    'x-auth-token': Cookies.get('token'),
                    'payment_id': match.params.id
                }
            }).then(res => {
                setPayment(res.data);
                setSpinner(false);
            })
                .catch(err => {
                    if(err.response.data.message === "jwt expired") {
                        Cookies.remove('token');
                        setAuth(false);
                      }
                    console.log(err.response);
                    setSpinner(false)
                    alert("Oops... can't get that payment. Please try again.")
                });
        }

        fetchPaymentDetail();
    }, [])

    var color;
    var status;
    var icon;

    if (payment) {
        switch (payment.status) {
            case "succeeded":
                status = "Succeeded"
                color = "#65ccb8"
                icon = faCheck
                break;

            case "incomplete":
                status = "Incomplete"
                color = "#4b778d"
                icon = faQuestion
                break;

            case "pending":
                status = "Pending"
                color = "#bdc7c9"
                icon = faCheck
                break;

            case "blocked":
                status = "Blocked"
                color = "#ff8882"
                icon = faCheck
                break;

        }
    }

    return (
        <>
            {
                spinner ? <Spinner />
                    :

                    <Container className="payment-detail-container" fluid>
                        <Row>
                            <Col className="payment-detail-header">
                                <span className="dimmer-text"><FontAwesomeIcon icon={faCreditCard} /> PAYMENT</span>
                                <span className="dimmer-text">{payment.payment_id} <FontAwesomeIcon icon={faClipboard} /></span>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="payment-detail-cta-header">
                                <div className="div-left">
                                    <h1 style={{ marginRight: '20px' }} >£<span>{(payment.amount / 100).toFixed(2)}</span> GBP</h1>
                                    <Badge style={{ backgroundColor: color, fontFamily: 'Avenir', fontWeight: '600' }}>{status} <FontAwesomeIcon icon={icon} /></Badge>
                                </div>
                                <div>
                                    <Button style={{ textTransform: 'none', backgroundColor: '#f7f7f7' }}> <span style={{ margin: 'auto 5px' }}>Transaction details</span></Button>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <CustomerBanner payment={payment} />
                        </Row>
                        <hr />
                    </Container>
            }
        </>
    )
}

export default PaymentDetail
