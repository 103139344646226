import { ITransactions } from "../../constants/types";
import { ICustomers, IPayments } from "../../constants/types/types";


const GroupUsers = (transactions: IPayments[]) => {
    let result = transactions.reduce((prev, curr) => {
        (prev[curr.customer_vizopay_id] = prev[curr.customer_vizopay_id] || []).push(curr)

        return prev;

    }, {})

    return result;
}

export const CalculateTotalSpend = (transactions: IPayments[]) => {

    let customers = GroupUsers(transactions);

    let customerArr = Object.values(customers)

    let arr: ICustomers[] = [];

    customerArr.map((payment: IPayments[]) => {


        let total = payment.reduce((prev, curr) => {

            let total_spend = prev + (curr.original_amount*(1-curr.percentage_discount) - curr.points_reduction_amount)

            return total_spend;
        }, 0)

        let co2 = payment.reduce((prev, curr) => {

            let total_spend = prev + curr.donation_amount

            return total_spend;
        }, 0)


        arr.push({
            customer_vizopay_id: payment[0].customer_vizopay_id,
            email: payment[0].customer_email,
            total_amount: total,
            total_donated: co2,
            trees_planted: Math.floor(co2/12.3)
        })

        arr.sort(function(a, b){return (<any>b).total_amount - (<any>a).total_amount});

    })
    
    return arr
}


let initialVolume = 0;

export const CalculateTotalVolume = (transactions: ITransactions[]) => {

    let sumVolume = transactions.reduce(
        (previousValue, currentValue) => {
            let total = previousValue + ((currentValue.original_amount * (1 - currentValue.percentage_discount)) - currentValue.points_reduction_amount);
            return total
        },
        initialVolume
      );
  
      return sumVolume

}

export const FormatCurrency = (number: number) => {

    let Currency = `£${(number/100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
  
      return Currency

}

export const FormatCurrency2 = (number: number) => {

    let Currency = `£${(number).toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
  
      return Currency

}

export const dateToDayOfWeekConverter = (date) => {

    const weekday = ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"];

    const d = new Date(date);

    let day = weekday[d.getDay()];

    return day

}

const DayOfWeekToNumberConverter = (data) => {

    const sorter = {"Monday": 1,
                     "Tuesday": 2,
                     "Wednesday": 3,
                     "Thursday": 4,
                     "Friday": 5,
                     "Saturday": 6,
                     "Sunday": 7};

    let sortedData = data.sort(function sortByDay(a, b) {
        let day1 = a.day_of_week;
        let day2 = b.day_of_week;
        return sorter[day1] - sorter[day2];
      });

    return sortedData

}

const GroupDay = (transactions: IPayments[]) => {

    let result = transactions.reduce((prev, curr) => {

        (prev[dateToDayOfWeekConverter(curr.created)] = prev[dateToDayOfWeekConverter(curr.created)] || []).push(curr)

        return prev;

    }, {})

    return result;
}

export const DayOfWeek = (transactions: IPayments[]) => {

    let dayGrouped = GroupDay(transactions);

    let customerArr = Object.values(dayGrouped)

    let arr = [];

    customerArr.map((payment: IPayments[]) => {


        let total = payment.reduce((prev, curr) => {

            let total_spend = prev + (curr.original_amount*(1-curr.percentage_discount) - curr.points_reduction_amount)

            return total_spend;
        }, 0)

        arr.push({
            day_of_week: dateToDayOfWeekConverter(payment[0].created),
            total_amount: total
        })

        
    })
    
    return DayOfWeekToNumberConverter(arr)

}

export const FirstTransaction = (transactions: IPayments[]) => {

    let customers = GroupUsers(transactions);

    function getPart (obj) {
        const filtered = Object.values(obj).filter(ob => (<any>ob).length <= 1);
        return filtered;
    }
    
    return getPart(customers);

}