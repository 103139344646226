import {
    ApolloClient,
    HttpLink,
    InMemoryCache
} from "@apollo/client"
import * as Realm from "realm-web"
import axios from "axios";
import url from "../api/url";
import Cookies from 'js-cookie';


const makeApolloClient = async(jwt: string) => {

const token = Cookies.get('token')

if(!token){
    return;
}

console.log(token)

let sessionToken: string = token;

console.log("SESSION TOKEN: ", sessionToken)

  // Connect to your MongoDB Realm app
const app = new Realm.App("merchant-dashboard-boixb");
// Create a custom jwt credential
// const credentials = Realm.Credentials.jwt("eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwiYXVkIjoibWVyY2hhbnQtYXBwLWNlY3JvIiwic3RvcmVfaWQiOiIxMzU1NTA2MDI0ODc1IiwiaWF0IjoxNTE2MjM5MDIyLCJleHAiOjE2NjYyOTAzMDV9.1Ht1rX_xhbleUlbs7jq74s69bYiPRG7DfANDXQ8ydg8");
const credentials = Realm.Credentials.jwt(sessionToken)

// console.log("CREDS: ", credentials)

try {
  const user = await app.logIn(credentials)
  console.log("Successfully logged in!", user.id);

  console.log("ACCESS TOKEN: ", app.currentUser?.accessToken)
  

    // create an apollo link instance, a network interface for apollo client
  const link = new HttpLink({
    uri: `https://us-east-1.aws.realm.mongodb.com/api/client/v2.0/app/merchant-dashboard-boixb/graphql`,
    headers: {
      'Authorization': `Bearer ${app.currentUser?.accessToken}`,
    }
  });

  

  // create an inmemory cache instance for caching graphql data
  const cache = new InMemoryCache();

  // console.log(cache, "CACHE APOLLO")


  // instantiate apollo client with apollo link instance and cache instance
  const client = new ApolloClient({
    link,
    cache
  });

  console.log(client.watchQuery, "CACHE APOLLO")

  console.log(client, "LINK APOLLO")


  return client;

} catch (err) {
  console.error("Failed to log in", err?.message);
  return {status: 401}
}

};

export default makeApolloClient;