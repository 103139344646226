import { useQuery } from '@apollo/client'
import { Button, Grid, Slider, TextField, Toolbar, Tooltip, Typography } from '@mui/material'
import { Box, Stack } from '@mui/system'
import React from 'react'
import { FETCH_TRANSACTIONS_CUSTOMERS } from '../../../../../apollo/queries'
import { ITransactions } from '../../../../../constants/types'
import { IPayments } from '../../../../../constants/types/types'
import { CalculateTotalVolume } from '../../../../../utils/customers'


type Props = {} 

const SpendRule = (props: Props) => {

  const {data, loading, error} = useQuery(FETCH_TRANSACTIONS_CUSTOMERS, {
    variables: {
      "query": {
        "status": "succeeded"
      },
      "sortBy": "CREATED_DESC",
      "limit": 500
    },
   })

   const [transactions, setTransactions] = React.useState([]);


   React.useEffect(() => {
     if(loading) {
      return;
     } else {
      setTransactions(data.transactions)
     }
   }, [data])

    const as = CalculateTotalVolume(transactions)/transactions.length;
    const [percentIncrease, setPercentIncrease] = React.useState(0);
    const [custom, setCustom] = React.useState<boolean>(false);

    function roundHalf(num: number) {
        return (Math.ceil(num*2)/2).toFixed(2);
    }
    
  return (
    <Stack spacing={2} sx={{width: '100%'}} >

       {
        !custom ?

        <>
           <Stack spacing={3}>
        <Typography variant='subtitle1'>Let's set some spend rules! Use the slider to set a target against your current average spend. </Typography>
        <Typography variant='h6'>Your current average spend: £{(as/100).toFixed(2)}</Typography>
        <Stack spacing={3} direction="row">
        <Typography color="#65ccb8" variant='h5'>Minimum Target: £{roundHalf((as*(1+percentIncrease/100)/100))} <Typography variant="caption">(rounded to the nearest £0.50)</Typography></Typography>
        </Stack>
        </Stack>
        <Box width="100%">
    <Typography>Drag the slide to set a target:</Typography>
      <Slider onChange={(e, nv) => setPercentIncrease(Number(nv))} defaultValue={5} aria-label="Default" valueLabelDisplay="on" valueLabelFormat={(e) => `${e}% increase`} max={200} />
    </Box>
        </>

        :

     

        <>
        <Typography>Custom rules:</Typography>
        <Tooltip title="The minimum amount a user has to spend to earn reward" children={undefined}>
        <TextField 
            label="Minimum spend"
        />
        </Tooltip>
        <Tooltip title="The maximum amount a user can spend to earn reward. We recommend not setting maximum values." children={undefined}>
        <TextField 
            label="Maximum spend"
        />
        </Tooltip>
        </>
       }

       <Button 
       size="large" 
       variant="contained"  
       color="info"
       style={{maxWidth: '300px'}}
       onClick={() => {setCustom(!custom)}}>{
        custom ? "Suggested Rules" : "Custom Rules"
        }</Button>
    </Stack>
  )
}

export default SpendRule