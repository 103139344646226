import { Grid, TextField, Typography } from '@mui/material'
import { Box, Stack } from '@mui/system'
import React from 'react'
import BasicDatePicker from '../../../../../elements/DatePicker'

type Props = {}

export const CampaignInformation = (props: Props) => {
  return (
    <Stack spacing={4}>
    <Box sx={{width: '100%'}}>
    <Typography variant="h5">Campaign Settings</Typography>
    <Typography variant='subtitle1'>This is only visible to you for reference, and is not shown to the end user.</Typography>
    </Box>
    <TextField 
        label="Campaign Name"
        placeholder="E.g. Increase Traffic on Tuesday's"
    />
    <Grid container>
        <Grid style={{paddingRight: '5px'}} item xs={6}>
            <BasicDatePicker fullWidth label="Campaign Start Date" initial={new Date()}/>
        </Grid>
        <Grid style={{paddingLeft: '5px'}}  item xs={6}>
            <BasicDatePicker fullWidth label="Campaign End Date" initial={new Date()}/>
        </Grid>
    </Grid>
</Stack>
  )
}