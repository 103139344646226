import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import SectionCard from './SectionCard';
import SectionContainer from './SectionContainer';
import { Container } from '@mui/material';
import { CampaignInformation } from './Wizard/CampaignInformation';
import { WizardData } from './Wizard/WizardData';

const steps = ['Campaign settings', 'Rules', 'Rewards', 'App Message', 'Summary'];

interface Props {
    activeStep: number;
    setActiveStep: Function;
}

export default function CampaignStepper({
    activeStep,
    setActiveStep
}: Props) {

  const handleReset = () => {
    setActiveStep(0);
  };

  React.useEffect(() => {
    console.log(activeStep, "ACTIVE STEP")
  }, [activeStep])

  return (
    <Box sx={{ width: '100%', height: '100%' }}>
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps: { completed?: boolean } = {};
          const labelProps: {
            optional?: React.ReactNode;
          } = {};
          return (
            <Step key={label} completed={index < activeStep}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>

        <React.Fragment>
          <Container sx={{paddingY: 5}}>
            <SectionCard>
                {WizardData[activeStep].component}
            </SectionCard>
          </Container>
        </React.Fragment>

    </Box>
  );
}