import { gql } from "@apollo/client";


export const FETCH_MERCHANT = gql`
query FetchMerchant {
    vendors {
      vizopay_id
      email
      application_fee
      country
      created
      default_currency
      donation_fee
      email_verified
      stripe_details_verified
    }
  }
`

export const FETCH_TRANSACTIONS = gql`
query FetchTransactions($query: TransactionQueryInput, $sortBy: TransactionSortByInput, $limit: Int) {
  transactions(query: $query, sortBy: $sortBy, limit: $limit) {
    _id,
    payment_id,
    customer_email,
    amount,
    created,
    refunded,
    status,
    customer_vizopay_id
    original_amount
    percentage_discount
    points_reduction_amount 
    application_fee_amount
    application_fee
  }
}
`

export const FETCH_INVOICE = gql`
query FetchInvoiceData($query: TransactionQueryInput, $limit: Int) {
    transactions(query: $query, limit: $limit) {
      payment_id
      amount
      original_amount
      application_fee_amount
      application_fee
      donation_amount
      created
      percentage_discount
      points_reduction_amount
    }
  }
`

export const FETCH_GROSS_VOLUME = gql`
query FetchInvoiceData($query: TransactionQueryInput, $limit: Int) {
    transactions(query: $query, limit: $limit) {
      payment_id
      amount
      original_amount
      application_fee_amount
      application_fee
      donation_amount
      created
      percentage_discount
      points_reduction_amount
    }
  }
`

export const FETCH_TRANSACTIONS_CUSTOMERS = gql`
query FetchTransactions($query: TransactionQueryInput, $sortBy: TransactionSortByInput, $limit: Int) {
  transactions(query: $query, sortBy: $sortBy, limit: $limit) {
    _id,
    payment_id,
    customer_email,
    amount,
    created,
    refunded,
    status,
    customer_vizopay_id
    original_amount
    percentage_discount
    points_reduction_amount 
    donation_amount
  }
}
`

export const FETCH_STORE = gql`
  query FetchStore {
    store {
      store_id
      store_name
      store_description
      image
    }
  }
`

export const FETCH_STORES = gql`
query FetchStores {
  stores {
    store_id
    store_name
  }
}
`

export const FETCH_INVOICE_DETAILS = gql`
query Vendor {
  vendor {
    account_details {
      company_name 
    }
    email
  }
}
`
