import React, { useState, useContext,useEffect } from 'react'
import LineChart from '../charts/LineChart'
import { Container, Row, Col } from 'reactstrap';
import './home.css'
import KeyStats from './KeyStats';
import DateSelector from '../../general/DateSelector';
import Impact from './Impact';
import Echarts from '../charts/Echarts';
import MainChart from '../charts/MainChart';
import SummaryRow from './SummaryRow';
import PaymentsSuccess from './PaymentsSuccess';
import Cookies from 'js-cookie';
import axios from 'axios';
import AuthContext from '../../../context/AuthContext';
import url from '../../../api/url';
import UserContext from '../../../context/UserContext';


const Home = () => {

    const { userData } = useContext(UserContext);
    const { auth, setAuth } = useContext(AuthContext);
    const [selectedDate, setSelectedDate] = React.useState(new Date());
    const [crosshairValue, setCrosshairValue] = useState(0.00)
    const [today,setToday] = useState(0);
    const [yesterday,setYesterday] = useState(0);


    useEffect(() => {

        let token = Cookies.get('token');
    
        const getTransactions = async () => {
          await axios.get(`${url}/business/data/transactions/volume`, {
            headers: {
              'x-auth-token': token
            }
          }).then(res => {  
            setToday(res.data.today.slice(-1)[0])

            setYesterday(res.data.yesterday[24])
          }).catch(err => 
            {
                if(err.response.data.message === "jwt expired") {
                    Cookies.remove('token');
                    setAuth(false);
                  }

                  console.log(err.response)
            }
          );
        }
    
        getTransactions();
    
      }, [])
    return (
        <Container fluid>
            <Row>
                <Col style={{ margin: '10px auto', padding: '0' }} >
                    <div className="graph-card card h-100">
                        <div style={{ backgroundColor: 'transparent', borderBottom: 'none' }} className="card-header">
                            <div style={{ backgroundColor: 'transparent' }}>
                                <h3><span style={{ backgroundColor: '#6cabab' }} className={`legend-indicator`} /> Today: £{(today / 100).toFixed(2)}</h3>
                                <h5><span style={{ backgroundColor: '#fff' }} className={`legend-indicator`} /> Yesterday: £{(yesterday / 100).toFixed(2)}</h5>
                            </div>
                            {/* <div>
                                <DateSelector selectedDate={selectedDate} setSelectedDate={setSelectedDate}/>
                            </div> */}
                        </div>
                        {/* <Echarts /> */}
                        {/* <LineChart selectedDate={selectedDate} crosshairValue={crosshairValue} setCrosshairValue={setCrosshairValue} /> */}
                        <div className="card-body">

                            <MainChart />
                        </div>

                    </div>

                </Col>

            </Row>
            <Row>
                <SummaryRow />
            </Row>

            <Row style={{paddingBottom: 20}}>
                <Col style={{ paddingLeft: '0' }} lg="6">

                    <PaymentsSuccess />


                </Col>
                <Col style={{ paddingRight: '0' }} lg="6">

                    <KeyStats />

                </Col>
            </Row>

        </Container>

    )
}

export default Home
