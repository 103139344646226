import { Image, Text, View } from '@react-pdf/renderer'
import React from 'react'
import Logo from "./green.png"

type Props = {}

const ReportHeader = (props: Props) => {
  return (
    <View style={{
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'space-between'
    }}>
         <Image style={{height: 40}} src={Logo} />
         <Text>INVOICE</Text>
    </View>
  )
}

export default ReportHeader