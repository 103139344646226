import { Document, Page, View } from '@react-pdf/renderer'
import React from 'react'
import ReportAddress from './ReportAddress'
import ReportHeader from './ReportHeader'
import Summary from './Summary'

type Props = {
    data: {
        vendor: {
            account_details: {
                company_name: string;
            },
            email: string;
        }
    },
    totalVolume: number;
    platformFee?: number; 
    carbonOffset?: number;
    dateFrom?: Date;
    dateEnd?: Date;
    onChange?: (value: any) => void;
}

const ReportsPDFNew = ({
    data,
    totalVolume,
    platformFee,
    carbonOffset,
    dateFrom,
    dateEnd,
    onChange,
}: Props) => {
  return (
    <Document>
        <Page size="A4">
            <View style={{
                flex: 1,
                width:'100%',
                paddingVertical: 20,
                paddingHorizontal: 24
            }}>
            <ReportHeader />
            
            <ReportAddress email={data.vendor.email} company_name={data.vendor.account_details.company_name} dateFrom={dateFrom} dateTo={dateEnd}/>

            <Summary volume={totalVolume} fees={platformFee} />
            </View>
        </Page>
    </Document>
  )
}

export default ReportsPDFNew