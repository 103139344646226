import React from 'react'
import ReactEcharts from 'echarts-for-react';


function LifecycleChart(data, thisMonthData) {

    const getColor = (color) => {

        let value;

        switch(color) {
  case 'primary':
    value = "#65ccb8"
    break;
  case 'dark':
    value = "#222831"
    break;

  case 'danger':
    value = "#FF9F9F"
    break;

  case 'warning':
    value = "#DEC1F7"
    break;

  case 'success':
    value = "#96FEA0"
    break;

  case 'info':
      value = "#FFD572"
      break;

  default:
    value="#65ccb8"
}
        
        return value;
    }

    
    const getOption = () => {
        return {
    legend: {
      bottom: 0,
      textStyle: {
        color: getColor('600')
      }
    },
    series: [
      {
        type: 'pie',
        radius: ['40%', '70%'],
        center: ['50%', '40%'],
        avoidLabelOverlap: false,
        label: {
          show: false,
          position: 'center'
        },
        labelLine: {
          show: false
        },
        data: [
  
          // {
          //   value: 4,
          //   name: 'Churn',
          //   itemStyle: {
          //     color: getColor('danger')
          //   }
          // },
          {
            value: Object.values(data.data.thisMonthData).length,
            name: 'New This Month',
            itemStyle: {
              color: getColor('info')
            }
          },
          {
            value: (Object.values(data.data.data).length - Object.values(data.data.thisMonthData).length),
            name: 'Active',
            itemStyle: {
              color: getColor('success')
            }
          },
          // {
          //   value: 24,
          //   name: 'Slipping',
          //   itemStyle: {
          //     color: getColor('warning')
          //   }
          // }
        ]
      }
    ],
    tooltip: {
      trigger: 'item',
      padding: [7, 10],
      backgroundColor: getColor('100'),
      borderColor: getColor('300'),
      textStyle: { color: getColor('dark') },
      borderWidth: 1,
      transitionDuration: 0,
      axisPointer: {
        type: 'none'
      }
    }
    }
    };

    return (
          <ReactEcharts

              option={getOption()}

            />
    )
}

export default LifecycleChart
