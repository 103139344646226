import React from 'react'
import ReactEcharts from 'echarts-for-react';
import { Dayjs } from 'dayjs';
import { ITransactions } from '../../../constants/types';

 
interface IVolumeChart {
  x: number;
  y: number;
}

interface IReportsChart {
  onChange?: (value: any) => void;
  transactions: IVolumeChart[];
  data: ITransactions[];
}


function ReportsDailyCharts({
  onChange,
  transactions,
  data
}: IReportsChart) {

  const [value, setValue] = React.useState<Dayjs | null>(null);
  const[y,setY] = React.useState([])
  const[x,setX] = React.useState([])

  React.useEffect(() => {
    if(onChange) {
        onChange(value)
    } else {
        return;
    }
  }, [value])

  let yArr  = []
  let xArr = []

  const CreateChart = async() => {
    transactions.map(transaction => {

      xArr.push(Number(transaction.x))
      yArr.push(Number(transaction.y))

      setX(xArr);
      setY(Object.values(yArr));

    })
  }

  React.useEffect(() => {
    CreateChart();
  }, [transactions])

     const getColor = (color) => {

        let value;

        switch(color) {
  case 'primary':
    value = "#fff"
    break;
  case 'dark':
    value = "#222831"
    break;

  case 'danger':
    value = "#FF9F9F"
    break;

  case 'warning':
    value = "#DEC1F7"
    break;

  case 'success':
    value = "#96FEA0"
    break;

  case 'info':
      value = "#FFD572"
      break;

  default:
    value="#fff"
}
        
        return value;
    }

  const Ydata = Object.values(y);
  const Xdata = x;

  const [options, setOptions] = React.useState({})

  const getOption = () => (
    setOptions({
    tooltip: {
       trigger: 'axis',
       padding: [7, 10],
       backgroundColor: getColor('100'),
       borderColor: getColor('300'),
       textStyle: { color: '#222831' },
       borderWidth: 1,
       formatter: (value) => `£${(value[0].data[1]/100).toFixed(2)}`,
       transitionDuration: 0,
       axisPointer: {
         type: 'none'
       }
     },
     xAxis: {
       type: 'value',
      //  data: ["!", "!!"],
      //  interval: 63,
       axisLine: {
         lineStyle: {
           color: getColor('300'),
           type: 'solid'
         }
       },
       axisTick: { show: false },
       axisLabel: {
         color: getColor('400'),
         formatter: value => value,
         margin: 15
       },
       splitLine: {
         show: false
       },
       minInterval: Xdata.length,
       min: (value) => {
        return value.min
       },
       max: (value) => {
        return value.max
       }
     },
     yAxis: {
       type: 'value',
       axisLabel: {
         show: true,
         color: getColor('400'),
         formatter: value => `£${(value/100).toFixed(0)}`,
         margin: 15
       },
       splitLine: {
         show: true,
         lineStyle: {
           color: "#ffffff20"
         }
       },
       axisTick: { show: false },
       axisLine: { show: false },

     },
     series: [
       {
         type: 'line',
         name: 'Transactions',
         data:transactions,
         lineStyle: { color: getColor('primary') },
         itemStyle: {
           color: getColor('primary'),
           borderRadius: [3, 3, 0, 0],
           textColor: '#fff'
         },
         showSymbol: true,
         symbol: 'circle',
         smooth: true,
         emphasis: {
           scale: true
         }
       }
     ],
     grid: { right: '3%', left: '10%', bottom: '10%', top: '5%' }
   })
  );
   React.useEffect(() => {
          getOption()
        }, [x, y])

   return (
         <ReactEcharts

             option={options}

           />
   )
}


export default ReportsDailyCharts
