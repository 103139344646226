import React from 'react'

function InvoiceSidebar() {
    return (
                    <div id="stickyBlockStartPoint" className="col-lg-3">
              <div className="js-sticky-block" data-hs-sticky-block-options="{
                   &quot;parentSelector&quot;: &quot;#stickyBlockStartPoint&quot;,
                   &quot;breakpoint&quot;: &quot;lg&quot;,
                   &quot;startPoint&quot;: &quot;#stickyBlockStartPoint&quot;,
                   &quot;endPoint&quot;: &quot;#stickyBlockEndPoint&quot;,
                   &quot;stickyOffsetTop&quot;: 20
                 }">
                <a className="btn btn-block btn-success mb-3" href="javascript:;">
                  <i className="tio-send mr-1" /> Send invoice
                </a>
                <a className="btn btn-block btn-white mb-3" href="javascript:;">
                  <i className="tio-download-to mr-1" /> Download
                </a>
                <div className="form-row">
                  <div className="col-sm mb-3 mb-sm-0">
                    <a className="btn btn-block btn-white" href="javascript:;">Preview</a>
                  </div>
                  <div className="col-sm">
                    <a className="btn btn-block btn-white" href="javascript:;">Save</a>
                  </div>
                </div>
                {/* End Row */}
                <hr className="my-4" />
                {/* Form Group */}
                <div className="form-group">
                  <label htmlFor="currencyLabel" className="input-label">Currency</label>
                  {/* Select */}
                  <select className="js-select2-custom custom-select" size={1} style={{opacity: '0'}} id="currencyLabel" data-hs-select2-options="{
                          &quot;minimumResultsForSearch&quot;: &quot;Infinity&quot;,
                          &quot;placeholder&quot;: &quot;Select currency&quot;
                        }">
                    <option label="empty" />
                    <option value="currency1" selected data-option-template="<span class=&quot;d-flex align-items-center text-truncate&quot;><img class=&quot;avatar avatar-xss avatar-circle mr-2&quot; src=&quot;@@autopath/node_modules/flag-icon-css/flags/1x1/us.svg&quot; alt=&quot;Image description&quot; width=&quot;16&quot;/><span>USD (United States Dollar)</span></span>">USD (United States Dollar)</option>
                    <option value="currency2" data-option-template="<span class=&quot;d-flex align-items-center text-truncate&quot;><img class=&quot;avatar avatar-xss avatar-circle mr-2&quot; src=&quot;@@autopath/node_modules/flag-icon-css/flags/1x1/gb.svg&quot; alt=&quot;Image description&quot; width=&quot;16&quot;/><span>GBP (United Kingdom Pound)</span></span>">GBP (United Kingdom Pound)</option>
                    <option value="currency3" data-option-template="<span class=&quot;d-flex align-items-center text-truncate&quot;><img class=&quot;avatar avatar-xss avatar-circle mr-2&quot; src=&quot;@@autopath/node_modules/flag-icon-css/flags/1x1/eu.svg&quot; alt=&quot;Image description&quot; width=&quot;16&quot;/><span>Euro (Euro Member Countries)</span></span>">Euro (Euro Member Countries)</option>
                  </select>
                  {/* End Select */}
                </div>
                {/* End Form Group */}
                {/* Toggle Switch */}
                <label className="row form-group toggle-switch" htmlFor="invoicePaymentTermsSwitch">
                  <span className="col-8 col-sm-9 toggle-switch-content ml-0">Payment terms</span>
                  <span className="col-4 col-sm-3">
                    <input type="checkbox" className="toggle-switch-input" id="invoicePaymentTermsSwitch" defaultChecked />
                    <span className="toggle-switch-label ml-auto">
                      <span className="toggle-switch-indicator" />
                    </span>
                  </span>
                </label>
                {/* End Toggle Switch */}
                {/* Toggle Switch */}
                <label className="row form-group toggle-switch" htmlFor="invoiceClientNotesSwitch">
                  <span className="col-8 col-sm-9 toggle-switch-content ml-0">Client notes</span>
                  <span className="col-4 col-sm-3">
                    <input type="checkbox" className="toggle-switch-input" id="invoiceClientNotesSwitch" defaultChecked />
                    <span className="toggle-switch-label ml-auto">
                      <span className="toggle-switch-indicator" />
                    </span>
                  </span>
                </label>
                {/* End Toggle Switch */}
                {/* Toggle Switch */}
                <label className="row form-group toggle-switch" htmlFor="invoiceAttachPDFSwitch">
                  <span className="col-8 col-sm-9 toggle-switch-content ml-0">Attach PDF in mail</span>
                  <span className="col-4 col-sm-3">
                    <input type="checkbox" className="toggle-switch-input" id="invoiceAttachPDFSwitch" />
                    <span className="toggle-switch-label ml-auto">
                      <span className="toggle-switch-indicator" />
                    </span>
                  </span>
                </label>
                {/* End Toggle Switch */}
              </div>
            </div>
    )
}

export default InvoiceSidebar
