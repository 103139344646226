import { Box, Container, Paper, TextField } from '@mui/material'
import { Stack } from '@mui/system'
import React from 'react'
import Login from './LoginNew'
import Signup from './sign up/Signup'

type Props = {}

const AuthContainer = (props: Props) => {

  const [login, setLogin] = React.useState<boolean>(true);

  return (
    <Container>
        <Stack
  direction="column"
  justifyContent="center"
  alignItems="center"
  spacing={4}
  minHeight="100vh"
>

       <Paper variant="outlined" style={{
        padding: 40,
        width: '100%',
        maxWidth: 600
       }}>
       {
            login ?

            <Login setLogin={setLogin}/>

            :

            <Signup setLogin={setLogin} />
        }
       </Paper>

</Stack>
    </Container>
  )
}

export default AuthContainer