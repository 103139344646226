import React, { useState, useEffect, useContext } from 'react'
import { Form, FormGroup, Label, InputGroupAddon, InputGroupText, Input, Row, Col } from 'reactstrap';


import './authentication.css'

import axios from 'axios';
import url from '../../api/url';
import Cookies from 'js-cookie';

import AuthContext from '../../context/AuthContext';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from "react-router-dom";
import CodeVerify from './sign up/CodeVerify';
import Button from '@mui/material/Button';
import { Box, CircularProgress, Container, Grid, TextField } from '@mui/material';
import { Stack } from '@mui/system';

function Login({ setLogin }) {

    const { auth, setAuth } = React.useContext(AuthContext);

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const [spinner, setSpinner] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    const [emailVerified,setEmailVerified] = useState(true);
    const [code, setCode] = useState("");
    const [initailRender,setInitialRender] = useState(true);
    const [shouldSend,setShouldSend] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();

        setSpinner(true);

        await axios.post(`${url}/business/login`,
            {
                email: email,
                password: password
            }
        ).then(res => {
            Cookies.set('token', res.data.token);

            setAuth(true);
        }).catch(error => {
            setSpinner(false)

            if(error.response.data.message === "Email not verified") {
                setEmailVerified(false)
            }

            if(error) {
                toast.dark("Incorrect credentials! Please try again.")
            }
        })
    }
useEffect(() => {
    if (code.length === 6) {
        setShouldSend(true)
    } else {
        setShouldSend(false)
    }
},[code])
    

useEffect(() => {
    if(initailRender){
        setInitialRender(false);
      }else{
          const postData = async () => {
              await axios.post(`${url}/business/signup/verify_code`, {
                  email: email,
                  code: code
                }).then(response => {
                  toast.dark("All done!");
                  setEmailVerified(true);
                }).catch(error => {
                  toast.dark(error.response.data.message);
                })
      
          }
        postData();
      }

// eslint-disable-next-line react-hooks/exhaustive-deps
},[shouldSend])

    return (
        <Box maxWidth={500} width="100%">
            {/* <ToastContainer
                hideProgressBar
                autoClose={2000}
                position="top-center"
            /> */}
           {
               !emailVerified ?

               <CodeVerify setCode ={setCode} email={email} />

               :

               <Stack 
                width="100%"
                direction="column"
                >
                <div className="header">
                <h1>Login</h1>
                <span>Sustainability made easy.</span>
            </div>

                <FormGroup>
                    
                    <TextField className="form-input" style={{width: '100%'}} id="email" label="Email" variant="outlined" onChange={(e) => setEmail(e.target.value)} size="medium" type="email" placeholder="jenny.doe@company.com"/>
                </FormGroup>
                <FormGroup>
                  
                    <TextField className="form-input" style={{width: '100%'}} id="password" label="Password" variant="outlined" onChange={(e) => setPassword(e.target.value)} size="medium"  type="password" placeholder="Please enter your password" />
                </FormGroup>
                <FormGroup style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <span>
                        <Link to="/forgot" className="auth-link">Forgot Password?</Link></span>
                </FormGroup>

                {
                    spinner ?

                        <Button style={{width: '100%'}} size="large" 
                            variant="outlined"
                            disabled={spinner}
                         >
                            <CircularProgress size={24} />
                        </Button>

                        :

                        <Button onClick={handleSubmit} color="primary" style={{width: '100%'}} size="large" type="submit" variant='contained' >
                            Login
                        </Button>
                }
          
    
          <span className="login-signup-nav">Don't have an account?  <a className="auth-link" onClick={() => setLogin(false)}>Sign up</a></span>
            </Stack>
    
           }
        </Box>
    )
}

export default Login