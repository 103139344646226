import { Grid, Skeleton, Stack, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import RewardCard from './RewardCard'
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import TextField from '@mui/material/TextField';
import Slider from '@mui/material/Slider';
import BasicDatePicker from '../../../../../elements/DatePicker'

type Props = {}

const RewardSummary = (props: Props) => {

    const date = new Date()

     //Points and Discount 

  const percentageMark = [
    {
      value: 0.05,
      label: '5%',
    },
    {
      value: 0.1,
      label: '10%',
    },
    {
      value: 0.15,
      label: '15%',
    },
    {
      value: 0.2,
      label: '20%',
    },
    {
      value: 0.25,
      label: '25%',
    },
    {
      value: 0.3,
      label: '30%',
    },
    {
      value: 0.35,
      label: '35%',
    },
    {
      value: 0.4,
      label: '40%',
    },
    {
      value: 0.45,
      label: '45%',
    },
    {
      value: 0.5,
      label: '50%',
    }
  ];

    return (

    <Stack spacing={4}>
        <Box sx={{width: '100%'}}>
        <Typography variant="h5">Summary of your Campaign</Typography>
        <Typography variant='subtitle1'>This is the campaign you will be running to grow your business!.</Typography>
        </Box>
        <TextField 
            label="Campaign Name"
            defaultValue="Grow my weakest day"
        />
        <Grid container>
            <Grid style={{paddingRight: '5px'}} item xs={6}>
                <BasicDatePicker fullWidth label="Campaign Start Date" initial={new Date()}/>
            </Grid>
            <Grid style={{paddingLeft: '5px'}}  item xs={6}>
                <BasicDatePicker fullWidth label="Campaign End Date" initial={new Date(date.setMonth(date.getMonth()+3))}/>
            </Grid>
        </Grid>
        <TextField 
            label="Rules"
            defaultValue="Days of the week"
        />
        <TextField 
            label="Select Days"
            defaultValue="Wednesday"
        />


        <Typography variant="h6">Points</Typography>
        <Box width={950}>
                            <Slider

                                defaultValue={0.2}
                                aria-labelledby="range-slider"
                                step={0.05}
                                min={0.05}
                                max={0.5}
                                marks={percentageMark}
                            />
                        </Box>


        <Typography variant="h6">App Message</Typography>
        <TextField 
            label="Title"
            defaultValue="Get 20% back on Wednesday!"
        />
        <TextField 
            label="Message"
            defaultValue="Earn 20% points back to use in your next purchase only when you spend on Wednesday's"
        />

    </Stack>

    


    );

}

export default RewardSummary