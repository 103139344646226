import React from 'react'
import Loader from '../../../assets/images/Ripple-1s-200px.svg'

const styles = {
    container: {
        display: 'flex',
        height: '100vh',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center'
    }
}

function MainLoader() {
    return (
        <div style={styles.container}>
            <img src={Loader}/>
        </div>
    )
}

export default MainLoader
