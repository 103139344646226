import React, { useState, useContext, useEffect } from 'react'
import TopNav from './navigation/TopNav'
import './dashboard.css'
import SideBar from './navigation/SideBar';
import DashboardRoutes from './routes/DashboardRoutes';
import { Switch } from 'react-router-dom'
import VerifyWizard from './verify/VerifyWizard'

import UserContext from '../../context/UserContext';

import url from '../../api/url';
import socketIOClient from "socket.io-client";

import axios from 'axios';
import Cookies from 'js-cookie'
import Complete from './animations/Complete';
import { toast, ToastContainer } from 'react-toastify';
import SideNavigator from './navigation/SideNavigator/SideNavigator';
import NewTopNav from './navigation/NewTopNav';


function DashboardLayout({ userSet, FetchUserData }) {

    const { userData, setUserData } = useContext(UserContext);

  const [socketId, setSocketId] = useState("");
  const [socketSet, setSocketSet] = useState(false)


    const [verified, setVerified] = useState(false);

    const [canAcceptPayments, setCanAcceptPayments] = useState(false);

    const [verificationStatus, setVerificationStatus] = useState('');

    const [loading, setLoading] = useState(true);
    const [sidenav, setSidenav] = useState(true)

//         useEffect(() => {



//              const socket = socketIOClient(`${url}`, {
//             path: "/business/socket",
//             withCredentials: true,
//             transportOptions: {
//             polling: {
//                 extraHeaders: {
//                     "my-custom-header": "abcd"
//                 }
//             }
//         }
//     });

//     socket.on('connect', () => {

//         setSocketId(socket.id)
//     })

//     socket.on('message', (data) => {

       

//         if(data.type === "identification") {


//           setVerificationStatus({
//                     status: data.status,
//                     message: data.message
//                 })

//             FetchUserData();

//         }


        

//     })

//         setSocketSet(true)

    

//   }, [])

  const [toastError, setToastError] = useState(false);

  useEffect(() => {

    console.log(userSet, "user set");

    if(userSet && !toastError && !userData.vizopay_status.can_accept_payments) {
        setToastError(true)
        toast.error("You cannot accept payments yet. We will activate you soon!",{position: "bottom-center",
        autoClose: 50000,
        hideProgressBar: true,}) 
    } else {
        return;
    }

  }, [userData])

  useEffect(() => {

    

    const StoreSocketSession = async() => {
        await axios.post(`${url}/business/save/socket`, {
            socket_id: socketId
        }, {
            headers: {
                'x-auth-token': Cookies.get('token')
            }
        }).then().catch(err => console.log(err.response, "error saving socket"));
    }

      if(socketId) {
            StoreSocketSession();
      } else {
          return;
      }




  }, [socketId])

    useEffect(() => {

        if (userSet) {
            setVerified(userData.store_added)
            setLoading(false)
        } else {
            setVerified(false)
            setLoading(false)
        }



    }, [userSet])




    return (
        <Switch>
            <div style={{ backgroundColor: 'transparent', height: '100%', marginBottom: 0 }}>
                <div>
                    <div style={{ paddingLeft: 0, paddingRight: 0 }} xs="2" md="0">
                        {sidenav ? <SideNavigator id="sidebar" /> : null}
                    </div>
                    <div id="mainContainer" style={{ paddingLeft: 0, paddingRight: 0 }} md="0" className="main-content-container" xs="10">
                        {/* <TopNav sidenav={sidenav} setSidenav={setSidenav} /> */}
                        <NewTopNav />
                        {
                            loading ?

                                "Loading..."

                                :

                                <div className="dashboard-content-container">
                                    {
                                        verified  ?
                                            <DashboardRoutes />
                                            :

                                            // verified && !userData.vizopay_status.can_accept_payments ?

                                            // <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%'}}>
                                            //     <Complete  height={150} width={150} autoplay={true} loop={false}/>
                                            //     <span style={{marginTop: '50px', marginBottom: '50px', fontFamily: 'Avenir', fontWeight: '700', fontSize: 16}}>Our team are looking into everything! We will get back to you within 1 business day.</span>
                                            // </div>

                                            // :
 
                                            <VerifyWizard verificationStatus={verificationStatus}/>
                                    }
                                </div>

                        }

                    </div>
                        <ToastContainer/>
                       
  
                    
                </div>
            </div>

        </Switch>
    )
}

export default DashboardLayout
