import React, {useState, useEffect,useContext} from 'react'
import { Button, Modal, FormGroup, ModalHeader, ModalBody, ModalFooter, Form,InputGroup, InputGroupAddon,  Row, Col, Input, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Spinner} from 'reactstrap';
import TextField from '@mui/material/TextField';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import LocationOnIcon from '@material-ui/icons/LocationOn';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import AuthContext from '../../../context/AuthContext';
import url from '../../../api/url';
import Cookies from 'js-cookie';
import "./stores.css"
import HandleError from '../ErrorHandler/HandleError';
import ButtonGroup from "@mui/material/ButtonGroup";
import axios from 'axios';
import {toast,ToastContainer} from 'react-toastify'
import "react-toastify/dist/ReactToastify.css"
import StoreBanner from './components/StoreBanner';
import { Divider } from '@mui/material/Divider';
import Slider from '@mui/material/Slider';

import AutocompleteGoogleMaps from './AutocompleteGoogleMaps'

function AddStore({modal, setModal}) {

    const toggle = () => setModal(!modal);
    const [role, setRole] = useState('Role')
    const { auth, setAuth } = useContext(AuthContext);
    const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => setDropdownOpen(prevState => !prevState);

  const [storeDescription, setStoreDescription] = useState("");
  const [storeDescriptionCL, setStoreDescriptionCL] = useState(0);

  const [loading, setLoading] = useState(false);



//Geocoding API

const [address, setAddress] = useState("");
const [formattedAddress, setFormattedAddress] = useState("");

const [placeId, setPlaceId] = useState(null);

const [geolocation, setGeolocation] = useState({});

const [addressSelected, setAddressSelected] = useState(false)

const getAddress = async() => {

    setAddressSelected(false);

    await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=AIzaSyDoBRoBijY8_R2aWW6dAk4tFbklqLwYkoo`)
        .then(res => {

            setFormattedAddress(res.data.results[0].formatted_address)
            setLatitude(res.data.results[0].geometry.location.lat);
            setLongitude(res.data.results[0].geometry.location.lng);

        })
        .catch(err => {console.log(err)
        setFormattedAddress("")
        });

} 

const [storeName, setStoreName] = useState("");
const [online,setOnline] = useState(false);
const [stripeVerified,setStripeVerified] = useState(false)
const [redirectUrl,setRedirectUrl] = useState("");
const [storeEmail,setStoreEmail] = useState("")
const [description, setDescription] = useState("");
const [urlRecieved,setUrlRecieved] = useState(false);
const [website, setWebsite] = useState("")
const [latitude, setLatitude] = useState("");
const [longitude, setLongitude] = useState("");
const [accountName, setAccountName] = useState("");
const [accountNumber, setAccountNumber] = useState("");
const [sortCode, setSortCode] = useState("");
const [storeCover, setStoreCover] = useState("");
const [stamp,setStamp] = useState({
  active: false,
  number_of_stamps_required: 9, // 10th time at store gets the reward
  reward_description:"",
  reward_type:  ""
})
const [points,setPoints] = useState({
  active: false, //£1 = 100
  number_of_points: 0.1
})
const [percentageDiscount,setPercentageDiscount] = useState({
  active: false,
  percentage_discount: 0.1, 
  discount_code: "",
  conditions: {
    new_user_only: false,
    on_terminal_only: false,
    student_discount_only:false
  }
})

const percentageMark = [
  {
    value: 0.05,
    label: '5%',
  },
  {
    value: 0.1,
    label: '10%',
  },
  {
    value: 0.15,
    label: '15%',
  },
  {
    value: 0.2,
    label: '20%',
  },
  {
    value: 0.25,
    label: '25%',
  },
  {
    value: 0.3,
    label: '30%',
  },
  {
    value: 0.35,
    label: '35%',
  },
  {
    value: 0.4,
    label: '40%',
  },
  {
    value: 0.45,
    label: '45%',
  },
  {
    value: 0.5,
    label: '50%',
  }
];


const pointMark = [
  {
    value: 0.05,
    label: '5%',
  },
  {
    value: 0.1,
    label: '10%',
  },
  {
    value: 0.15,
    label: '15%',
  },
  {
    value: 0.2,
    label: '20%',
  },
  {
    value: 0.25,
    label: '25%',
  },
  {
    value: 0.3,
    label: '30%',
  },
  {
    value: 0.35,
    label: '35%',
  },
  {
    value: 0.4,
    label: '40%',
  },
  {
    value: 0.45,
    label: '45%',
  },
  {
    value: 0.5,
    label: '50%',
  }
];


const displayCounter = stamp.number_of_stamps_required > 0;

const handleIncrement = () => {
  setStamp(prevState => ({...prevState, number_of_stamps_required: prevState.number_of_stamps_required + 1 }));
};

const handleDecrement = () => {
  setStamp(prevState => ({...prevState, number_of_stamps_required: prevState.number_of_stamps_required -1 }));
};
//Create handleDecrement event handler

const takeToStripe = () => {
  window.location.href = redirectUrl;
}
console.log(online)
  
var bodyFormData = new FormData();


    const saveStore = async() => {

      setLoading(true);

        bodyFormData.append('store_name', storeName)
        bodyFormData.append('store_email',storeEmail)
        bodyFormData.append('website', website)
        bodyFormData.append('description', description)
        bodyFormData.append('place_id', placeId)
        // bodyFormData.append('latitude', latitude)
        // bodyFormData.append('longitude', longitude)
        bodyFormData.append('account_name', accountName)
        bodyFormData.append('account_number', accountNumber)
        bodyFormData.append('sort_code', sortCode)
        bodyFormData.append('storeCover', storeCover)
        bodyFormData.append('address', formattedAddress)
        bodyFormData.append('online', online)
        bodyFormData.append('stamp',JSON.stringify(stamp))
        bodyFormData.append('points',JSON.stringify(points))
        bodyFormData.append('percentage_discount',JSON.stringify(percentageDiscount))

        await axios({

                method: "post",
                url: `${url}/business/stores`,
                data: bodyFormData,
                headers: { "Content-Type": "multipart/form-data", "x-auth-token": Cookies.get("token") },

                })
                .then(res => {
                  setLoading(false)
                  if(res.status >= 200 < 300){
                  toast.success("Store successfully added", {
                    position: toast.POSITION.BOTTOM_CENTER
                  });}
                  setStripeVerified(res.data.verified)
                  setRedirectUrl(res.data.redirect_url)
                  setUrlRecieved(true)

                })
                .catch(err => { 
                    setLoading(false);
                    if(err.response.data.message === "jwt expired") {
                      Cookies.remove('token');
                      setAuth(false);
                    }
                    HandleError(err.response.status,err.response.data.message)
                })
                
    }

    useEffect(() => {

      setStoreDescriptionCL(description.length)

    }, [description])



    return (
          <div>
      <Modal isOpen={modal} toggle={toggle} className="add-team-modal" >
        <ToastContainer/>
        <ModalHeader toggle={toggle}>Add a Store</ModalHeader>
        {
          urlRecieved ?
          <ModalBody >

          <div className="add-team-modal-body">
           {
             stripeVerified?
             <>
             <Row style={{marginTop: 20, marginBottom: 20}}>
             <Button onClick={toggle} color="success">Close</Button>    
           </Row>
           <Row>
             <small>All set your store has been added</small>
           </Row>
           </>
           :
           <> 
          <Row style={{marginTop: 20, marginBottom: 20}}>
              <Button onClick={takeToStripe} color="success">Take me to Stripe</Button>    
            </Row>
            <Row>
              <small>Finish adding your store by onboarding with our partner stripe</small>
            </Row>
            </>
           }
           </div>
           </ModalBody>
           :
           <>
           
           {
              loading ?
              <ModalBody style={{minHeight:"10vh"}}>
              <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <Spinner style={{color: '#65ccb8'}}/>
              </div>
              </ModalBody>
               :
               <>
          <ModalBody>
              <div className="add-team-modal-body">
            <Row>
                Add your store so that you can start accepting payments at <a>https://terminal.vizopay.com</a>
            </Row>
            <Row style={{marginTop: 20, marginBottom: 20}}>
              <Col >
                  <Form>
                      <FormGroup>
                          <h4>Store Details</h4>
                      </FormGroup>
                       
                     <FormGroup>
                      
                      <TextField onChange={(e) => setStoreName(e.target.value)} className="form-input" style={{width: '100%'}} id="outlined-size-small" label="Store Name" variant="outlined" size="small" placeholder="Coffee Co." size="medium"/>
          
                  </FormGroup>
                  <FormGroup>
                      
                      <TextField onChange={(e) => setStoreEmail(e.target.value)} className="form-input" style={{width: '100%'}} id="outlined-size-small" label="Store Email" variant="outlined" size="small" placeholder="store@example.com" size="medium"/>
          
                  </FormGroup>
         <FormGroup>
                      
                      <TextField onChange={(e) => {
                        setDescription(e.target.value)
                      }}  className="form-input" style={{width: '100%'}} id="outlined-multiline-static" rows={4} label="Store Description" variant="outlined" size="small" multiline placeholder="Add your store description so that people understand more about your store." size="medium"/>
                      <Row>
                          <div className="container-character-count">
                              <small>{storeDescriptionCL}/160</small>
                          </div>
                      </Row>
                              {(description.length > 4 && description.length < 20) && <span className='error'>Store description should atleast be 20 characters long</span>}
                      
                  </FormGroup>
                  <FormGroup>
                        <small>If you have an online and a physical store, you will need to add to separate stores.</small>
                        </FormGroup>
                  <FormGroup>
                              <label class="container">
                          <Input style={{width: '100%'}} id="outlined-basic" label="Password" variant="outlined" checked={online} value={online} onChange = {(e) => setOnline(prevState => !prevState)} type="checkbox" />
                          <span style={{border: '1px solid #65ccb8'}} class="checkmark"></span>
                          <span style={{ marginLeft: '10px' }}>Online Store</span>
                      </label>

                        </FormGroup>
                        
                   <FormGroup>
                      
                      <TextField onChange={(e) => setWebsite(e.target.value)} className="form-input" style={{width: '100%'}} id="outlined-size-small" label="Website" variant="outlined" size="small" placeholder="https://www.yourstore.com" size="medium"/>
                  </FormGroup>
                   <FormGroup>
                          <h4>Store Location</h4>
                          <small>Let people know where to find you!</small>
                      </FormGroup>
                  <FormGroup>
                
                      <AutocompleteGoogleMaps placeId={placeId} setPlaceId={setPlaceId}/>
         
                      {/* <TextField onChange={(e) => setAddress(e.target.value)} className="form-input" style={{width: '100%'}} id="outlined-size-small" label="Enter your Postcode or Address" variant="outlined" size="small" placeholder="E.g. Coffee Co, 71-75 Shelton Street, London" size="medium"/>
                      {(address.length > 0 &&address.length < 3) && <span className='error'>Please Enter Valid Address</span>} */}
                      {/* <Row>
                          <div className="address-button">
                              <Button onClick={getAddress}>Search Address</Button>
                          </div>
                      </Row> */}
                      <Row>
                          <div className="formatted-address-container">
                              {
                                  formattedAddress ?
  
                                  <div>
                                      <List component="nav" aria-label="main mailbox folders">
          <ListItem  onClick={() => {setAddressSelected(!addressSelected)}} button>
            {
                addressSelected ?
  
              <>
              <ListItemIcon >
             
  
                      <CheckBoxIcon style={{color: '#65ccb8'}} />
  
            </ListItemIcon>
            <ListItemText primary={formattedAddress} />
              </>
  
  
                :
  
              <>
              <ListItemIcon >
          
                  <CheckBoxOutlineBlankIcon />
                  
            </ListItemIcon>
            <ListItemText primary={formattedAddress} />
              </>
                
            }
  
          </ListItem>
        </List>
                                  </div>
                                  :
  
                                  null
  
                              }
                          </div>
                      </Row>
                  </FormGroup>
                  <FormGroup>
                      <h4>Bank Details</h4>
                      <small>We need your bank details so you can get paid!</small>
                  </FormGroup>
                   <FormGroup>
                      
                      <TextField onChange={(e) => setAccountName(e.target.value)} className="form-input" style={{width: '100%'}} id="outlined-size-small" label="Account Name" variant="outlined" size="small" placeholder="JENNY DOE" size="medium"/>
  
                  </FormGroup>
                  <FormGroup>
                      
                      <TextField onChange={(e) => setAccountNumber(e.target.value)} className="form-input" style={{width: '100%'}} id="outlined-size-small" label="Account Number" variant="outlined" size="small" placeholder="01000112" size="medium"/>
                      {(accountNumber.length > 1 &&accountNumber.length !== 8) && <span className='error'>Account number must be 8 characters long</span>}
                  </FormGroup>
                  <FormGroup>
                      
                      <TextField onChange={(e) => setSortCode(e.target.value)} className="form-input" style={{width: '100%'}} id="outlined-size-small" label="Bank Sort Code" variant="outlined" size="small" placeholder="01-02-03" size="medium"/>
                      {(sortCode.length > 1 &&sortCode.length !== 6) && <span className='error'>Sort Code must be 6 characters long</span>}
                  </FormGroup>
                  <FormGroup>
                      <h4>Cover Photo</h4>
                      <small>Get an awesome page on our app for your store to reach new customers.</small>
                  </FormGroup>
                  <FormGroup>
                            {/* File Attachment Input */}
        <label className="custom-file-boxed" htmlFor="customFileInputBoxedEg">
          {
            storeCover ?
                      <span id="customFileBoxedEg">{storeCover.name}</span>
  
            :
            <>
            <span id="customFileBoxedEg">Browse your device and upload documents</span>
          <small className="d-block text-muted">Maximum file size 10MB</small>
          </>
          }
          <input onChange={(e) => {
            setStoreCover(e.target.files[0])
            
          }} id="customFileInputBoxedEg" name="custom-file-boxed" type="file" className="js-file-attach custom-file-boxed-input" />
        </label>
        {/* End File Attachment Input */}
                  </FormGroup>
                    <FormGroup>
                      <h4>Rewards</h4>
                      <small>Enhance your offering by adding reward to each purchase.</small>
                  </FormGroup> 
                  <FormGroup>
                    <small>What type of rewards would you like to offer?</small>
                    <p>
                    <small>Select all that apply</small>
                    </p>
                    <p>
                    <small>Donot select any if you would like to not offer any rewards</small>
                    </p>
                  </FormGroup>
                  <FormGroup>
                  <Row>
                          <Col>
                              <label class="container">
                          <Input style={{width: '100%'}} id="outlined-basic" label="Password" variant="outlined" checked={stamp.active} value={stamp.active} onChange = {(e) => setStamp(prevState => {return {...prevState,active :!stamp.active}})} type="checkbox" />
                          <span style={{border: '1px solid #65ccb8'}} class="checkmark"></span>
                          <span style={{ marginLeft: '10px' }}>Stamp based</span>
                      </label>
                          </Col>
                          <Col>
                              <label class="container">
                          <Input style={{width: '100%'}} id="outlined-basic" label="Password" variant="outlined" checked={points.active} value={points.active} onChange= {(e) => setPoints(prevState => {return {...prevState,active:!points.active}})} type="checkbox" />
                          <span style={{border: '1px solid #65ccb8'}} class="checkmark"></span>
                          <span style={{ marginLeft: '10px' }}>Points based</span>
                      </label>
                          </Col>
                          <Col>
                              <label class="container">
                          <Input style={{width: '100%'}} id="outlined-basic" label="Password" variant="outlined" checked={percentageDiscount.active} value={percentageDiscount.active} onChange= {(e) => setPercentageDiscount(prevState => {return {...prevState,active:!percentageDiscount.active}})} type="checkbox" />
                          <span style={{border: '1px solid #65ccb8'}} class="checkmark"></span>
                          <span style={{ marginLeft: '10px' }}>Discount based</span>
                      </label>
                          </Col>
                      </Row>
                  </FormGroup>
                  {
                    stamp.active &&
                   
                     <FormGroup>
                       <FormGroup>
                      <h4>Stamp Based</h4>
                  </FormGroup>
                        <div style={{marginTop: 20, marginBottom: 20}}>
                        <ButtonGroup size="small" aria-label="small outlined button group">
                      {displayCounter && <Button onClick={handleDecrement}>-</Button>}
                      {displayCounter && <Button disabled>{stamp.number_of_stamps_required}</Button>}
                      <Button onClick={handleIncrement}>+</Button>
                        </ButtonGroup>
                        <span style={{ marginLeft: '20px' }}>Number of stamps required to get reward</span>
                        </div>
                        <FormGroup>
                      <TextField  onChange={(e) => {
                        setStamp(prevState => ({...prevState,reward_description:e.target.value}))
                      }}  className="form-input" style={{width: '100%'}} id="outlined-multiline-static" rows={4} label="Reward Description" variant="outlined" size="small" multiline placeholder="Add your reward description so that people understand how to get the reward." size="medium"/>
                      <Row>
                          <div className="container-character-count">
                              <small>{storeDescriptionCL}/160</small>
                          </div>
                      </Row>
                              {(stamp.reward_description.length > 4 && stamp.reward_description.length < 20) && <span className='error'>Reward description should atleast be 20 characters long</span>}
  
                      <FormGroup>
                      
                      <TextField onChange={(e) => setStamp(prevState => ({...prevState,reward_type:e.target.value}))} className="form-input" style={{width: '100%'}} id="outlined-size-small" label="Reward Type" variant="outlined" size="small" placeholder="Free Coffee" size="medium"/>
          
                      </FormGroup>
                  </FormGroup>
                     </FormGroup>
                  }
  
                   {
                    points.active &&
                   
                     <FormGroup>
                       <FormGroup>
                       <h4>Points Based</h4>
                       <small>Pick percentage of cashback you would like to offer your customers on every purchase</small>
                       </FormGroup>
                       <Slider style={{color:"#65ccb8"}}
                  onChange={(e, newValue) => setPoints(prevState => ({...prevState,number_of_points:newValue}))}
                  defaultValue={`${points.number_of_points}`}
                  aria-labelledby="range-slider"
                  step={0.05}
                  min={0.05}
                  max={0.5}
                  marks={pointMark} />
                     </FormGroup>
                    
                  }
                  {
                    percentageDiscount.active &&
                     <FormGroup>
                       <FormGroup>
                       <h4>Discount Based</h4>
                       </FormGroup>
                       <Slider style={{color:"#65ccb8"}}
                  onChange={(e, newValue) => setPercentageDiscount(prevState => ({...prevState,percentage_discount:newValue}))}
                  defaultValue={`${percentageDiscount.percentage_discount}`}
                  aria-labelledby="range-slider"
                  step={0.05}
                  min={0.05}
                  max={0.5}
                  marks={percentageMark} />
                      <FormGroup>
                      <small>Specify the discount code if you would like to have one</small>
                        </FormGroup>
                        <FormGroup style={{marginTop: 20, marginBottom: 20}}>
                      <TextField onChange={(e) => setPercentageDiscount(prevState => ({...prevState,discount_code:e.target.value}))} className="form-input" style={{width: '100%'}} id="outlined-size-small" label="Discount Code" variant="outlined" size="small" placeholder="VIZOPAY10" size="medium"/>
                      </FormGroup>
                      <FormGroup>
                      <small>If you would like only specific users to get the discounts, please select below </small>
                        </FormGroup>
                      <FormGroup>
                      <Row>
                      
                          <Col>
                              <label class="container">
                          <Input style={{width: '100%'}} id="outlined-basic" label="Password" variant="outlined" checked={percentageDiscount.conditions.new_user_only} value={percentageDiscount.conditions.new_user_only} onChange = {(e) => setPercentageDiscount(prevState => {return {...prevState,conditions:{...prevState.conditions,new_user_only:!percentageDiscount.conditions.new_user_only}}})} type="checkbox" />
                          <span style={{border: '1px solid #65ccb8'}} class="checkmark"></span>
                          <span style={{ marginLeft: '10px' }}>New users </span>
                      </label>
                          </Col>
                          <Col>
                              <label class="container">
                          <Input style={{width: '100%'}} id="outlined-basic" label="Password" variant="outlined" checked={percentageDiscount.conditions.on_terminal_only} value={percentageDiscount.conditions.on_terminal_only} onChange = {(e) => setPercentageDiscount(prevState => {return {...prevState,conditions:{...prevState.conditions,on_terminal_only:!percentageDiscount.conditions.on_terminal_only}}})} type="checkbox" />
                          <span style={{border: '1px solid #65ccb8'}} class="checkmark"></span>
                          <span style={{ marginLeft: '10px' }}>On terminal </span>
                      </label>
                          </Col>
                          <Col>
                              <label class="container">
                          <Input style={{width: '100%'}} id="outlined-basic" label="Password" variant="outlined" checked={percentageDiscount.conditions.student_discount_only} value={percentageDiscount.conditions.student_discount_only} onChange = {(e) => setPercentageDiscount(prevState => {return {...prevState,conditions:{...prevState.conditions,student_discount_only:!percentageDiscount.conditions.student_discount_only}}})} type="checkbox" />
                          <span style={{border: '1px solid #65ccb8'}} class="checkmark"></span>
                          <span style={{ marginLeft: '10px' }}>Student Only</span>
                      </label>
                          </Col>
                      </Row>
                      </FormGroup>
                     </FormGroup>
                  }
                </Form>
              </Col>
                
            </Row>
            </div>
          </ModalBody>
          <ModalFooter>
              <Button onClick={saveStore} color="success">Add</Button>
            <Button color="danger" onClick={toggle}>Cancel</Button>
          </ModalFooter>
          </>
           }
           </>
        }
         

      </Modal>
    </div>
  );
}

export default AddStore;
