import { useQuery } from '@apollo/client';
import { faBell, faCheckCircle, faMailBulk } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, CircularProgress, Container, FormControl, InputAdornment, InputLabel, MenuItem, Select, TextField, Toolbar, Tooltip, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { DatePicker, DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import axios from 'axios';
import { error } from 'console';
import Cookies from 'js-cookie';
import React from 'react'
import url from '../../api/url';
import { FETCH_STORES } from '../../apollo/queries';
import BasicDatePicker from '../../elements/DatePicker';
import Toast from '../../elements/Notifications/Toast';

type Props = {
    user: {
        email: string;
        customer_id: string;
    }
}

const AddCredit = ({
    user,
}: Props) => {

    const date = new Date();
    const created_gte = new Date(date.getFullYear(), date.getMonth() + 1, date.getDate())


    const [success, setSuccess] = React.useState<boolean>(false);

    const [dateExp, setDateExp] = React.useState<Date|any>(created_gte);

    const {data, loading, error} = useQuery(FETCH_STORES);

    const [amount, setAmount] = React.useState<number>(0);
    const [maxAmount, setMaxAmount] = React.useState<number>(0);
    const [minAmount, setMinAmount] = React.useState<number>(0);
    const [name, setName] = React.useState<string>("");
    const [storeId, setStoreId] = React.useState<string>("");

    const [errors, setErrors] = React.useState([]);

    const MakePennies = (number: number) => {
        return number*100
    }

    React.useEffect(() => {

        let errorsArr = [...errors];

        if(amount) {
            let number = amount;

            if(number < 100) {

                errorsArr.push("Amount must be greater than £1.00")
                setErrors(errorsArr)

            } else {
                setErrors([])
            }
        }

        if(maxAmount) {
            let number = maxAmount;

            if(number > amount || number < 100) {
                errorsArr.push("Maximum amount cannot be greater than total amount and cannot be less than £1.")
                setErrors(errorsArr)
            } else {
                setErrors([])
            }
        }

        if(minAmount) {
            let number = maxAmount;

            if(number < maxAmount) {
                errorsArr.push("Minumum amount must be greater than maximum applicable.")
                setErrors(errorsArr)
            } else {
                setErrors([])
            }
        }


    }, [amount, maxAmount, minAmount])

    React.useEffect(() => {
        console.log(errors, "ERRORS")
    }, [errors])

    const AddCredit = async() => {
        await axios.post(`${url}/business/add-credit`, {
            store_id: storeId,
            name: name,
            customer_id: user.customer_id,
            total: amount,
            maximum_amount_applicable: maxAmount,
            minimum_charge: minAmount,
        }, {
            headers: {
                'x-auth-token': Cookies.get('token')
            }
        })
        .then(res => {
            console.log(res, "RES")
            setSuccess(true)
        })
        .catch(err => console.log(err, "ERR"))
    }

  return (
    <Stack spacing={4}>
        <Typography paddingX="20px" textAlign="center" variant="h5">
        <FontAwesomeIcon color="#65ccb8" style={{marginRight: 10}} icon={faMailBulk}/> Send {user.email} a Voucher
        </Typography>
        <Typography variant="subtitle1">Say thanks by giving your customer a voucher to spend! It's a great way to engage loyal customers.</Typography>
        <Typography variant="subtitle1">When you add a voucher and credit we automatically send a notification to the user via email or push!</Typography>

        <Stack spacing={2}>
            <Tooltip title="The total value of the voucher e.g. £5">
                <TextField onChange={(e) => setAmount(MakePennies(Number(e.target.value)))} label="Total Amount" 
                placeholder='E.g. 5'
                InputProps={{
                    type: "number",
            startAdornment: <InputAdornment position="start">£</InputAdornment>,
          }}
                />
            </Tooltip>

            <Tooltip title="Maximum amount a user can apply in a single use. If left blank will be equal to total value.">
                <TextField onChange={(e) => setMaxAmount(MakePennies(Number(e.target.value)))} label="Maximum Applicable" 
                placeholder='E.g. 2.50'
                InputProps={{
                    type: "number",
            startAdornment: <InputAdornment position="start">£</InputAdornment>,
          }}
                />
            </Tooltip>

            <Tooltip title="Minimum amount a user can spend to apply the voucher. Must be greater than maximum amount.">
                <TextField onChange={(e) => setMinAmount(MakePennies(Number(e.target.value)))} label="Minimum Spend" 
                placeholder='E.g. 7.50'
                InputProps={{
                    type: "number",
            startAdornment: <InputAdornment position="start">£</InputAdornment>,
          }}
                />
            </Tooltip>

            <Tooltip title="Name of the Voucher">
                <TextField inputProps={{ maxLength: 30 }} onChange={(e) => setName(e.target.value)} label="Name" 
                placeholder='E.g. Burger Shack £5.00 Snack on us'
                />
            </Tooltip>

          
            <FormControl fullWidth>
            <InputLabel id="store-id-select-label">Store</InputLabel>
            <Select
          labelId="store-id-select-label"
          id="store-id-select"
          value={storeId}
          label="Store"
          onChange={(e) => setStoreId(e.target.value)}
        >
          {
            data?.stores.map((store) => {
                return(
                    <MenuItem key={store.store_id} value={store.store_id}>{store.store_name}</MenuItem>
                )
            })
          }

        </Select>
            </FormControl>

{/* 
          <div >
          <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DesktopDatePicker 
        
                    className="date-picker-modal"
                         label="Date desktop"
                         inputFormat="MM/DD/YYYY"
                         value={new Date()}
                         onChange={(e) => console.log(e)}
                         renderInput={(params) => <TextField {...params} />}
                
            />
          </LocalizationProvider>
          </div> */}
           

            {
                loading ?

                <Button disabled size="large" variant='outlined'>

                        <CircularProgress style={{marginLeft: 8}} size={18}/>
                    </Button>

                :

                success ?

                <Button color="info" size="large" variant='outlined'>Close</Button>

                    :

                    <Button onClick={() => {

                        AddCredit();
                        }} size="large" variant='outlined'> 
    

                            
                            Send Voucher 
                            <FontAwesomeIcon style={{marginLeft: 8}} icon={faCheckCircle}/>
                          
                        </Button>
            }

            {

                errors.length ?

                errors.map((err) => {
                    return(
                        <Typography variant="body1" color="error">{err}</Typography>
                    )
                })

                :

                null
            }
        </Stack>

        <Toast 
               show={success}
               message="Voucher successfully added!"
               severity='success'
        />
    </Stack>
  )
}

export default AddCredit;