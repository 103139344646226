import React from 'react'
import Lottie from 'react-lottie';

import animationData from '../../lottie/securing.json';

function Securing(props) {

       const defaultOptions = {
      loop: props.loop,
      autoplay: props.autoplay, 
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    };

    return (
        <div style={{marginTop: 20, marginBottom: 20}}>
             <Lottie options={defaultOptions}
              height={props.height}
              width={props.width}
              isPaused={false}
              isStopped={false}
         />
        </div>
    )
}

export default Securing
