import React, { useContext, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Cookies from 'js-cookie';

import './verify-form.css';
import VeriffContainer from './Veriff';
import ConnectStore from './ConnectStore';

import axios from 'axios';
import url from '../../../api/url';

import UserContext from '../../../context/UserContext';
import Securing from '../animations/Securing';
import { Box, Container, Paper } from '@mui/material';
import { Stack } from '@mui/system';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  active: {
    backgroundColor: '#fff'
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

function getSteps() {
  return ['Verification', 'Connect Your Store'];
}

function VerifyWizard({verificationStatus}) {
  const { userData } = useContext(UserContext);

  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(1);
  const steps = getSteps();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (userData.onboarding != undefined) {
      if (userData.onboarding.veriff_status == "approved") {
        setActiveStep(1);
      }
    }

    const bankConnect = async (code) => {
      await axios.post(`${url}/business/onboarding/connect`,
        {},
        {
          headers: {
            'x-auth-token': Cookies.get('token'),
            'code': code
          }
        }
      ).then(response => {
        setLoading(false);
        if (response.data.status) {
          window.location.href = "https://business.vizopay.com/";
        } else {
          alert(response.data.message);
        }
      }).catch(error => {
        setLoading(false);
        console.log(error);
      })
    }
    var code = window.location.search.split("&")[0].replace("?code=", "");
    if (code) {
      bankConnect(code);
    } else {
      setLoading(false);
    }
  }, [verificationStatus])


 
  return (
    <>
      <Box
      justifyContent="center"
      alignItems="center"
      width="100%"
      >
        <Paper
          variant='outlined'
          style={{padding: 40}}
        >
        {
          !loading && userData.onboarding ?
            <Stack
              spacing={4}
              direction="column"
              justifyContent="center"
            
            >
              
                <h3 style={{marginTop: 20, marginBottom: 20, textAlign: 'center'}}>We just need a few details</h3>
                <p style={{marginTop: 20, marginBottom: 20, textAlign: 'center'}}>As a payment service provider we need to know who we do business with to keep our community safe. It only takes a few minutes!</p>
              
              <Stepper style={{ backgroundColor: 'transparent', marginTop: 20, marginBottom: 20 }} activeStep={activeStep} alternativeLabel>
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
              <>
                {
                  activeStep == 0 ?

                    <div className="veriff-root">
                        {
                          
                        (userData.onboarding.veriff_status !== "approved" && userData.onboarding.veriff_status !== "submitted" && userData.onboarding.veriff_status !== "resubmission_requested" && userData.onboarding.veriff_status !== "declined") ?
                          <VeriffContainer />
                          :

                          (userData.onboarding.veriff_status === "resubmission_requested")  ?

                          <div>

                          <div style={{paddingTop: 20, paddingBottom: 20}}>
                            <span style={{fontSize: 16, fontFamily: "", fontWeight: '700', margin: '20px auto' }}>
                          {"We need you to resubmit your id check due to the following reason(s): " + userData.onboarding.veriff_message.toLowerCase() + "."}
                      </span>  
                          </div>  

                          <VeriffContainer />


                          </div>

                          :  userData.onboarding.veriff_status === "submitted" || userData.onboarding.veriff_status === "declined"?

                          <div>

                            <Securing height={150} width={150} autoplay={true} loop={true}/>
                          <span style={{fontSize: 16, fontFamily: "", fontWeight: '700', margin: '20px auto' }}>
                          {"Your details have been submitted. We will let you know when you can move onto the next step."}
                      </span>    

                          </div>
                          
                          : null
                      
                        }

                    
                    </div>
                        :
                    <ConnectStore />
                    
                }
              </>
            </Stack>
            : "Loading.."
        }
        </Paper>
      </Box>
    </>
  )
}

export default VerifyWizard