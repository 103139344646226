import React from 'react'
import ReactEcharts from 'echarts-for-react';
import { FormatCurrency2 } from '../../../utils/customers';

function WeeklyChart(bestDay) {

     const getColor = (color) => {

        let value;

        switch(color) {
  case 'primary':
    value = "#65ccb8"
    break;
  case 'dark':
    value = "#222831"
    break;

  case 'danger':
    value = "#FF9F9F"
    break;

  case 'warning':
    value = "#DEC1F7"
    break;

  case 'success':
    value = "#96FEA0"
    break;

  case 'info':
      value = "#FFD572"
      break;

  default:
    value="#65ccb8"
}
        
        return value;
    }

const months = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday',
  ];

  const data = [1000, 1301, 1402, 1216, 1086, 1236, 1219]; 

  const [totalValues, setTotalValues] = React.useState([]);

  const sortArr = () => {

    let arr = [];
    bestDay.bestDay.map((day) => {

      arr.push(
        [
          day.day_of_week,
          (day.total_amount/100).toFixed(2)
        ]
      )

    })

    setTotalValues(arr);

  }

  React.useEffect(() => {
    sortArr();
  }, [bestDay])


  const [options, setOptions] = React.useState({})


  const getOption = () => (
    setOptions(
      {
        tooltip: {
           trigger: 'axis',
           padding: [7, 10],
           backgroundColor: getColor('100'),
           borderColor: getColor('300'),
           textStyle: { color: getColor('dark') },
           borderWidth: 1,
           // formatter: tooltipFormatter,
           formatter: (value) => `${value[0].data[0]}<br /> <br />
                                  ${(value[0].seriesName)}: ${("£"+(value[0].data[1]/1).toFixed(2)).bold()}`,
           transitionDuration: 0,
           axisPointer: {
             type: 'none'
           }
         },
         xAxis: {
           type: 'category',
           data: months,
           axisLine: {
             lineStyle: {
               color: getColor('300'),
               type: 'solid'
             }
           },
           axisTick: { show: false },
           axisLabel: {
             color: getColor('400'),
             formatter: value => value.substring(0, 3),
             margin: 15
           },
           splitLine: {
             show: false
           }
         },
         yAxis: {
           type: 'value',
           axisLabel: {
             show: true,
             color: getColor('400'),
             formatter: value => FormatCurrency2(value),
             margin: 15
           },
           splitLine: {
             show: false,
             lineStyle: {
               color: getColor('200')
             }
           },
           axisTick: { show: false },
           axisLine: { show: false },
         },
         series: [
           {
             type: 'bar',
             name: 'Volume',
             data: totalValues,
             lineStyle: { color: getColor('primary') },
             itemStyle: {
               color: getColor('primary'),
               borderRadius: [3, 3, 0, 0]
             },
             showSymbol: false,
             symbol: 'circle',
             smooth: false,
             emphasis: {
               scale: true
             }
           }
         ],
         grid: { right: '3%', left: '10%', bottom: '10%', top: '5%' }
       }
    )
    );

    React.useEffect(() => {

       getOption()

    }, [totalValues])

    return (
          <ReactEcharts

              option={options}

            />
    )
}

export default WeeklyChart
