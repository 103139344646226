import React from 'react'
import ReactCodeInput from 'react-verification-code-input';
import axios from 'axios';
import url from '../../../api/url';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function CodeVerify({ setCode, email }) {

    async function resendCode() {
        await axios.post(`${url}/business/signup/resend`, {
            email: email,
            firstName: ""
        }).then(response => {
            toast.dark("Code has been resend.");
        }).catch(error => {
        })
    }

    return (
        <>
            <ToastContainer
                hideProgressBar
                autoClose={2000}
                position="top-center"
            />
            <div className="fa-container">
                <div>
                    <h5>
                        Please enter the code we just sent to {email}.
                    </h5>
                </div>
                <div>
                    <h5>
                    Make sure to check your junk folder in your email
                    </h5>
                </div>
                <div style={{marginTop: 10, marginBottom: 10}}>
                    <ReactCodeInput type="number" style={{borderColor: '#65ccb8'}} onChange={(e) => {
                        setCode(e)
                    }} />
                </div>
                <span style={{ margin: '10px auto' }}>I didn't get one! <a className="auth-link" onClick={() => resendCode(true)}>Resend</a></span>
            </div>
        </>
    )
}

export default CodeVerify