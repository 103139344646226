import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Typography } from '@mui/material'
import { Box, Stack } from '@mui/system'
import React from 'react' 

type Props = {
  reward: {
    reward: String;
    description: String;
    selected: Boolean;
    suggestion: String;
    id: number;
  };
  onChange: (index: number, select: number) => void;
}

const RewardCard = ({
  reward,
  onChange
}: Props) => {

  //backgroundColor: '#49AFA5'
  
  const [selected, setSelected] = React.useState(false);

  React.useEffect(() =>{
   if(onChange && selected === true) {
    let select = 1
    onChange(reward.id, select)
   } else if (onChange && selected === false) {
    let select = 2
    onChange(reward.id, select)
   } else
    return;
  }, [selected])

  
  return (
    <Box sx={{backgroundColor: '#49AFA5', padding: 2, borderRadius: "10px"}} >
        <Stack spacing={3}>
            <Typography variant="h5" fontWeight={"550"} color="#fff">{reward.reward}</Typography>
            <Typography color="#fff">{reward.description}</Typography>
            <Stack spacing={2}>
            <Typography fontWeight={"400"} textAlign="center" color="#fff">{reward.suggestion}</Typography>
            </Stack>
            {
              !selected ? 

              <Button onClick={() => setSelected(!selected)}  variant="outlined" color="secondary">Select</Button>

              :

              <Button onClick={() => setSelected(!selected)} variant="outlined" color="secondary">Selected <FontAwesomeIcon style={{marginLeft: 5}} icon={faCheckCircle}/></Button>

            }
        </Stack>

    </Box>
  )
}

export default RewardCard