import React, {useContext,useState,useEffect} from 'react'
import { Page, Text, View, Document, StyleSheet,Image,Polygon,G,Svg } from '@react-pdf/renderer';
import firstPagePhoto from "../../../../assets/images/reportCoverCop.png";
import vizopayLogo from "../../../../assets/images/Vizopay Logo-01.png"
import frontpage from "../../../../assets/images/A4 - Front Page.png"
import reporttop from "../../../../assets/images/A4 - The Report Top.png"
import reportbottom from "../../../../assets/images/A4 - The Report Bottom.png"

import details from "../../../../assets/images/Details.png"
import * as htmlToImage from "html-to-image";

import { Doughnut } from 'react-chartjs-2';

export const data = {
  labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
  datasets: [
    {
      label: 'Carbon Capture',
      data: [12, 19, 3, 5, 2, 3],
      backgroundColor: [
        'rgba(255, 99, 132, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(255, 206, 86, 0.2)',
        'rgba(75, 192, 192, 0.2)',
        'rgba(153, 102, 255, 0.2)',
        'rgba(255, 159, 64, 0.2)',
      ],
      borderColor: [
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(153, 102, 255, 1)',
        'rgba(255, 159, 64, 1)',
      ],
      borderWidth: 1,
    },
  ],
};


const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#F9F9FA',
    fontFamily: 'Avenir',
  },

  logoImg:{
    maxHeight: "200px",
    width: "200px",
    position:"absolute",
    right:"10px"
  },
  backgroundImg:{
    zIndex: -1,
  },
  titleSection: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'left',
    alignItems: 'left',
    padding: "30 0 0 20"
  },
  mainText: {
    fontSize: "12px", 
    fontFamily: "Helvetica", 
    fontWeight: '300',
    color: "#222831"
  },
  footerText: {
    fontSize: "10px", 
    fontFamily: "Helvetica", 
    fontWeight: '200'
  },
  header: {
    fontSize: '28px',
    fontWeight: '700',
    color: '#5c8d89'

  },
  vizopayIntroductionSection: {
    flexGrow: 1,
    padding: "30px 35px",
    justifyContent: 'center',
  },
  mainSection: {
    height: '165px',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#F9F9FA'

  },

  reportContainer: {
      padding: 20,
      flexDirection: 'column',
      justifyContent: 'space-around'
  },
    reportItemOne: {
    padding: '0 10 10 10',
    flexDirection: 'row',
    justifyContent: 'space-between'
},
  reportItem: {
      padding: 10,
      flexDirection: 'row',
      justifyContent: 'space-between'
  },
  reportItemHeading: {
      color: '#74b49b',
      fontSize: "12px", 
    fontFamily: "Helvetica", 
    fontWeight: '600'
  },
  chart:{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    bottom:"30px"
  },
  detail:{
    maxHeight: "700px",
    width: "550px",
    paddingLeft:"3vw",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  footerSection: {
    height:"2vh",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'left',
    backgroundColor: '#F9F9FA',
    padding: "0 0 0 10"
  }
});

function SustainabilityPDF({userData,Imgurl}) {

  


    return (
        <Document>

          <Page  size="A4"  >
          <Image  src={frontpage} />
          </Page>

          <Page  size="A4"  >
          <Image  src={reporttop} />

          <View style={styles.mainSection}>
                    <View style={styles.reportContainer}>
                        <View style={styles.reportItemOne}>
                            <Text style={[styles.reportItemHeading, {marginRight: 20}]}>e-Trees Planted</Text>
                            <Text style={[styles.mainText, {marginLeft: 20}]}>{(Math.floor(userData.trees_planted))}</Text>
                        </View>
                        <View style={styles.reportItem}>
                            <Text style={[styles.reportItemHeading, {marginRight: 20}]}>Est. CO2 Sequestered</Text>
                            <Text style={[styles.mainText, {marginLeft: 20}]}>{(userData.carbon_offset).toFixed(2)} kg per year</Text>
                        </View>
                        <View style={styles.reportItem}>
                            <Text style={[styles.reportItemHeading, {marginRight: 20}]}>Total Donated</Text>
                            <Text style={[styles.mainText, {marginLeft: 20}]}>£{((userData.total_donated)/100).toFixed(2)}</Text>
                        </View>
                        </View>        
                    </View>

          <Image  src={reportbottom} />
          </Page>


{/*           
          <Page  size="A4"  >
          <Image  style={styles.logoImg} src={vizopayLogo} />
          <Image  style={styles.backgroundImg} src={firstPagePhoto} />
          <View style={styles.titleSection} >
                    <Text style={styles.header}>Sustainability Report for {userData.company_name}</Text>
          </View>
           <View style={styles.footerSection}>
                    <Text style={styles.footerText}>Vizopay - Powering sustainability through payments.</Text>
           </View>
          <View>
          </View>
          </Page> */}


            {/* <Page size="A4" >
            <Image  style={styles.logoImg} src={vizopayLogo} />
              <View style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "stretch",
                width: "100%",
                height: "100%",
                flexDirection: "column"
              }}>
                <View style={styles.vizopayIntroductionSection}>
                    <Text style={[styles.mainText, {margin: "10px auto"}]}>{userData.company_name} partners with Vizopay, an authorised payment provider that acts as an intermediary facilitator between {userData.company_name} and the projects that that help combat climate change.</Text>
                    <Text style={[styles.mainText, {margin: "10px auto"}]}>The following report summarises {userData.company_name}'s contribution to our projects:</Text>
                </View>
                <View style={styles.chart} >
                  
                    <Image  style={styles.chart} src={Imgurl} />
                  
                </View>
                <View >{
                  //<Image  style={styles.detail} src={details} />
                  }
                
                </View>
               
                <View style={styles.mainSection}>
                    <View style={styles.reportContainer}>
                        <View style={styles.reportItem}>
                            <Text style={[styles.reportItemHeading, {marginRight: 20}]}>Trees Planted</Text>
                            <Text style={[styles.mainText, {marginLeft: 20}]}>{(Math.floor(userData.trees_planted))}</Text>
                        </View>
                        <View style={styles.reportItem}>
                            <Text style={[styles.reportItemHeading, {marginRight: 20}]}>Estimated CO₂ Sequestered</Text>
                            <Text style={[styles.mainText, {marginLeft: 20}]}>{(userData.carbon_offset).toFixed(2)} kg per year</Text>
                        </View>
                        </View>        
                    </View>
                <View style={styles.footerSection}>
                    <Text style={styles.footerText}>Vizopay - Powering sustainability through payments.</Text>
                </View>
                
                </View>
                </Page> */}
                
        </Document>
    )
}

export default SustainabilityPDF
