import React, { useState } from "react";
import { Row, Container, Col} from 'reactstrap';

import './reports.css';

interface IReportHero {
    firstName?: string
}

function ReportsHero() {
  return (
      <>
    <div >
        <Container fluid>
            
            <h1> Report </h1>

        </Container>
    </div>

    </>
  );
}

export default ReportsHero;