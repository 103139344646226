import React, {useState, useEffect,useContext} from 'react'
import './stores.css';
import {Form, FormGroup, Spinner, Row, Container, Col, Button,Input} from 'reactstrap';
import Slider from '@mui/material/Slider';
import AuthContext from '../../../context/AuthContext';
import TextField from '@mui/material/TextField';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import Fab from '@mui/material/Fab';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import ButtonGroup from "@mui/material/ButtonGroup";
import axios from 'axios';
import url from '../../../api/url';
import Cookies from 'js-cookie';
import HandleError from '../ErrorHandler/HandleError';
import {Link} from 'react-router-dom';
import {toast,ToastContainer} from 'react-toastify'
import "react-toastify/dist/ReactToastify.css"

import AutocompleteGoogleMaps from './AutocompleteGoogleMaps'

function StoreDetailLayout(props) {
    
    const percentageMark = [
        {
          value: 0.05,
          label: '5%',
        },
        {
          value: 0.1,
          label: '10%',
        },
        {
          value: 0.15,
          label: '15%',
        },
        {
          value: 0.2,
          label: '20%',
        },
        {
          value: 0.25,
          label: '25%',
        },
        {
          value: 0.3,
          label: '30%',
        },
        {
          value: 0.35,
          label: '35%',
        },
        {
          value: 0.4,
          label: '40%',
        },
        {
          value: 0.45,
          label: '45%',
        },
        {
          value: 0.5,
          label: '50%',
        }
      ];
      
      const pointMark = [
        {
          value: 0.05,
          label: '5%',
        },
        {
          value: 0.1,
          label: '10%',
        },
        {
          value: 0.15,
          label: '15%',
        },
        {
          value: 0.2,
          label: '20%',
        },
        {
          value: 0.25,
          label: '25%',
        },
        {
          value: 0.3,
          label: '30%',
        },
        {
          value: 0.35,
          label: '35%',
        },
        {
          value: 0.4,
          label: '40%',
        },
        {
          value: 0.45,
          label: '45%',
        },
        {
          value: 0.5,
          label: '50%',
        }
      ];

    
    const { match } = props;
    const [stripeUrl,setStripeUrl] = useState("");
    const [stripeVerified,setStripeVerified] = useState(true);
    const [store, setStore] = useState({});
    const [loading, setLoading] = useState(true);
    const { auth, setAuth } = useContext(AuthContext);
    const [storeName, setStoreName] = useState("");
    const [description, setDescription] = useState("");
    const [website, setWebsite] = useState("");
    const [address, setAddress] = useState("");
    const [bankID, setBankID] = useState("");
    const [accountName, setAccountName] = useState("");
    const [sortCode, setSortCode] = useState("");
    const [lastThree, setLastThree] = useState("")
    const [accountNumber, setAccountNumber] = useState("");
    const [latitude, setLatitude] = useState("");
    const [longitude, setLongitude] = useState("");
    const [storeCover, setStoreCover] = useState("");
    const [password, setPassword] = useState("");
    const [passwordUpdated,setPasswordUpdated] = useState(false);
const [placeId, setPlaceId] = useState(null);
  
    const [storeId,setStoreId] = useState("");
    const [stamp,setStamp]= useState("");
    const [points,setPoints] = useState("");
    const [percentageDiscount,setPercentageDiscount] = useState("");

    const [changeImage, setChangeImage] = useState(false);

    const [updated, setUpdated] = useState(false);

    const [settingsChanged, setSettingChanged] = useState(false);
    const [initialRender,setInitialRender] = useState(true)


    const takeToStripe = () => {
        window.location.href = stripeUrl;
      }

    useEffect(() => {
        if (initialRender) {
            setInitialRender(false)
        } else  {
            setSettingChanged(true)
        }
    },[storeName,description,website,placeId,accountName,sortCode,accountNumber])
    

    



    useEffect(() => {
        

      const getStorePassword = async() => {
          await axios.get(`${url}/business/store_password/${match.params.id}`, {
          headers: {
              'x-auth-token': Cookies.get("token")
          }
      }).then(( res ) => {

          setPassword(res.data.password)

          

      }).catch( ( err ) => {
        if(err.response.data.message === "jwt expired") {
            Cookies.remove('token');
            setAuth(false);
          }
          console.log(err.response, "error response");
      })

      }

      getStorePassword();

  }, [passwordUpdated])


    useEffect(() => {
        

        const getStore = async() => {
            await axios.get(`${url}/business/stores/${match.params.id}`, {
            headers: {
                'x-auth-token': Cookies.get("token")
            }
        }).then(( res ) => {

            console.log(res)
            setStore(res.data.store);
        
            setStoreName(res.data.store.store_name);
            setDescription(res.data.store.store_description)
            setWebsite(res.data.store.store_website);
            setAddress(res.data.store.store_address);
            setBankID(res.data.store.bank_account.bank_account_id);
            setAccountName(res.data.store.bank_account.account_name);
            setAccountNumber("*****" + res.data.store.bank_account.last_three);
            setSortCode(res.data.store.bank_account.sort_code);
            setStoreId(res.data.store.store_id);
            setStamp(res.data.store.rewards?.stamp);
            setPoints(res.data.store.rewards?.points);
            setPercentageDiscount(res.data.store.rewards?.percentage_discount);
            setStripeVerified(res.data.store.stripe_details_verified)
            setLoading(false);
            setUpdated(false);
            setSettingChanged(false)

        }).catch( ( err ) => {
            if(err.response.data.message === "jwt expired") {
                Cookies.remove('token');
                setAuth(false);
              }

            console.log(err.response, "error response");
        })

        }

        getStore();

    }, [updated])

    useEffect(() => {

        const getStripeUrl = async() => {
            await axios.get(`${url}/business/stripe_link/${match.params.id}`, {
            headers: {
                'x-auth-token': Cookies.get("token")
            }
        }).then(( res ) => {
            setStripeUrl(res.data.stripe_Link)
        }).catch( ( err ) => {
          console.log(err)
          if(err.response.data.message === "jwt expired") {
              Cookies.remove('token');
              setAuth(false);
            }
            console.log(err.response, "error response");
        })
  
        }
  
        getStripeUrl();
    },[stripeVerified])





    const displayCounter = stamp.number_of_stamps_required > 0;

    const handleIncrement = () => {
        setStamp(prevState => ({...prevState, number_of_stamps_required: prevState.number_of_stamps_required + 1 }));
      };
      
      const handleDecrement = () => {
        setStamp(prevState => ({...prevState, number_of_stamps_required: prevState.number_of_stamps_required -1 }));
      };

    

const newPassword = async() => {
    await axios.post(`${url}/business/stores/new-password/${storeId}`,{}, {
        headers: {
            'x-auth-token': Cookies.get("token")
        }
    }).then((res) => {
        setPasswordUpdated(true)
        toast.success("New Password Generated, Check Your Email", {
            position: toast.POSITION.TOP_CENTER
          });}
    ).catch( ( err ) => {
        console.log(err.response, "error response");
    })
}



const updateRewards = async() => {
    await axios.put(`${url}/business/stores/rewards/${storeId}`,
    {
        stamp:stamp,
        points:points,
        percentage_discount:percentageDiscount
    }
    , {
        headers: {
            'x-auth-token': Cookies.get("token")
        }
    }).then((res) => {
        toast.success("Rewards updated", {
            position: toast.POSITION.TOP_CENTER
          });}
    ).catch( ( err ) => {
        console.log(err.response, "error response");
    })
}


      const [storeDescriptionCL, setStoreDescriptionCL] = useState(0);

// Location

const [formattedAddress, setFormattedAddress] = useState("");

const [geolocation, setGeolocation] = useState({});


const [addressSelected, setAddressSelected] = useState(false)

const getAddress = async() => {

    setAddressSelected(false);

    await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=AIzaSyDoBRoBijY8_R2aWW6dAk4tFbklqLwYkoo`)
        .then(res => {


            setFormattedAddress(res.data.results[0].formatted_address)
            setLatitude(res.data.results[0].geometry.location.lat);
            setLongitude(res.data.results[0].geometry.location.lng);

        })
        .catch(err => {console.log(err)
        setFormattedAddress("")
        });

} 


//   count store characters

useEffect(() => {

    setStoreDescriptionCL(description.length)

}, [description]);

// update store details

var bodyFormData = new FormData();


    const saveStore = async() => {

      setLoading(true);



        bodyFormData.append('account_name', accountName)
        bodyFormData.append('account_number', accountNumber)
        bodyFormData.append('sort_code', sortCode)

      
        bodyFormData.append('place_id', placeId)
        bodyFormData.append('store_name', storeName)
        bodyFormData.append('store_website', website)
        bodyFormData.append('store_description', description)
        // bodyFormData.append('latitude', latitude)
        // bodyFormData.append('longitude', longitude)
        bodyFormData.append('storeCover', storeCover)
        bodyFormData.append('store_address', address)



        await axios({

                method: "put",
                url: `${url}/business/stores/${match.params.id}`,
                data: bodyFormData,
                headers: { "Content-Type": "multipart/form-data", "x-auth-token": Cookies.get("token") },

                })
                .then(res => {
                    setUpdated(true)
                    setChangeImage(false)
                    if(res.status >= 200 < 300){
                        toast.success("Store successfully updated!", {
                          position: toast.POSITION.TOP_CENTER
                        });}
                })
                .catch(err => {
                    setLoading(false);
                    setLoading(false);
                    if (err.response.status == 401){
                      Cookies.remove('token')
                      window.location.href = "/"
                    }
                    HandleError(err.response.status,err.response.data.message)
                })
    }



    return (
        
        
        <div className="store-detail-container">
            <ToastContainer/>
        {
            loading ?

            <div className="loading-div">

                    <Spinner style={{color: '#65ccb8'}} size="md"/>

            </div>
                :

            <>

            <div style={{alignItems: 'flex-start', height: '100%', width: '100%'}} className="outter">


            {/* BANNER */}
                <Row>
                    <Link to="/stores">
                        <Fab aria-label="back" style={{boxShadow: "0px 0px 0px", elevation: 0}}>
                            <KeyboardBackspaceIcon />
                        </Fab>
                    </Link>
                </Row>
                
                <Row>
                    <div style={{display: "flex", position: 'relative', justifyContent: 'center', alignItems: 'center', width: '100%', margin: "20px auto"}}>
                    {
                        changeImage ?

                        <>
                        <Button color="danger" onClick={(e) => {setChangeImage(false)}} style={{position: 'absolute', top: 5, left: 5, zIndex: 3}}>
                            Cancel
                        </Button>
                        {/* File Attachment Input */}
      <label className="custom-file-boxed" htmlFor="customFileInputBoxedEg">
        {
          storeCover ?
          
                    <span id="customFileBoxedEg">{storeCover.name}</span>

          :
          <>
          <span id="customFileBoxedEg">Browse your device and upload documents</span>
        <small className="d-block text-muted">Maximum file size 10MB</small>
        </>
        }
        <input onChange={(e) => {
          setStoreCover(e.target.files[0])
        }} id="customFileInputBoxedEg" name="custom-file-boxed" type="file" className="js-file-attach custom-file-boxed-input" />
      </label>
      {/* End File Attachment Input */}
                        </>
                        :

                        <>
                        <Button onClick={(e) => {setChangeImage(true)}} style={{position: 'absolute', top: 5, left: 5}}>
                            Change
                        </Button>
                        <img style={{height: "200px", width: "100%", objectFit: 'cover', borderRadius: 10, alignSelf: 'center', boxShadow: "5px 5px 15px 5px rgba(34,40,49,0.16)"}} className="card-img-top" src={`${url}/${store.image}`} alt="Card image cap" />  
                        </>

                    }
                    </div>
                </Row>
                <Row>
                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%'}}>
                        <small>Above is a representation of how your cover photo will look on the app. We ensure premium quality before your store goes live.</small>
                    </div>
                </Row>
                <hr />
                <Row style={{margin: "20px auto"}}>
                    <h2>Information</h2>
                </Row>
                {
                    stripeVerified?
                    
                  <div>  
                <Row>
                    <div style={{margin: "20px 15px"}}>
                        
                    <p style={{color: 'red'}}>Please note that your store will temporarily be inactive while we check your new details.</p>
                
                    </div>
                </Row>
                <Row style={{margin: "20px auto", width: '100%'}}>
                    <Form style={{width: '100%'}}>
                        <FormGroup>
                        <TextField disabled value={storeId} className="form-input" style={{width: '100%'}} id="outlined-size-small" label="Store Id" variant="outlined" size="small" placeholder="Bank Account ID" size="medium"/>
                        </FormGroup>
                        <FormGroup>
                        <TextField disabled value={storeName} className="form-input" style={{width: '100%'}} id="outlined-size-small" label="Store Name" variant="outlined" size="small" placeholder="Bank Account ID" size="medium"/>
                        </FormGroup>
                        <FormGroup>
                        <TextField disabled value={password} className="form-input" style={{width: '100%'}} id="outlined-size-small" label="Store Password" variant="outlined" size="small" placeholder="Bank Account ID" size="medium"/>
                        </FormGroup>
                        <FormGroup>
                             <TextField value={description} onChange={(e) => {
                      setDescription(e.target.value)
                    }}  className="form-input" style={{width: '100%'}} id="outlined-multiline-static" rows={4} label="Description" variant="outlined" size="small" multiline placeholder="Add your store description so that people understand more about your store." size="medium"/>
                    {!description && <span className='error'>Store Description is Required</span>}
                    <Row>
                        <div className="container-character-count">
                            <small>{storeDescriptionCL}/160</small>
                        </div>
                    </Row>
                    
                        </FormGroup>
                         <FormGroup>
                            <TextField value={website} onChange={(e) => {setWebsite(e.target.value)}} className="form-input" style={{width: '100%'}} id="outlined-size-small" label="Website" variant="outlined" size="small" placeholder="Website url" size="medium"/>
                        </FormGroup>
                        <Row style={{margin: "40px auto"}}>
                    <h2>Store Address</h2>
                </Row>
               
                                <FormGroup>

                                  <AutocompleteGoogleMaps placeholder={address} placeId={placeId} setPlaceId={setPlaceId}/>
                    
                    {/* <TextField value={address} onChange={(e) => setAddress(e.target.value)} className="form-input" style={{width: '100%'}} id="outlined-size-small" label="Enter your Postcode or Address" variant="outlined" size="small" placeholder="E.g. Coffee Co, 71-75 Shelton Street, London" size="medium"/>
                    {!address && <span className='error'>Store Address is required</span>}
                    <Row>
                        <div className="address-button">
                            <Button onClick={getAddress}>Search Address</Button>
                        </div>
                    </Row> */}
                    <Row>
                        <div className="formatted-address-container">
                            {
                                formattedAddress ?

                                <div>
                                    <List component="nav" aria-label="main mailbox folders">
        <ListItem  onClick={() => setAddressSelected(!addressSelected)} button>
          {
              addressSelected ?

            <>
            <ListItemIcon >
           

                    <CheckBoxIcon style={{color: '#65ccb8'}} />

          </ListItemIcon>
          <ListItemText primary={formattedAddress} />
            </>


              :

            <>
            <ListItemIcon >
        
                <CheckBoxOutlineBlankIcon />
                
          </ListItemIcon>
          <ListItemText primary={formattedAddress} />
            </>
              
          }

        </ListItem>
      </List>
                                </div>
                                :

                                null

                            }
                        </div>
                    </Row>
                </FormGroup>
                <Row style={{margin: "40px auto"}}>
                    <h2>Change Store Password</h2>
                </Row>
                
                            <Button onClick={newPassword}>Generate New Store Password</Button>

                <div style={{margin: "20px 0px"}}>
                        
                    <p style={{color: '#506D84'}}>After Clicking the button you will recieve an email with your new store password</p>
                
                    </div>
                    <Row style={{margin: "40px auto"}}>
                    <h2>Reward Details</h2>
                    </Row>
                    <FormGroup>
                    <Row>
                                <Col>
                                    Active Rewards:
                                </Col>
                                
                                
                            </Row>
                  <Row>
                          <Col>
                              <label class="container">
                          <Input style={{width: '100%'}} id="outlined-basic" label="Password" variant="outlined" checked={stamp.active} value={stamp.active} onChange = {(e) => setStamp(prevState => {return {...prevState,active :!stamp.active}})} type="checkbox" />
                          <span style={{border: '1px solid #65ccb8'}} class="checkmark"></span>
                          <span style={{ marginLeft: '10px' }}>Stamp based</span>
                      </label>
                          </Col>
                          <Col>
                              <label class="container">
                          <Input style={{width: '100%'}} id="outlined-basic" label="Password" variant="outlined" checked={points.active} value={points.active} onChange= {(e) => setPoints(prevState => {return {...prevState,active:!points.active}})} type="checkbox" />
                          <span style={{border: '1px solid #65ccb8'}} class="checkmark"></span>
                          <span style={{ marginLeft: '10px' }}>Points based</span>
                      </label>
                          </Col>
                          <Col>
                              <label class="container">
                          <Input style={{width: '100%'}} id="outlined-basic" label="Password" variant="outlined" checked={percentageDiscount.active} value={percentageDiscount.active} onChange= {(e) => setPercentageDiscount(prevState => {return {...prevState,active:!percentageDiscount.active}})} type="checkbox" />
                          <span style={{border: '1px solid #65ccb8'}} class="checkmark"></span>
                          <span style={{ marginLeft: '10px' }}>Discount based</span>
                      </label>
                          </Col>
                      </Row>
                  </FormGroup>
                
                
                         {
                    stamp.active &&
                   
                     <FormGroup>
                       <FormGroup>
                      <h4>Stamp Based</h4>
                  </FormGroup>
                        <div style={{marginTop: 20, marginBottom: 20}}>
                        <ButtonGroup size="small" aria-label="small outlined button group">
                      {displayCounter && <Button onClick={handleDecrement}>-</Button>}
                      {displayCounter && <Button disabled>{stamp.number_of_stamps_required}</Button>}
                      <Button onClick={handleIncrement}>+</Button>
                        </ButtonGroup>
                        <span style={{ marginLeft: '20px' }}>Number of stamps required to get reward</span>
                        </div>
                        <FormGroup>
                      <TextField  onChange={(e) => {
                        setStamp(prevState => ({...prevState,reward_description:e.target.value}))
                      }}  className="form-input" style={{width: '100%'}} id="outlined-multiline-static" rows={4} label="Reward Description" variant="outlined" size="small" multiline placeholder="Add your reward description so that people understand how to get the reward." size="medium"/>
                      <Row>
                          <div className="container-character-count">
                              <small>{storeDescriptionCL}/160</small>
                          </div>
                      </Row>
                              {(stamp.reward_description.length > 4 && stamp.reward_description.length < 20) && <span className='error'>Reward description should atleast be 20 characters long</span>}
  
                      <FormGroup>
                      
                      <TextField onChange={(e) => setStamp(prevState => ({...prevState,reward_type:e.target.value}))} className="form-input" style={{width: '100%'}} id="outlined-size-small" label="Reward Type" variant="outlined" size="small" placeholder="Free Coffee" size="medium"/>
          
                      </FormGroup>
                  </FormGroup>
                     </FormGroup>
                  }
  
                   {
                    points.active &&
                   
                     <FormGroup>
                       <FormGroup>
                       <h4>Points Based</h4>
                       <small>Pick percentage of cashback you would like to offer your customers on every purchase</small>
                       </FormGroup>
                       <Slider style={{color:"#65ccb8"}}
                  onChange={(e, newValue) => setPoints(prevState => ({...prevState,number_of_points:newValue}))}
                  defaultValue={`${points.number_of_points}`}
                  aria-labelledby="range-slider"
                  step={0.05}
                  min={0.05}
                  max={0.5}
                  marks={pointMark} />
                     </FormGroup>
                    
                  }
                  {
                    percentageDiscount.active &&
                     <FormGroup>
                       <FormGroup>
                       <h4>Discount Based</h4>
                       </FormGroup>
                       <Slider style={{color:"#65ccb8"}}
                  onChange={(e, newValue) => setPercentageDiscount(prevState => ({...prevState,percentage_discount:newValue}))}
                  defaultValue={`${percentageDiscount.percentage_discount}`}
                  aria-labelledby="range-slider"
                  step={0.05}
                  min={0.05}
                  max={0.5}
                  marks={percentageMark} />
                      <FormGroup>
                      <small>Specify the discount code if you would like to have one</small>
                        </FormGroup>
                        <FormGroup style={{marginTop: 20, marginBottom: 20}}>
                      <TextField onChange={(e) => setPercentageDiscount(prevState => ({...prevState,discount_code:e.target.value}))} className="form-input" style={{width: '100%'}} id="outlined-size-small" label="Discount Code" variant="outlined" size="small" placeholder="VIZOPAY10" size="medium"/>
                      </FormGroup>
                      <FormGroup>
                      <small>If you would like only specific users to get the discounts, please select below </small>
                        </FormGroup>
                      <FormGroup>
                      <Row>
                      
                          <Col>
                              <label class="container">
                          <Input style={{width: '100%'}} id="outlined-basic" label="Password" variant="outlined" checked={percentageDiscount.conditions.new_user_only} value={percentageDiscount.conditions.new_user_only} onChange = {(e) => setPercentageDiscount(prevState => {return {...prevState,conditions:{...prevState.conditions,new_user_only:!percentageDiscount.conditions.new_user_only}}})} type="checkbox" />
                          <span style={{border: '1px solid #65ccb8'}} class="checkmark"></span>
                          <span style={{ marginLeft: '10px' }}>New users </span>
                      </label>
                          </Col>
                          <Col>
                              <label class="container">
                          <Input style={{width: '100%'}} id="outlined-basic" label="Password" variant="outlined" checked={percentageDiscount.conditions.on_terminal_only} value={percentageDiscount.conditions.on_terminal_only} onChange = {(e) => setPercentageDiscount(prevState => {return {...prevState,conditions:{...prevState.conditions,on_terminal_only:!percentageDiscount.conditions.on_terminal_only}}})} type="checkbox" />
                          <span style={{border: '1px solid #65ccb8'}} class="checkmark"></span>
                          <span style={{ marginLeft: '10px' }}>On terminal </span>
                      </label>
                          </Col>
                          <Col>
                              <label class="container">
                          <Input style={{width: '100%'}} id="outlined-basic" label="Password" variant="outlined" checked={percentageDiscount.conditions.student_discount_only} value={percentageDiscount.conditions.student_discount_only} onChange = {(e) => setPercentageDiscount(prevState => {return {...prevState,conditions:{...prevState.conditions,student_discount_only:!percentageDiscount.conditions.student_discount_only}}})} type="checkbox" />
                          <span style={{border: '1px solid #65ccb8'}} class="checkmark"></span>
                          <span style={{ marginLeft: '10px' }}>Student Only</span>
                      </label>
                          </Col>
                      </Row>
                      </FormGroup>
                     </FormGroup>
                  }       
                    <Button onClick={updateRewards}>Update Rewards</Button>

                    <Row style={{margin: "40px auto"}}>
                    <h2>Account Details</h2>
                </Row>
                
                         <FormGroup>
                            <Row>
                                <Col>
                                    <TextField disabled value={bankID} className="form-input" style={{width: '100%'}} id="outlined-size-small" label="Bank Account ID" variant="outlined" size="small" placeholder="Bank Account ID" size="medium"/>
                                </Col>
                                <Col>
                                    <TextField value={accountName} onChange={(e) => {setAccountName(e.target.value)}} className="form-input" style={{width: '100%'}} id="outlined-size-small" label="Account Name" variant="outlined" size="small" placeholder="Account Name" size="medium"/>
                                    {!accountName && <span className='error'>Account Name is Required</span>}
                                </Col>
                                
                            </Row>
        
                        </FormGroup>
                        <FormGroup>
                            <Row>
                                <Col>
                                    <TextField value={accountNumber} onChange={(e) => setAccountNumber(e.target.value)} className="form-input" style={{width: '100%'}} id="outlined-size-small" label="Account Number" variant="outlined" size="small" placeholder="Account Number" size="medium"/>
                                    {(accountNumber.length !== 8 ) && <span className='error'>Account Number Needs to be 8 Characters Long</span>}

                                </Col>
                                <Col>
                                    <TextField value={sortCode} onChange={(e) => setSortCode(e.target.value)} className="form-input" style={{width: '100%'}} id="outlined-size-small" label="Sort Code" variant="outlined" size="small" placeholder="Sort Code" size="medium"/>
                                    {(sortCode.length !== 6 ) && <span className='error'>Sort Code Needs to be 6 Characters Long</span>}
                                </Col>
                            </Row>
                
                        </FormGroup>
                        <FormGroup>
                            <Row>
                                <div style={{padding: '30px 15px', display: 'flex', justifyContent: 'flex-end', width: '100%'}}>
                                    <Button disabled = {!settingsChanged} style={{width: '300px'}} onClick={saveStore}>Save Changes</Button>
                                </div>
                            </Row>

                        </FormGroup>
                    </Form>
                </Row>
                      </div>  
                      :
                      <>
                      <div style={{marginBottom: "15px"}}>
                        
                    <p style={{color: 'red'}}>To view or edit store details please complete your onboarding with our partner stripe</p>
                
                    </div>
                      <Button onClick={takeToStripe}>Take me to Stripe</Button>
                    </>
                }    

            

            {/* STORE HEADER */}

            {/* TABS - SETTINGS | BANK DETAILS \ LOCATION*/}
            </div>
            </>
        }
        </div>
            
    )
}

export default StoreDetailLayout
