import React, { useState, useEffect, useContext } from 'react'
import { Form, FormGroup, Label, InputGroupAddon, InputGroupText, Input, Row, Col } from 'reactstrap';
import { Button, Loader } from 'semantic-ui-react'
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import './authentication.css'
import { Spinner } from 'reactstrap'
import axios from 'axios';
import url from '../../api/url';

import AuthContext from '../../context/AuthContext';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import TextField from '@mui/material/TextField';


function getSteps() {
    return ['Step 1', 'Step 2'];
}

function Forgot(props) {
    const history = useHistory();

    const { auth, setAuth } = useContext(AuthContext);

    const [email, setEmail] = useState('');

    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const [spinner, setSpinner] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);

    const [activeStep, setActiveStep] = React.useState(props.match.params.code ? 1 : 0);

    const [code, setCode] = useState(props.match.params.code ? props.match.params.code : '');

    const [seconds, setSeconds] = React.useState(10);

    const steps = getSteps();

    async function sendCode() {
        setSpinner(true);
        await axios.post(`${url}/business/reset/email`,
            {
                email: email,
            }
        ).then(response => {
            setSpinner(false);
            toast.dark("Password reset information sent to your email address.");
        }).catch(error => {
            setSpinner(false);
            toast.dark(error.response.data.message);
            setErrorMessage(true);
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        sendCode();
    }

    const handleSubmit2 = async (e) => {
        e.preventDefault();

        setSpinner(true);

        await axios.post(`${url}/business/reset/password`,
            {
                code: code,
                password: password,
                confirmPassword: confirmPassword
            }
        ).then(response => {
            setSpinner(false);
            toast.dark("Password Changed");
            history.push("/login");
        }).catch(error => {
            setSpinner(false);
            toast.dark(error.response.data.message);
            setErrorMessage(true);
        })
    }

    function getStepContent(stepIndex) {
        switch (stepIndex) {
            case 0:
                return (
                    <Form onSubmit={handleSubmit}>
                        <FormGroup>
                            
                            <TextField style={{width: '100%'}} id="outlined-basic" label="Email" variant="outlined" onChange={(e) => setEmail(e.target.value)} size="lg" className="form-input" type="email" placeholder="jenny.doe@company.com" />
                        </FormGroup>

                        <Button size="large"
                            disabled={!email || spinner}
                            className="green-button" type='submit'>

                            {
                                spinner ?
                                    "Please wait.."
                                    :
                                    "Submit"
                            }
                        </Button>
                        <br />
                        <br />
                        <Link to="/login" className="auth-link">Go back to login screen</Link>
                    </Form>
                );
            case 1:
                return (
                    <Form onSubmit={handleSubmit2}>
                        {/*<FormGroup>
                            <Label>Email*</Label>
                            <Input readOnly onChange={(e) => setEmail(e.target.value)} size="lg" className="form-input" type="email" placeholder="jenny.doe@company.com" />
                            <a className="auth-link" onClick={() => setActiveStep(0)}>Edit</a>
                        </FormGroup>*/}

                        <FormGroup>
                            <TextField style={{width: '100%'}} id="outlined-basic" label="Enter Verification Code" variant="outlined" value={code} onChange={(e) => setCode(e.target.value)} size="lg" className="form-input" placeholder="Code" />
                        </FormGroup>

                        <FormGroup>
                            
                            <TextField style={{width: '100%'}} id="outlined-basic" label="New Password" variant="outlined" onChange={(e) => setPassword(e.target.value)} size="lg" className="form-input" type="password" placeholder="Password" />
                        </FormGroup>
                        <FormGroup>
                            
                            <TextField style={{width: '100%'}} id="outlined-basic" label="Confirm New Password" variant="outlined" onChange={(e) => setConfirmPassword(e.target.value)} size="lg" className="form-input" type="password" placeholder="Confirm Password" />
                        </FormGroup>

                        <Button size="large"
                            disabled={spinner}
                            className="green-button" type='submit'>
                            {
                                spinner ?
                                    "Please wait.."
                                    :
                                    "Submit"
                            }
                        </Button>

                        {/* <br />
                        <br />
                        <Button className="auth-link" disabled={spinner} size="small" onClick={() => sendCode()}>Resend OTP</Button> */}
                    </Form>
                );
            default:
                return 'Unknown stepIndex';
        }
    }

    return (
        <div className="login-container card div--center">
            <ToastContainer
                hideProgressBar
                autoClose={2000}
                position="top-center"
            />
            <div className="header">
                <h1>Reset Password</h1>
                <Stepper style={{ backgroundColor: 'transparent' }} activeStep={activeStep} alternativeLabel>
                    {steps.map((label) => (
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
            </div>
            <div>{getStepContent(activeStep)}</div>
        </div >
    )
}

export default Forgot