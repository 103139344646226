import React from 'react'
import AddItem from './AddItem'
import InvoiceBillTo from './InvoiceBillTo'
import InvoiceHeader from './InvoiceHeader'
import InvoiceSidebar from './InvoiceSidebar'
import InvoiceTotal from './InvoiceTotal'

function InvoiceMain() {
    return (
        <main id="content" role="main" className="main">
        {/* Content */}
        <div className="content">
          <div className="row">
            <div className="col-lg-9 mb-5 mb-lg-0">
              {/* Card */}
              <div className="card card-lg">
                {/* Body */}
                <div className="card-body">
                  <InvoiceHeader />
                  <hr className="my-5" />
                <InvoiceBillTo />
                <AddItem />
                  <hr className="my-5" />
                <InvoiceTotal />
                  {/* End Row */}
                  {/* Form Group */}
                  <div className="form-group">
                    <label htmlFor="invoiceNotesLabel" className="input-label">Notes &amp; terms</label>
                    <textarea className="form-control" placeholder="Who is this invoice to?" id="invoiceNotesLabel" aria-label="Who is this invoice to?" rows={3} defaultValue={""} />
                  </div>
                  {/* End Form Group */}
                  <p className="font-size-sm mb-0">© Vizopay. {new Date().getFullYear()}.</p>
                </div>
                {/* End Body */}
              </div>
              {/* End Card */}
              {/* Sticky Block End Point */}
              <div id="stickyBlockEndPoint" />
            </div>
            <InvoiceSidebar />
          </div>
          {/* End Row */}
        </div>
        {/* End Content */}
        {/* Footer */}
        {/* End Footer */}
      </main>
    )
}

export default InvoiceMain
