import React, { useContext,useState,useEffect } from 'react'
import { Row, Col, Form, FormGroup, Input, Label, Button } from 'reactstrap';
import axios from 'axios';
import "./profileSettings.css"
import url from '../../../api/url.js';
import Cookies from "js-cookie"
import AuthContext from '../../../context/AuthContext';
import UserContext from '../../../context/UserContext';

function ProfileSettings() {
    const { userData } = useContext(UserContext);
    const[phoneWithoutZero, setPhoneWithoutZero] = useState(userData.phone);
    const[values,setValues] = useState({
        first_name: userData.first_name,
        last_name:userData.last_name,
        email:userData.email,
        phone:phoneWithoutZero,
        company_name:userData.company_name,
        website_url:userData.website_url
    });
    const[phonePlaceHolder, setPhonePlaceHolder] = useState("0" + userData.phone);
    const { auth, setAuth } = useContext(AuthContext);
    const[submitted,setSubmitted] = useState(false);
    const[validPhone,setValidPhone] = useState(true);
    const[validEmail,setValidEmail] = useState(true);

    
    useEffect(() => {
        const emailRegex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i

        if(emailRegex.test(values.email)) {
            setValidEmail(true)
        } else {
            setValidEmail(false)
        }

    }, [values.email])


    useEffect(() => {
        const phoneRegex = /^(?:(?:\(?(?:0(?:0|11)\)?[\s-]?\(?|\+)44\)?[\s-]?(?:\(?0\)?[\s-]?)?)|(?:\(?0))(?:(?:\d{5}\)?[\s-]?\d{4,5})|(?:\d{4}\)?[\s-]?(?:\d{5}|\d{3}[\s-]?\d{3}))|(?:\d{3}\)?[\s-]?\d{3}[\s-]?\d{3,4})|(?:\d{2}\)?[\s-]?\d{4}[\s-]?\d{4}))(?:[\s-]?(?:x|ext\.?|\#)\d{3,4})?$/

        if(phoneRegex.test(phonePlaceHolder)) {
            setValidPhone(true)
            setPhoneWithoutZero(phonePlaceHolder.substring(1,))
            setValues((values) => ({...values,phone:phoneWithoutZero}))
        } else {
            setValidPhone(false)
        }

    }, [phonePlaceHolder,phoneWithoutZero])

      
    const handleSubmit = async (event) => {
    event.preventDefault();
    if(values.first_name && values.last_name && values.email && values.phone && values.company_name && values.website_url) {
        await axios.put(`${url}/business/settings/general`,values, {
            headers: {
                "x-auth-token": Cookies.get("token")
            }
        })
        .then(res => {
            if(res.status == 204) {
                setSubmitted(true);
                setTimeout(() => {
                    window.location.reload();
                  }, 1000);
            }
        })
        .catch((e) => 
        {
            if(e.response.data.message === "jwt expired") {
                Cookies.remove('token');
                setAuth(false);
              }
              console.log(e)
        }
        ) 
    }
    }


    return (
        <Form onSubmit={handleSubmit}>
            <Col>
                <FormGroup>
                    <Row>
                        <Col>
                            <Label>First Name</Label>
                            <Input value={values.first_name} onChange={(e) => setValues((values) => ({...values,first_name:e.target.value}))} placeholder={userData.first_name} />
                            {!values.first_name && <span className='error'>Please Enter a First Name</span>}

                        </Col>
                        <Col>
                            <Label>Last Name</Label>
                            <Input value={values.last_name} onChange={(e) => setValues((values) => ({...values,last_name:e.target.value}))} placeholder={userData.last_name} />
                            {!values.last_name && <span className='error'>Please Enter a Last Name</span>}
                        </Col>
                    </Row>
                </FormGroup>
                <FormGroup>
                    <Row>
                        <Col>
                            <Label>Email</Label>
                            <Input value={values.email} onChange={(e) => setValues((values) => ({...values,email:e.target.value}))} type="email" placeholder={userData.email} />
                            { !values.email || !validEmail && <span className='error'>Please Enter a Valid Email</span>}
                        </Col>
                        <Col>
                            <Label>Phone</Label>
                            <Input value={phonePlaceHolder} onChange={(e) => setPhonePlaceHolder(e.target.value)} type="tel" placeholder={userData.phone} />
                            { !phonePlaceHolder || !validPhone  && <span className='error'>Please Enter a Valid Phone Number</span>}
                        </Col>
                    </Row>
                </FormGroup>
                <FormGroup>
                    <Label>Company Name</Label>
                    <Input value={values.company_name} onChange={(e) => setValues((values) => ({...values,company_name:e.target.value}))} placeholder={userData.company_name} />
                    { !values.company_name && <span className='error'>Please Enter a Company Name</span>}
                </FormGroup>
                <FormGroup>
                    <Label>Website URL</Label>
                    <Input value={values.website_url} onChange={(e) => setValues((values) => ({...values,website_url:e.target.value}))} placeholder={userData.website_url} />
                    { !values.website_url && <span className='error'>Please Enter a Company Url</span>}
                </FormGroup>
                <FormGroup>
                    <Button  >Update</Button>
                    {submitted && <div className='success-message'>Profile Updated Successfully!!</div>}
                </FormGroup>
            </Col>
            <Col>
            </Col>
        </Form>
    )
}

export default ProfileSettings