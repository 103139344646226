import React from 'react'
import {Container, Row, Col, Card, CardImg, CardBody, CardTitle, CardSubtitle, Button, CardText} from 'reactstrap';
import paymentsLogo from './images/payments.svg'
import environmentalLogo from './images/environment.svg'
import dataLogo from './images/data.svg'

import './products.css'

function Products() {

    const productArray = [
        {
            image: paymentsLogo,
            imageCap: 'image caption',
            title: 'Payments',
            subtitle: 'Invest in Technology, Traditional & Verified carbon reduction projects with every payment.',
            text: 'Use our Plugins or APIs to add environmental sustainability to your products at checkout. You and your customers can track your environmental impact with every transaction.',
            button: 'Explore'
        },
        {
            image: environmentalLogo,
            imageCap: 'image caption',
            title: 'Direct',
            subtitle: 'Select which projects you want to fund with direct funding. Included with the Payments product.',
            text: 'Use alongside our Payments product at no additional cost or just directly fund carbon capture projects. You can also select a percentage of funding to each type of action.',
            button: 'Coming Soon'
        },
        {
            image: dataLogo,
            imageCap: 'image caption',
            title: 'Data',
            subtitle: 'Leverage our powerful Data API to incorporate into your business.',
            text: 'The Data API is coming soon.',
            button: 'Coming Soon'
        }
    ]

    return (
        <Container fluid>
            <Row>
                {productArray.map(products => {

                    return(
                        <Col lg="6">
                    <Card className="shadow-lg p-3 mb-5 bg-white rounded product-card">
<div className="img-wrapper">
                        <CardImg top className="product-image" src={products.image} alt={products.imageCap} />

</div>
        <CardBody>
          <CardTitle tag="h5">{products.title}</CardTitle>
          <CardSubtitle tag="h6" className="mb-2 text-muted">{products.subtitle}</CardSubtitle>
          <CardText>{products.text}</CardText>
          {
              products.button === "Explore" ?

              <Button style={{backgroundColor: '#65ccb8'}}>{products.button}</Button>

              :

              <Button>{products.button}</Button>

          }
        </CardBody>
      </Card>
                </Col>
                    )
                })}
            </Row>
        </Container>
    )
}

export default Products
