import React, { useState } from 'react'
import { Form, FormGroup, InputGroupAddon, InputGroupText, Input, Row, Col } from 'reactstrap';

import TextField from '@mui/material/TextField';


import MenuItem from '@mui/material/MenuItem';
import { Autocomplete, Checkbox, FormControl, FormControlLabel, Grid, Select } from '@mui/material';
import { Stack } from '@mui/system';

const BusinessTypes = [
    {
        value: "Single"
    },
    {
        value: "Chain"
    },
    {
        value: "Franchise"
    }
]



const industries = [
"Aerospace and aviation",
"Agriculture",
"Appliances",
"Automotive",
"Banking and financial services",
"Biometrics",
"CSR and governance",
"Construction and building",
"Customer service",
"Data protection",
"Electrical and electronic",
"Energy and utilities",
"Engineering",
"Environmental management and sustainability",
"Facilities management",
"Fire",
"Food and drink",
"Graphic Technology",
"Healthcare",
"Health and safety",
"ICT and telecoms",
"Import export",
"Information management",
"Innovation and design",
"Internet of Things",
"Lighting",
"Manufacturing and processing",
"Measurement and metrology",
"Medical devices",
"Mining and materials",
"Nanotechnology",
"Pressure vessels",
"Quality and business improvement",
"Risk",
"Security",
"Transport and logistics",
"Waste and recycling",
"Welding",
"Other"
]



function CompanyForm({ companyName, companyWebsite, industry, setCompanyName, setCompanyWebsite, setIndustry, setAuthRep, authRep, setBusinessType, businessType,acceptPaymentsOnline,acceptPaymentsInStore,setAcceptPaymentsInStore,setAcceptPaymentsOnline }) {

    const [businessTypeSelect, setBusinessTypeSelect] = React.useState("Single");
    
    return (
        <div>
            <Form>
                <FormGroup>
                    <TextField style={{width: '100%'}} id="outlined-basic" label="Company Name" variant="outlined" value={companyName} onChange={(e) => setCompanyName(e.target.value)} size="lg" className="form-input" type="text" placeholder="Company Name" />
                </FormGroup>
                <FormGroup>
                    <TextField style={{width: '100%'}} id="outlined-basic" label="Company Website" variant="outlined" value={companyWebsite} onChange={(e) => setCompanyWebsite(e.target.value)} size="lg" className="form-input" type="text" placeholder="Company website" />
                </FormGroup>
                <FormGroup>
      
          <TextField value={businessType} style={{width: '100%'}} id="outlined-basic" label="Business Type" variant="outlined" select onChange={(e) => setBusinessType(e.target.value)} size="lg" className="form-input" type="select" placeholder="Business Type" name="select" id="exampleSelect">
            {
                BusinessTypes.map((option) => {
                    return(
                        <MenuItem key={option.value} value={option.value}>
                        {option.value}
                    </MenuItem>
                    )
                })
            }
          </TextField>
                </FormGroup>
                <FormGroup>
                    {/* <Row>
                        <label>What type of payments does your store accept?</label>
                    </Row> */}
                    <Grid container spacing={2} >
                        <Grid item xs={6}>
                <FormControlLabel control={
                <Checkbox checked={acceptPaymentsInStore} onChange={() => setAcceptPaymentsInStore(!acceptPaymentsInStore)}/>} 
                label="In person payments" 
                />

                        
                 
                        </Grid>
                        <Grid item xs={6}>
                        <FormControlLabel control={
                <Checkbox onChange={() => setAcceptPaymentsOnline(!acceptPaymentsOnline)}/>} 
                label="Online payments" 
                />
                        
                        </Grid>
                    </Grid>

                </FormGroup>
            
                <FormGroup>
                
                <Autocomplete
      disablePortal
      id="combo-box-demo"
      options={industries}
      sx={{ width: "100%" }}
      renderInput={(params) => <TextField {...params} label="Industries" />}
      onChange={(event, newValue) => {
        setIndustry(newValue);
      }}
      value={industry}
    />
                </FormGroup>
                <FormControl>
             
                <FormControlLabel control={<Checkbox checked={authRep} onChange={() => setAuthRep(!authRep)}/>} label="I confirm I am an authorised representative of this company." />
                

                        {/* <span style={{ marginLeft: '20px' }}>I confirm I am an authorised representative of this company.</span> */}
      
                </FormControl>
                <FormGroup>
                    <span style={{color: '#2F5D62'}}>An authorised representative is a company director OR 25%+ shareholder.</span>
                </FormGroup>
            </Form>
        </div>
    )
}

export default CompanyForm