import React, { useState } from 'react'
import {
    Switch,
    Route,
    Redirect,
} from 'react-router-dom';
import InvoiceLayout from '../../invoice/InvoiceLayout';
import Home from '../home/Home';
import Products from '../products/Products';
import Settings from '../settings/Settings';
import PaymentDetail from '../transactions/PaymentDetail';
import Transactions from '../transactions/Transactions';

import { ToastContainer } from 'react-toastify'
import Customers from '../customers/Customers';
import Search from '../search/Search';
import StoresLayout from '../stores/StoresLayout';
import AddStore from '../stores/AddStore';
import StoreDetailLayout from '../stores/StoreDetailLayout';
import Campaigns from '../campaigns/CampaignLayout';
import Reports from '../reports/ReportsLayout.tsx';

function DashboardRoutes() {

    const [paymentId, setPaymentId] = useState(null);

    return (
        <>
            <Route exact path="/" component={Home} />
            <Route exact path="/payments">
                <Transactions paymentId={paymentId} setPaymentId={setPaymentId} />
            </Route>
            <Route exact path='/transactions/:id' render={(props) => {
                return (<PaymentDetail {...props} />)
            }} />
            <Route path="/invoice" component={InvoiceLayout} />
            <Route path="/reports" component={Reports} />
            <Route path="/insight" component={Transactions} />
            <Route path="/customers" component={Customers} />
            <Route path="/campaigns" component={Campaigns} />
            <Route path="/search" component={Search} />
            <Route path="/products" component={Products} />
            <Route path="/settings" component={Settings} />
            <Route path="/support" component={Products} />
            <Route exact path="/stores" component={StoresLayout}/>
               <Route exact path='/stores/:id' render={(props) => {
                return (<StoreDetailLayout {...props} />)
            }} />
            <Route path="/stores/add" component={AddStore}/>
            <ToastContainer
                position="bottom-right"
                autoClose={4000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />


        </>


    )
}

export default DashboardRoutes
