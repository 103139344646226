import React, { useState, useEffect,useContext } from 'react'
import CustomerTable from './CustomerTable.tsx';
import TopComponent from './TopComponent';
import "./customers.css"
import AuthContext from '../../../context/AuthContext';
import BottomComponent from './BottomComponent'
import { FETCH_TRANSACTIONS_CUSTOMERS } from '../../../apollo/queries';
import { useQuery } from '@apollo/client';
import './customers.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoins, faCreditCard, faUser } from '@fortawesome/free-solid-svg-icons';
import { Box } from '@mui/system';
import Toast from '../../../elements/Notifications/Toast';
import { CalculateTotalSpend, CalculateTotalVolume, dateToDayOfWeekConverter, DayOfWeek, FirstTransaction, FormatCurrency } from '../../../utils/customers';

function Customers() {

  const [searchFilter, setSearchFilter] = useState('');
  const [spinner, setSpinner ] = useState(true);
  const { auth, setAuth } = useContext(AuthContext);


  const [customerData, setCustomerData] = useState([]);

  const [originalData, setOriginalData] = useState([])

  const searchTable = (rows) => {
    return rows.filter((row) =>


      row.first_name.toLowerCase().indexOf(searchFilter) > -1 ||
      row.customer_vizopay_id.toLowerCase().indexOf(searchFilter) > -1 ||
      row.email.toLowerCase().indexOf(searchFilter) > -1 ||
      row.trees_planted.toString().toLowerCase().indexOf(searchFilter) > -1 ||
      row.total_amount.toString().indexOf(searchFilter) > -1 ||
      row.total_donated.toString().indexOf(searchFilter) > -1


    )
  }

  useEffect(() => {

    if(searchFilter.length) {
      setCustomerData(searchTable(originalData))
    } 
  }, [searchFilter])



  const [transactions, setTransactions] = useState([]);

  const [originalTransactionList, setOriginalTransactionList] = useState([
    {
      id: "",
      _id: "",
      status: "",
      amount: "",
      created: ""
    }
  ]);

  const [bestDay, setBestDay] = useState([]);

  const [refresh, setRefresh] = useState(false);

  const {data, loading, error, refetch} = useQuery(FETCH_TRANSACTIONS_CUSTOMERS, {
    variables: {
      "query": {
        "status": "succeeded"
      },
      "sortBy": "CREATED_DESC",
      "limit": 500
    },
  });

  const refetchData = async() => {
    refetch();

    setRefresh(false);
  }

  useEffect(() => {
    if(refresh) {
      refetchData();
    }
  }, [refresh])

  const sortPayments = async(data) => {
    setTransactions(data.map(data => {

      const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

      let date = new Date(data.created)

      return {
        id: data.payment_id,
        _id: data.customer_vizopay_id,
        status: data.status,
        email: data.customer_email,
        amount: `£${(((data.original_amount * (1 - data.percentage_discount)) - data.points_reduction_amount) / 100).toFixed(2)}`,
        created: `${date.getDate()} ${monthNames[date.getMonth()]} ${date.toLocaleTimeString()}`
      }
    }).reverse());

    setSpinner(false);

    setOriginalTransactionList(data.map(data => {

      const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

      let date = new Date(data.created)


      return {
        id: data.payment_id,
        _id: data.customer_vizopay_id,
        status: data.status,
        email: data.customer_email,
        amount: `£${(((data.original_amount * (1 - data.percentage_discount)) - data.points_reduction_amount)  / 100).toPrecision(4)}`,
        created: `${date.getDate()} ${monthNames[date.getMonth()]} ${date.toLocaleTimeString()}`
      }
    }).reverse());
  }


  useEffect(() => {

    if(error) {
      console.log(error, "error fetching payment gql")
    } else if(loading) {
      console.log("Fetching payments GraphQL...")
    } else {
      setTransactions(data.transactions);
      CreateTableData(data.transactions);
      setOriginalTransactionList(data.transactions);
      setSpinner(false);
    }
  }, [data])


  const [tableData, setTableData] = useState([]);

  const CreateTableData = (transactions) => {

   setTableData(CalculateTotalSpend(transactions));
   setBestDay(DayOfWeek(data.transactions));

}


  const [thisMonthData, setThisMonthData] = useState([]);

  const thisMonth = (transactions) => {

    var created_lte = new Date();
    var created_gte = new Date(created_lte.getFullYear(), created_lte.getMonth(), 1);

    var filerData = transactions.filter(a => {
      var date = new Date(a.created);
      return (date >= created_gte && date <= created_lte);
    });

    setThisMonthData(FirstTransaction(filerData));

}


  React.useEffect(() => {
    if(loading || error) {
        return;
    }

    if(data.transactions.length) {
      CreateTableData(data.transactions);
      thisMonth(data.transactions);
     }

}, [data])

  const topDataTabs = [
    {
      key: 1,
      icon: <FontAwesomeIcon style={{color: '#fff', fontSize: 20}} icon={faUser}/>,
      title: "Total Customers",
      backgroundColor: "#65ccb8",
      body: tableData.length,
      percentage: "10%"
    },
    {
      key: 2,
      icon: <FontAwesomeIcon style={{color: '#fff', fontSize: 20}} icon={faCreditCard}/>,
      title: "Customer LTV",
      backgroundColor: "#DEC1F6",
      body: FormatCurrency(CalculateTotalVolume(transactions)/tableData.length),
      percentage: "15%" 
    },
    {
      key: 3,
      icon: <FontAwesomeIcon style={{color: '#fff', fontSize: 20}} icon={faCoins}/>,
      title: "Average Spend",
      backgroundColor: "#FF8A8F",
      body: FormatCurrency(CalculateTotalVolume(transactions)/transactions.length), 
      percentage: "5%"
    }
  ];


  return (

    <Box paddingX="20px" width="100%">

    <div className='TopComponentCard'>
    {topDataTabs.map(dataItem => (
        <TopComponent
          key={dataItem.key}
          icon={dataItem.icon}
          title={dataItem.title}
          backgroundColor={dataItem.backgroundColor}
          body={dataItem.body}
          percentage={dataItem.percentage}
        />
        ))}

    </div>

    <BottomComponent data={tableData} thisMonthData={thisMonthData} bestDay={bestDay}/>
    
    <CustomerTable data={tableData} setSearchFilter={setSearchFilter} />
    
    <Toast 
      show={!spinner}
      message="Showing all customers"
      severity='success'
    />
</Box>
  )
}

export default Customers
