// @flow
import React, {useState,useContext} from 'react';
import Cookies from 'js-cookie';
import "./mobile.css"
import {Link, useRouteMatch} from 'react-router-dom'
import AuthContext from '../../../../context/AuthContext';

export default function MobileMenu(handleClickAway) {

    const { auth, setAuth } = useContext(AuthContext)


const handleLogout = () => {
    Cookies.remove('token')
    setAuth(false);

}
    const sideMenu = [
        {
            route: '/',
            external: null,
            text: 'Home'
        },
        {
            route: '/payments',
            external: null,
            text: 'Payments'
        },
        {
            route: '/customers',
            external: null,
            text: 'Customers'
        },
        {
            route: '/campaigns',
            external: null,
            text: 'Campaigns'
        },
        {
            route: '/stores',
            external: null,
            text: 'Stores'
        },
        {
            route:'/settings',
            external:null,
            text:'Settings'
        }
       
    ]

  

  return (
    
    <div className="whole">
      <section >
    <ul className="menu">
        {
            sideMenu.map((item) =>(
                <Link  to={item.route}>
                    <li onClick={handleClickAway}>{item.text}</li>
                </Link>
            ))
        } 
        <Link to={'/login'}>
                    <li onClick={handleLogout}>Log Out</li>
        </Link>
        
    </ul>
  </section>
    </div>
  );
};