import React,{useEffect,useState} from 'react'
import { Form, FormGroup, InputGroup, InputGroupAddon, InputGroupText, Input, Row, Col } from 'reactstrap';

import TextField from '@mui/material/TextField';
import { InputAdornment, Typography } from '@mui/material';
import { AccountCircle } from '@material-ui/icons';


function AboutForm({ firstName, lastName, email, phone, setFirstName, setLastName, setEmail, setPassword, setConfirmPassword, setPhone,password,setStrongPassword,confirmPassword,passwordMatch,setPasswordMatch, invalidEmail, invalidPhone, setInvalidEmail, setInvalidPhone }) {
    const [strongPass,setStrongPass] = useState(true);
    let strongRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{6,})/;
    const [initialRender,setInitialRender] = useState(true);
    const [initialRenderTwo,setInitialRenderTwo]= useState(true);
    


    useEffect(() => {
        if(initialRender){
            setInitialRender(false)
        }else{
            if ( strongRegex.test(password)){
                setStrongPass(true)
                setStrongPassword(true)
            } else {
                setStrongPass(false)
                setStrongPassword(false)
            }
        }
    },[password])

    useEffect(() => {
        if(initialRenderTwo){
            setInitialRenderTwo(false)
        }else{
            if ( password === confirmPassword){
               setPasswordMatch(true)
            } else {
                setPasswordMatch(false)
            }
        }
    },[confirmPassword])


    useEffect(() => {

            const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let result =  re.test(String(email).toLowerCase());

    

    if(!result) {
        setInvalidEmail(true);
    } else {
        setInvalidEmail(false)
    }

    }, [email])

    useEffect(() => {

         let result = /^(?:\W*\d){10}\W*$/.test(phone.toString().replace(/^0+/, ''))

         if(!result) {
             setInvalidPhone(true)
         } else {
             setInvalidPhone(false)
         }

    }, [phone])

    const styles = {
        validation: {
            borderColor: 'red'
        }
    }

    return (
        <div className="signup-form">
            <Form>
                <FormGroup>
                    <Row>
                        <Col>
                            <TextField style={{width: '100%'}} id="outlined-basic" label="First Name" variant="outlined" value={firstName} onChange={(e) => setFirstName(e.target.value)} size="lg" className="form-input" placeholder="Jenny" />
                        </Col>
                        <Col>
                            <TextField style={{width: '100%'}} id="outlined-basic" label="Last Name" variant="outlined" value={lastName} onChange={(e) => setLastName(e.target.value)} size="lg" className="form-input" placeholder="Doe" />
                            
                        </Col>
                    </Row>
                </FormGroup>
                <FormGroup>
                    <TextField style={{width: '100%'}} id="outlined-basic" label="Email" variant="outlined" value={email} onChange={(e) => setEmail(e.target.value)} size="lg" className="form-input" type="email" placeholder="jenny.doe@company.com" />
                    {(invalidEmail && email) ? <span className='error'>Please enter a valid email address</span> : null}

                </FormGroup>
                <FormGroup>
                    <TextField value={password} style={{width: '100%'}} id="outlined-basic" label="Password" variant="outlined" onChange={(e) => setPassword(e.target.value)} size="lg" className="form-input" type="password" placeholder="Please enter your password" />
                    {(!strongPass && password) && <span className='error'>Password must be atleast 6 characters long, have capital letter, a number and a special character </span>}
                </FormGroup>
                <FormGroup>
                    <TextField value={confirmPassword} style={{width: '100%'}} id="outlined-basic" label="Confirm Password" variant="outlined" onChange={(e) => setConfirmPassword(e.target.value)} size="lg" className="form-input" type="password" placeholder="Please confirm your password" />
                    {(!passwordMatch && confirmPassword) && <span className='error'>Password and Confirm password need to match</span>}
                </FormGroup>
                <FormGroup>
                    <InputGroup>
                        
                        <TextField
                        InputProps={{
                            startAdornment:
                                <InputAdornment position="start">
                                  +44
                                </InputAdornment>
                        }}
                       
                        style={{width: '100%'}} id="outlined-basic" label="Phone Number" variant="outlined" value={phone} onChange={(e) => setPhone(e.target.value)} size="lg" className="form-input" type="tel" placeholder="7700002222" />
                    {(invalidPhone && phone) ? <span className='error'>Please enter a valid phone number</span> : null}

                    </InputGroup>

                </FormGroup>
            </Form>
        </div>
    )
}

export default AboutForm