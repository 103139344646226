import React, {useState, useEffect} from 'react';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import axios from 'axios';



const AutocompleteGoogleMaps = ({placeId, setPlaceId, placeholder}) => {

const [value, setValue] = useState(null);

useEffect(() => {
    console.log("GOOGLE PLACES API: ", value)

    if(value) {
        setPlaceId(value.value.place_id)
    }

}, [value])


useEffect(() => {
    console.log(placeId, "PLACE ID")
})

 return(
      <div>
    <GooglePlacesAutocomplete
    
    style={{borderColor: '#65ccb8'}}
      apiKey="AIzaSyDoBRoBijY8_R2aWW6dAk4tFbklqLwYkoo"
       selectProps={{
           placeholder: placeholder ? placeholder : "Search for your store or enter an address...",
            styles: {
      input: (provided) => ({
        ...provided,
        color: '#222831',
      }),
      option: (provided) => ({
        ...provided,
        color: '#65ccb8',
      }),
      singleValue: (provided) => ({
        ...provided,
        color: '#65ccb8',
      }),
    },
          value,
          onChange: setValue,
        }}
    autocompletionRequest={{
        fields: ["address_components", "geometry", "icon", "name"],
    componentRestrictions: {
    country: ['gb'],
    }
  }}
    />
  </div>
 )
};

export default AutocompleteGoogleMaps;