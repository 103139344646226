import React from 'react';
import Veriff from '@veriff/js-sdk';
import VeriffElement from './VeriffElement';


function VeriffContainer() {


    return (
        <div style={{ margin: 'auto', display: 'inline-block' }} id='veriff-root'>
            <VeriffElement />
        </div>

    )
}

export default VeriffContainer
