import React, {useState, useEffect,useContext} from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import AuthContext from '../../../context/AuthContext';
import {Spinner} from 'reactstrap';
import { useQuery } from '@apollo/client';
import { FETCH_GROSS_VOLUME } from '../../../apollo/queries';

import { ITransactions } from '../../../constants/types';

import axios from 'axios';
import url from '../../../api/url';
import Cookies from 'js-cookie';
import { CalculateTotalVolume, FormatCurrency } from '../../../utils/customers';

const created_lte = new Date();
const created_gte = "2022-01-01T10:31:03.327+00:00";

function KeyStats() {

    const [spinner, setSpinner] = useState(true);
    const { auth, setAuth } = useContext(AuthContext);
    const [grossVolume, setGrossVolume] = useState(null);
    const [netVolume, setNetVolume] = useState(null);
    const [successfulPayments, setSuccessfulPayments] = useState(null);
    const [unsuccessfulPayments, setUnsuccessfulPayments] = useState(null);
    const [successRate, setSuccessRate] = useState(null);
    const [averageSpend, setAverageSpend] = useState(null);
    const [refunds, setRefunds] = useState(null);

    const [totalVolume, setTotalVolume] = React.useState(0)

    const [transactions, setTransactions] = useState([
      {
        payment_id: "",
        original_amount: 0,
        amount: 0,
        donation_amount: 0,
        application_fee_amount: 0,
        created: "",
        percentage_discount: 0.1,
        points_reduction_amount: 0
      }
    ]);

    const {data, loading, error} = useQuery(FETCH_GROSS_VOLUME, {
      variables: {
          "query": {
            "AND": [
              {
                "status": "succeeded",
                "created_gte": created_gte,
                "created_lte": created_lte.toISOString()
              }
            ]
          },
          "limit": 1000
        }
      });

      const sortTransactions = async(data) => {
        setTransactions(data.map(data => {
            return {
            payment_id: data.payment_id,
            original_amount: data.original_amount,
            amount: data.amount,
            donation_amount: data.donation_amount,
            application_fee_amount: data.application_fee_amount,
            created: data.created,
            percentage_discount: data.percentage_discount,
            points_reduction_amount: data.points_reduction_amount
            }
        }));
    }

      React.useEffect(() => {
        if (error) {
            console.log(error, "error fetching payment gql")
        } else if(loading) {
            console.log("Fetching payments GraphQL...")
        } else { 
            sortTransactions(data.transactions)
        }
    }, [data])

    let initialVolume = 0;

    const CreateVolume = (transactions: ITransactions[]) => {

    let sumVolume = data.transactions.reduce(
      (previousValue, currentValue) => {
          let total = previousValue + ((currentValue.original_amount * (1 - currentValue.percentage_discount)) - currentValue.points_reduction_amount);
          return total
      },
      initialVolume
    );

    setTotalVolume(sumVolume);

    }


    useEffect(() => {
      const getData = async() => {
        await axios.get(`${url}/business/data/dashboard/stats`, 
          {
            headers: {
              'x-auth-token': Cookies.get('token')
            }
          }
        ).then(res => {
          setGrossVolume(res.data.grossVolume);  
          setNetVolume(0);
          setSuccessfulPayments(res.data.successfulPayments);
          setUnsuccessfulPayments(res.data.unsuccessfulPayments);
          setSuccessRate(res.data.successRate);
          setAverageSpend(res.data.average);       
        }).catch(err => 
          {
            if(err.response.data.message === "jwt expired") {
              Cookies.remove('token');
              setAuth(false);
            }
            console.log(err.response)
          }
          
          );
      }

      getData();
    }, [])


    React.useEffect(() => {

      if(loading || error) {
          return;
      }

      if(data.transactions.length) {
        CalculateTotalVolume(data.transactions);
       }

  }, [data])


    const rows = [
        // {
        //     name: 'Gross Volume',
        //     metric: `£${(grossVolume/100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`,
        //     color: '#222831'
        // },
        {
          name: 'Gross Volume',
          metric: FormatCurrency(CalculateTotalVolume(transactions)),
          color: '#222831'
        },
        {
            name: 'Successful Payments',
            metric: `${successfulPayments}`,
            color: 'success'
        },
        {
            name: 'Unsuccessful Payments',
            metric: `${unsuccessfulPayments}`,
            color: 'danger'
        },
        {
            name: 'Success Rate',
            metric: `${(successRate*100).toFixed(2)} %`,
            color: 'secondary'
        },
        {
            name: 'Average Customer Spend',
            metric: FormatCurrency(CalculateTotalVolume(transactions)/successfulPayments),
            color: 'primary'
        },
        // {
        //     name: 'Total Refunded',
        //     metric: `£${refunds/100}`,
        //     color: 'warning'
        // },
    ]

    return (

        <div className="card h-100">
          {/* Header */}
          <div className="card-header">
            <h5 className="card-header-title">Reports overview</h5>
            {/* Unfold */}
            <div className="hs-unfold">
              <a className="js-hs-unfold-invoker btn btn-icon btn-sm btn-ghost-secondary rounded-circle" href="javascript:;" data-hs-unfold-options="{
                       &quot;target&quot;: &quot;#reportsOverviewDropdown1&quot;,
                       &quot;type&quot;: &quot;css-animation&quot;
                     }">
              </a>
            </div>
            {/* End Unfold */}
          </div>
          {/* End Header */}
          {/* Body */}
          <div className="card-body">
           
            {/* End Progress */}
            {/* Table */}
            <div className="table-responsive">
              <table className="table table-lg table-nowrap card-table mb-0">
                <tbody>

                {
                  rows.map(data => {
                    return(
                        <tr>
                    <th scope="row">
                      <span className={`legend-indicator bg-${data.color}`} />{data.name}
                    </th>
                    <td>{data.metric}</td>
                    {/* <td>
                      <span className={`badge badge-soft-${data.color}`}>+12.1%</span>
                    </td> */}
                  </tr>
                    )
                  })
                }
              
                </tbody></table>
            </div>
            {/* End Table */}
          </div>
          {/* End Body */}
        </div>


    )
}

export default KeyStats
