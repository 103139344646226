import React, {useState} from 'react';
import {Container, Row, Col, Spinner} from 'reactstrap';
import ReportsHero from './ReportsHero';
import ReportsSummary from './ReportsSummary';
import './reports.css';
import BasicDatePicker from '../../../elements/DatePicker';
import ReportsDailyCharts from './ReportsDailyChart';
import { ITransactions } from '../../../constants/types';
import { FETCH_INVOICE } from '../../../apollo/queries';
import { useQuery } from '@apollo/client';
import Toast from '../../../elements/Notifications/Toast';
import { Paper } from '@mui/material';
import { Box } from '@mui/system';

const created_lte = new Date();
const created_gte = new Date(created_lte.getFullYear(), created_lte.getMonth() - 1, created_lte.getDate());



const ReportsLayout = () => {

    const [dateFrom, setDateFrom] = React.useState<Date|any>(created_gte);
    const [dateEnd, setDateEnd] = React.useState<Date|any>(created_lte);

    const [totalVolume, setTotalVolume] = React.useState<number>(0)
    const [platformFee, setPlatformFee] = React.useState<number>(0)
    const [carbonOffset, setCarbonOffset] = React.useState<number>(0)

    const [transactions, setTransactions] = useState([
        {
          payment_id: "",
          original_amount: 0,
          amount: 0,
          donation_amount: 0,
          application_fee_amount: 0,
          created: "",
          percentage_discount: 0.1,
          points_reduction_amount: 0
        }
      ]);

    const {data, loading, error, refetch} = useQuery(FETCH_INVOICE, {
    variables: {
        "query": {
          "AND": [
            {
              "status": "succeeded",
              "created_gte": created_gte.toISOString(),
              "created_lte": created_lte.toISOString()
            }
          ]
        },
        "limit": 1000
      }
    });

    const sortTransactions = async(data) => {
        setTransactions(data.map(data => {
            return {
            payment_id: data.payment_id,
            original_amount: data.original_amount,
            amount: data.amount,
            donation_amount: data.donation_amount,
            application_fee_amount: data.application_fee_amount,
            created: data.created,
            percentage_discount: data.percentage_discount,
            points_reduction_amount: data.points_reduction_amount
            }
        }));
    }

    React.useEffect(() => {
        if (error) {
            console.log(error, "error fetching payment gql")
        } else if(loading) {
            console.log("Fetching payments GraphQL...")
        } else { 
            sortTransactions(data.transactions)
        }
    }, [data])

    interface IVolumeChart {
        x: any;
        y: any;
    }

    const [chartTransactions, setChartTransactions] = React.useState<IVolumeChart[]>([]);

    const CreateReport = (transactions: ITransactions[]) => {

        let initialVolume = 0;
        let arr = [];

    let sumVolume = data.transactions.reduce(
        (previousValue, currentValue, currIndex) => {
    
            let total = previousValue + ((currentValue.original_amount * (1 - currentValue.percentage_discount)) - currentValue.points_reduction_amount)

            arr.push(
              [
                currIndex +1,
                total
              ]
             
            )

            setChartTransactions(arr);

            return total

        },
        initialVolume
      );
    

    let initialPlatformfee = 0;
    
    let sumPlatformFee = transactions.reduce(
        (previousValue, currentValue) => previousValue + currentValue.application_fee_amount,
        initialPlatformfee
      );
    
    let initialCarbonOffset = 0;
    
    let sumCarbonOffset = transactions.reduce(
        (previousValue, currentValue) => previousValue + currentValue.donation_amount,
        initialCarbonOffset
      );
    
    let convertFormat = (numberTotal: number) => {
        return Number((numberTotal/100).toFixed(2))
    };

    let totalVolume = convertFormat(sumVolume);
    let platformFee = convertFormat(sumPlatformFee);
    let carbonOffset = convertFormat(sumCarbonOffset);

    setTotalVolume(totalVolume);
    setPlatformFee(platformFee);
    setCarbonOffset(carbonOffset);


    }

    React.useEffect(() => {

        if(loading || error) {
            return;
        }
  
        // Fetch transactions between dates

        if(dateFrom && dateEnd){
            refetch(
                {
                    "query": {
                      "AND": [
                        {
                          "status": "succeeded",
                          "created_gte": dateFrom.toISOString(),
                          "created_lte": dateEnd.toISOString()
                        }
                      ]
                    }
                }
            )
        }

        // sort data function

       if(data.transactions.length) {
        CreateReport(data.transactions);
       }

    }, [dateFrom, dateEnd, data])


    return(
     <>
        <ReportsHero />
        <div className="report-date-picker-container">
          <div className='report-date-picker'>
            <BasicDatePicker label='Date From' initial={created_gte} onChange={(e) => setDateFrom(e)} />
          </div>

          <div className='report-date-picker'>
            <BasicDatePicker label='Date To' initial={created_lte} onChange={(e) => setDateEnd(e)} />
          </div>

        </div>
        {
            loading ?
            <div className="report-spinner">
                <Spinner />
            </div>
            :
            <>
            <div className='report-chart-container'>
               <Paper style={{backgroundColor: "#49AFA5"}} elevation={1}>
                <Box padding="20px">
                <h2>Gross Sales Chart</h2>
                <ReportsDailyCharts data={data.transactions} transactions={chartTransactions} />
                </Box>
               </Paper>
            </div>
            <ReportsSummary totalVolume={totalVolume} platformFee={platformFee} carbonOffset={carbonOffset} dateFrom={dateFrom} dateEnd={dateEnd}/>
            </>
        }
            <Toast 
      show={!loading && data}
      message={`Report created for dates ${dateFrom.toLocaleString().slice(0, 10)} to ${dateEnd.toLocaleString().slice(0, 10)}`}
      severity='success'
    />
    </>
    )
}

export default ReportsLayout;