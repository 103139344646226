import { Grid, Skeleton, Stack, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import RewardCard from './RewardCard'
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import TextField from '@mui/material/TextField';
import Slider from '@mui/material/Slider';

import './RewardCSS.css'

type Props = {}

const RewardLayout = (props: Props) => {

const [rewards, setRewards] = React.useState([]);

const FakeCallback = async() => {
    const delay = ms => new Promise(res => setTimeout(res, ms));

    await delay(1000)

    setRewards([0, 1, 2])
}

React.useEffect(() => {
    FakeCallback()
},[])

let original = [
    {
        id: 0,
        reward: "Stamps",
        description: "Stamps are a great way to achieve your target. Here is an example of how you can use this:",
        suggestion: "Offer Double Stamps on your weakest day",
        selected: false
    },
    {
        id: 1,
        reward: "Points",
        description: "Points are a great way to achieve your target. Here is an example of how you can use this:",
        suggestion: "10% points when you spend above your average spend or more",
        selected: false
    },
    {
        id: 2,
        reward: "Percentage Discount",
        description: "Percentage Discounts are a great way to achieve your target. Here is an example of how you can use this:",
        suggestion: "10% off on a Tuesday",
        selected: false
    }
]

 const [rewardArray, setRewardArray] = React.useState(original)

 const SelectReward = (index: number, select: number) => {
    if (select === 2) {
        const arr = [...rewardArray]
        arr[index].selected = false;
        setRewardArray(arr);
    } else {
        const arr = [...rewardArray]
        arr[index].selected = true;
        setRewardArray(arr);
    }
  };

// Stamp buttons 
  const [stamp, setStamp] = React.useState<number>(0);

  const AddStamp = () => {

    if(stamp === 20) {    
        setStamp(20);
    } else {
        setStamp(stamp + 1);
    }

  }

  const MinusStamp = () => {

    if(stamp === 0) {
        setStamp(0)
    } else {
        setStamp(stamp - 1)
    }

  }

  const [value, setValue] = React.useState('');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };


  //Points and Discount 

  const percentageMark = [
    {
      value: 0.05,
      label: '5%',
    },
    {
      value: 0.1,
      label: '10%',
    },
    {
      value: 0.15,
      label: '15%',
    },
    {
      value: 0.2,
      label: '20%',
    },
    {
      value: 0.25,
      label: '25%',
    },
    {
      value: 0.3,
      label: '30%',
    },
    {
      value: 0.35,
      label: '35%',
    },
    {
      value: 0.4,
      label: '40%',
    },
    {
      value: 0.45,
      label: '45%',
    },
    {
      value: 0.5,
      label: '50%',
    }
  ];


  return (
    <Stack spacing={2}>
        <Box>
            <Typography variant="h6">We're working out the best rewards based on your data, users & rules...</Typography>
        </Box>
        <Grid container width="100%" spacing={2} justifyContent="flex-start" alignItems='center'>
    

    {
        rewards.length ?

        
        rewardArray.map((reward) => {
            return(
                <>
                <Grid spacing={2} item sm={4}>
                    <RewardCard onChange={(index, select) => SelectReward(index, select)} reward={reward} key={reward.reward} />
                    
                </Grid>

                </>
            )
           })
   

        :


           [0,1,2].map((reward) => {
            return(
                <Grid spacing={2} item sm={4}>
                <Skeleton key={reward} variant="rectangular" width="100%" sx={{borderRadius: "10px"}} height={300} />
                </Grid>
            )
           })

        }

</Grid>


{
           rewardArray.map((reward) => {
            return(

                        reward.selected ?

                        reward.reward === "Stamps" ?

                        <Stack spacing={2}>
                        <Box>
                        <h1>{reward.reward}</h1>

                            <p>Number of stamps required to get reward:</p>

                            <ButtonGroup variant="contained" aria-label="outlined primary button group">
                                <Button onClick={MinusStamp} >-</Button>
                                <span className='stampNumberPlace'>{stamp}</span>
                                <Button onClick={AddStamp} >+</Button>
                            </ButtonGroup>

                            <Box
                                component="form"
                                sx={{
                                    '& .MuiTextField-root': { m: 1, width: '123ch' },
                                }}
                                noValidate
                                autoComplete="off"
                                >
                                <div style={{padding: "10px 0px 0px", width: "100%"}}>
                                    <TextField
                                    id="outlined-multiline-flexible"
                                    label="Reward Description"
                                    placeholder="Add your reward description so that people know how to get the reward"
                                    multiline
                                    rows={4}
                                    maxRows={4}
                                    value={value}
                                    onChange={handleChange}
                                    />
                                </div>
                                <div style={{width: "100%"}}>
                                    <TextField
                                    id="outlined-multiline-flexible"
                                    label="Reward Type"
                                    placeholder="Free Coffee"
                                    multiline
                                    rows={2}
                                    maxRows={4}
                                    value={value}
                                    onChange={handleChange}
                                    />
                                </div>
                            </Box>

                        </Box>
                        </Stack>

                        :

                        <Stack spacing={2}>
                        <Box>
                        <h1>{reward.reward}</h1>

                        <p>Pick a percentage of discount you would like to offer your customers</p>

                        <Box width={950}>
                            <Slider

                                defaultValue={0.2}
                                aria-labelledby="range-slider"
                                step={0.05}
                                min={0.05}
                                max={0.5}
                                marks={percentageMark}
                            />
                        </Box>


                            
                        </Box>
                        </Stack>
                        
                        :

                        null
                    

            )
           })
        
        }



    </Stack>

  )
}

export default RewardLayout