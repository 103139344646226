import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'

function ProductSelection() {

    const [payments, setPayments] = useState(false);
    const [environmental, setEnvironmental] = useState(false);

    const styles = {
        backgroundColor: '#65ccb8',
        textColor: {
            color: '#fff'
        },
        icon: faCheck
    }

    return (
        <div className="product-selection-container">
            <div onClick={() => setPayments(!payments)} style={payments ? styles : null} className="product-div">
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <h4 style={payments ? styles.textColor : null}>Payments</h4>
                    <h4 style={styles.textColor}>{payments ? <FontAwesomeIcon icon={faCheck} /> : null}</h4>
                </div>
                <div>
                    <p style={payments ? styles.textColor : null}>Accept payments in an environmentally friendly way.</p>
                    {payments ? <small style={styles.textColor}>Price per transaction. No minimum volume. No contracts.</small> : null}
                </div>
            </div>
            <div onClick={() => setEnvironmental(!environmental)} style={environmental ? styles : null} className="product-div">
                <div>
                    <h4 style={environmental ? styles.textColor : null}>Environmental {environmental ? <FontAwesomeIcon icon={faCheck} /> : null}</h4>
                </div>
                <div>
                    <p style={environmental ? styles.textColor : null}>Easily fund environmental projects, technology and more in 2 clicks.</p>
                </div>
            </div>
        </div>
    )
}

export default ProductSelection
