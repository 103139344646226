import React from 'react'
import { Container, Row, Col, Card, CardImg, CardBody, CardTitle, FormGroup, Form, Input, CardSubtitle, Button, CardText } from 'reactstrap';
import PasswordSettings from './PasswordSettings';
import PlatformSettings from './PlatformSettings';
import ProfileSettings from './ProfileSettings';

import './settings.css';

function Settings() {
    return (
        <Container fluid>
            <Row>
                <Col>
                    <Card className="shadow p-3 mb-5 bg-white rounded settings-card">
                        <CardTitle className="card-header">
                            <h3>Profile Settings</h3>
                        </CardTitle>
                        <CardBody>
                            <ProfileSettings />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Card className="shadow p-3 mb-5 bg-white rounded settings-card">
                        <CardTitle className="card-header">
                            <h3>Password Settings</h3>
                        </CardTitle>
                        <CardBody>
                            <PasswordSettings />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Card className="shadow p-3 mb-5 bg-white rounded settings-card">
                        <CardTitle className="card-header">
                            <h3>Vizopay Settings</h3>
                        </CardTitle>
                        <CardBody>
                            <PlatformSettings />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}

export default Settings