import React, {useState} from 'react'
import {Container, Row, Col} from 'reactstrap'
import InvoiceMain from './InvoiceMain'
import Welcome from './Welcome';

function InvoiceLayout() {

    const [invoice, setInvoice] = useState(false);

    return (
        <>
            {
                invoice ?

                <InvoiceMain />

                :

                <Welcome setInvoice={setInvoice}/>

            }
      
        </>
    )
}

export default InvoiceLayout
