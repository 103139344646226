import React, { useState, useEffect,useContext } from 'react'

import { Spinner } from 'reactstrap'

import { LineChart, Line, Tooltip, ResponsiveContainer, XAxis, CartesianGrid } from 'recharts';
import AuthContext from '../../../context/AuthContext';
import moment from 'moment'
import ReactEcharts from 'echarts-for-react';


import Cookies from 'js-cookie';
import url from '../../../api/url';
import axios from 'axios';


const MainChart = ({ selectedDate, crosshairValue, setCrosshairValue }) => {
  const { auth, setAuth } = useContext(AuthContext);
  const [spinner, setSpinner] = useState(true);

  const [transactions, setTransactions] = useState([]);
  const [yesterday, setYesterday] = useState([])

  const times = [{ x: moment().startOf('day').toDate(), y: 0 }, { x: moment().endOf('day').toDate(), y: 0 }]

  useEffect(() => {

    let token = Cookies.get('token');

    const getTransactions = async () => {
      await axios.get(`${url}/business/data/transactions/volume`, {
        headers: {
          'x-auth-token': token
        }
      }).then(res => {
        setTransactions(res.data.today.map(data => { return (data / 100).toFixed(2) }))
        setYesterday(res.data.yesterday.map(data => { return (data / 100).toFixed(2) }))
      }).catch(err => {
        if(err.response.data.message === "jwt expired") {
          Cookies.remove('token');
          setAuth(false);
        }
        console.log(err.response)
      }
        
        );
    }

    getTransactions();

  }, [])


  const data = transactions;
  const themeColors = {
    dark: '#222831',
    primary: '#6cabab'
  }

  const getOption = (totalOrderData, isDark) => {
    const grays = '#65ccb8';
    return {
      tooltip: {
        triggerOn: 'mousemove',
        trigger: 'axis',
        axisPointer: {
          type: 'cross'
        },
        //    position: function (pos, params, el, elRect, size) {
        //         var obj = {top: 10};
        //         obj[['left', 'right'][+(pos[0] < size.viewSize[0] / 2)]] = 30;
        //         return obj;
        //     },
        formatter: function (param) {

          var today;
          var yesterday;

          if (!param[1]) {
            today = Math.max(...transactions)
          } else {
            today = param[1].data
          }

          return [
            'Time: ' + param[0].name + '<hr size=1 style="margin: 3px 0">',
            'Today: £' + today + '<br/>',
            'Yesterday: £' + param[0].data + '<br/>',
          ].join('');
        },
        padding: [7, 10],
        backgroundColor: '#fff',
        borderColor: grays['300'],
        borderWidth: 1,
        transitionDuration: 0,
        textStyle: { color: themeColors.dark }
      },
      title: {
        show: true,
        title: "Payments"
      },
      xAxis: {
        type: 'category',
        data: ['00:00', '01:00', '02:00', '03:00', '04:00', '05:00', '06:00', '07:00', '08:00', '09:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00', '23:59'],
        boundaryGap: false,
        splitLine: { show: false },
        axisLine: {
          show: true,
          lineStyle: {
            color: '#fff',
            type: 'line'
          }
        },
        axisLabel: { show: true },
        axisTick: { show: false },
        axisPointer: {
          snap: true,
          lineStyle: {
            color: '#6cabab',
            width: 2
          },
          label: { show: false }
        },

      },
      yAxis: {
        type: 'value',
        splitLine: {
          show: false,

        },
        axisLine: {
          show: true,
          lineStyle: {
            color: '#fff',
            type: 'line'
          }

        },
        axisLabel: { show: false },
        axisTick: { show: false },
        axisPointer: { show: false }
      },
      series: [
        {
          type: 'line',
          lineStyle: {
            color: '#fff',
            width: 1
          },
          itemStyle: {
            color: grays['100'],
            borderColor: '#fff',
            borderWidth: 1
          },
          hoverAnimation: true,
          data: yesterday,
          connectNulls: true,
          smooth: 0.6,
          smoothMonotone: 'x',
          symbol: 'circle',
          symbolSize: 0,
          areaStyle: {
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color: 'transparent'
                },
                {
                  offset: 1,
                  color: 'transparent'
                }
              ]
            }
          }
        },
        {
          type: 'line',
          lineStyle: {
            color: themeColors.primary,
            width: 3
          },
          itemStyle: {
            color: grays['100'],
            borderColor: themeColors.primary,
            borderWidth: 2
          },
          hoverAnimation: true,
          data: totalOrderData,
          connectNulls: true,
          smooth: 0.6,
          smoothMonotone: 'x',
          symbol: 'circle',
          symbolSize: 0,
          areaStyle: {
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color: '#6cabab'
                },
                {
                  offset: 0.5,
                  color: '#65ccb8'
                },
                {
                  offset: 1,
                  color: '#ceeee7'
                }
              ]
            }
          }
        },


      ],
    };
  };

  return (
    <>
      {
        transactions.length ?
          <>
            <ReactEcharts

              option={getOption(data)}

            />
          </>
          :
          ''
      }
    </>

  )
}

export default MainChart
