import React, { useContext, useState, useEffect } from 'react';
import { Container, Row, Col, Input, Badge } from 'reactstrap';
import axios from 'axios';
import url from '../../../api/url';
import Cookies from 'js-cookie';
import { useHistory } from 'react-router-dom';
import Moment from 'react-moment';
import moment from 'moment';
import AuthContext from '../../../context/AuthContext';

function Search(props) {
  const [loading, setLoading] = useState(true);
  const [keyword, setKeyword] = useState("");
  const [results, setResults] = useState([]);
  const history = useHistory();
  const { auth, setAuth } = useContext(AuthContext);

  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  useEffect(() => {
    const getSearch = async (data) => {
      setLoading(true);
      await axios.post(`${url}/business/data/transactions/search`,
        data,
        {
          headers: {
            'x-auth-token': Cookies.get('token')
          }
        }
      ).then(response => {
        setResults(response.data);
        setLoading(false);
      }).catch(error => {
        if(error.response.data.message === "jwt expired") {
          Cookies.remove('token');
          setAuth(false);
        }
        setLoading(false);
        console.log(error);
      })
    }

    var query = window.location.search.split("&")[0].replace("?query=", "").replace(/%20/g, "");

  
    if (query) {
      // %3C <
      // %3E >
      setKeyword(query);
      var array = [];
      var data = {};
      var key = "";

      if (query.search("%3C") != -1) {
        array = query.split("%3C");
        key = array[0].replace(":", "");
        data = {
          [key]: {
            $lt: array[1] * 100
          }
        };
      } else if (query.search("%3E") != -1) {
        array = query.split("%3E");
        key = array[0].replace(":", "");
        data = {
          [key]: {
            $gt: array[1] * 100
          }
        };
      } else {
        array = query.split(":");
        if (array[0] == "date") {
          const start = moment();
          var today = start.format("YYYY-MM-DD");
          var tommorow = start.add(1, 'days').format("YYYY-MM-DD");
          var tommorow1 = start.add(1, 'days').format("YYYY-MM-DD");
          var yesterday = start.add(-3, 'days').startOf('day').format("YYYY-MM-DD");
          if (array[1] == "today") {
            data = {
              created: {
                $gte: today,
                $lt: tommorow
              }
            };
          } else if (array[1] == "tommorow") {
            data = {
              created: {
                $gte: tommorow,
                $lt: tommorow1
              }
            };
          } else if (array[1] == "yesterday") {
            data = {
              created: {
                $gte: yesterday,
                $lt: today
              }
            };
          }

        } else {
          if (array.length == 2) {
            data = {
              [array[0]]: array[1]
            };
          } else if (validateEmail(array[0])) {
            data = {
              customer_email: array[0]
            };
          } else if (array.length == 1) {
            data = {
              payment_id: array[0]
            };
          }
        }
      }
      getSearch(data);
    } else {
      setLoading(false);
    }
  }, [props.location.search])

  return (
    !loading ?
      results.length ?
        <div className="card customer-container container-fluid">
          <Row Row className="card-header">
            <Col lg="3">
              <h4 className="payments-header">
                Search results:
              </h4>
            </Col>
          </Row>
          <p className="card-content">
            <>
              <Row Row className="card-header">
                <Col lg="3">
                  <h4 className="payments-header">
                    Payment
                  </h4>
                </Col>
              </Row>
              <table class="table">
                <thead>
                  <th>AMOUNT</th>
                  <th>STATUS</th>
                  <th>PAYMENT ID</th>
                  <th>CUSTOMER</th>
                  <th>DATE</th>
                </thead>
                <tbody>
                  {results.map(function (data, index) {

                    var status;
                    var color;

                    switch (data.status) {
                      case "succeeded":
                        status = "Succeeded"
                        color = "#65ccb8"
                        break;

                      case "incomplete":
                        status = "Incomplete"
                        color = "#4b778d"
                        break;

                      case "pending":
                        status = "Pending"
                        color = "#bdc7c9"
                        break;

                      case "blocked":
                        status = "Blocked"
                        color = "#ff8882"
                        break;
                    }

                    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
                      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
                    let date = new Date(data.created)
                    const paymentDetailClick = () => {

                      let path = `/transactions/${data.payment_id}`;
                      history.push(path);
                    }

                    return <tr onClick={paymentDetailClick}>
                      <td>{`£${(data.amount / 100).toFixed(2)}`}</td>
                      <td>
                        <Badge style={{ backgroundColor: color, fontFamily: 'Avenir', fontWeight: '600' }}>
                          {status}
                        </Badge>
                      </td>
                      <td>{data.payment_id}</td>
                      <td>{data.customer_email}</td>
                      <td>
                        {`${date.getDate()} ${monthNames[date.getMonth()]} ${date.toLocaleTimeString()}`}
                      </td>
                    </tr>
                  })}
                </tbody>
              </table>
            </>
          </p>
        </div>
        :
        'No search results found.'
      :
      'Loading..'
  )
}

export default Search;