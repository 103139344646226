import React, {useState, useContext,useEffect,useRef} from 'react'
import Flatpickr from "react-flatpickr";

import UserContext from '../../../context/UserContext';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload } from '@fortawesome/free-solid-svg-icons'

import {ReactPDF, PDFDownloadLink} from '@react-pdf/renderer';
import * as htmlToImage from "html-to-image";
import SustainabilityPDF from './additional/SustainabilityPDF';
import { Doughnut } from 'react-chartjs-2';
import Chart from 'chart.js';
import ChartJsImage from 'chartjs-to-image'
import tree from "../../../assets/images/tree.png"


const carbonData = [74, 20, 6, 0, 0]


export const data = {
  labels: [`Forestration (${carbonData[0]}%)`, `Soil Seq (${carbonData[1]}%)`, `Biochar (${carbonData[2]}%)`, `Direct Air Capture (${carbonData[3]}%)`, `Bio-Oil (${carbonData[4]}%)`],
  datasets: [
    {
      label: 'Carbon Capture',
      data: carbonData,
      backgroundColor: [
        '#5c8d89',
        '#d66829',
        '#6060f7',
        '#8b9c6b',
        '#47abbf',
        
      ],
      borderColor: [
        '#5c8d89',
        '#d66829',
        '#6060f7',
        '#8b9c6b',
        '#47abbf',
        
      ],
      borderWidth: 1,
       
    },
  ]
  
};


function PaymentsSuccess() {

  const {userData} = useContext(UserContext);

    const [date, setDate] = useState(new Date())

  const [Imgurl,setImgUrl] = useState("");
  const [updated,setUpdated] = useState(false)
  
  const ref = useRef()
  


  useEffect(() =>{
    const call = async() => {
      const myChart = new ChartJsImage();
      myChart.setConfig({
        type: 'doughnut',
        data: data,
        options: {
          responsive: true,
          title: {
            display: true,
            position: "top",
            text: "Carbon Capture",
            fontSize: 30,
            fontColor: "#5c8d89"
          },
          cutoutPercentage: 90,
          plugins: {
            datalabels: {
              display:false,
              formatter: (value) => {
                return value + '%';
              },
              color: 'black',
              font: {
              
                size: 12,
              }
            },
            doughnutlabel: {
              labels: [{
                text: "Beyond Trees",
                color: '#5c8d89',
                font: {
                  size: 30,
                  weight: 'bold'
                }
              }, {
                text: 'Portfolio',
                color: '#5c8d89',
              }]
            }
          },
          
          legend: {
            display: true,
            position: "right",
            labels: {
              fontColor: "black",
              fontSize: 12,
             
            }
          }
        },
      });

      myChart.setWidth(700).setHeight(500)


      const dataUrl = await myChart.toDataUrl();
      setImgUrl(dataUrl)
    }

    call()
  },[])


    const rows = [
        {
            name: 'Trees Planted',
            metric: `${Math.floor(userData.trees_planted).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`,
            color: 'primary'
        },
        {
            name: 'Est. CO2 Sequestered',
            metric: `${(userData.carbon_offset).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} kg per year`,
            color: 'success'
        },/*
        {
            name: 'Verified CO2 Sequestered',
            metric: `12.7 kg`,
            color: 'danger'
        },*/
        {
            name: 'Total Donated',
            metric: `£${(userData.total_donated/100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`,
            color: 'secondary'
        }, 
        // {
        //     name: 'Vizopay Impact Score',
        //     metric: '789',
        //     color: 'primary'
        // },
      /*  {
            name: 'Projects Funded',
            metric: `4`,
            color: 'warning'
        },*/
    ]


    return (
                <div className="card h-100">
          {/* Header */}
          <div className="card-header">
            <h5 className="card-header-title">Sustainability overview</h5>
            {/* Unfold */}
            <PDFDownloadLink
            document={<SustainabilityPDF Imgurl = {Imgurl} userData={userData}/>}
          fileName={`Sustainability Report.pdf`}
          
            >
              {({ blob, url, loading, error }) =>
            error ? console.log(error) : loading ? "Loading document..." : 
            
             "Download"
          }
            </PDFDownloadLink>
          
             
  
            {/* End Unfold */}
          </div>
          {/* End Header */}
          {/* Body */}
          <div className="card-body">
           
            {/* End Progress */}
            {/* Table */}
            <div className="table-responsive">
              <table className="table table-lg table-nowrap card-table mb-0">
                <tbody>

                {
                  rows.map(data => {
                    return(
                        <tr>
                    <th scope="row">
                      <span className={`legend-indicator bg-${data.color}`} />{data.name}
                    </th>
                    <td>{data.metric}</td>
                    {/* <td>
                      <span className={`badge badge-soft-${data.color}`}>+12.1%</span>
                    </td> */}
                  </tr>
                    )
                  })
                }
              
                </tbody></table>
            </div>
            {/* End Table */}
          </div>
          {/* End Body */}
        </div>

    )
}

export default PaymentsSuccess
