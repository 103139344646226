import * as React from 'react';
import { Dayjs } from 'dayjs';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

// const [value, setValue] = React.useState<Dayjs | null>(dayjs('2022-04-07'));

interface IDatePicker {
    label: string;
    initial: any;
    onChange?: (value: any) => void;
    style?: any;
    fullWidth?: boolean;
}

export default function BasicDatePicker({
    label,
    onChange,
    initial,
    style,
    fullWidth,
    ...rest
}: IDatePicker) {
  const [value, setValue] = React.useState<Dayjs | null>(initial);

  React.useEffect(() => {

    if(onChange) {
        onChange(value)
    } else {
        return;
    }
  }, [value])

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        InputProps={{
          style: style
        }}
        label={label}
        value={value ? value : initial}
        onChange={(newValue) => {
          setValue(newValue);
        }}
        renderInput={(params) => <TextField fullWidth={fullWidth} {...params} />}
        {...rest}
      />
    </LocalizationProvider>
  );
}
