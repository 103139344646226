import React, {useState} from 'react'
import {Form, FormGroup, Label, Button, Spinner, Input, Col, Row} from 'reactstrap';
import Logo from '../../../assets/images/Vizopay Logo-01.png'
import LoginImage from '../../../assets/images/login-image.svg'


function AddTeamSignup() {

    const [spinner, setSpinner] = useState(false);

    return (
                <main id="content" role="main" className="main pt-0">
        {/* Content */}
        <div className="container-fluid px-3">
          <div className="row">
            {/* Cover */}
            <div className="col-lg-6 d-none d-lg-flex justify-content-center align-items-center min-vh-lg-100 position-relative bg-light px-0">
              {/* Logo & Language */}
              <div className="position-absolute top-0 left-0 right-0 mt-3 mx-3">
                <div className="d-none d-lg-flex justify-content-between">
                  <a href="/">
                    <img className="w-100" src={Logo} alt="Logo" style={{minWidth: '7rem', maxWidth: '7rem'}} />
                  </a>
                </div>
              </div>
              {/* End Logo & Language */}
              <div style={{maxWidth: '23rem'}}>
                <div className="text-center mb-5">
                  <img className="img-fluid" src={LoginImage} alt="Image Description" style={{width: '12rem'}} />
                </div>
                <div className="mb-5">
                  <h2 className="display-4">Get started with:</h2>
                </div>
                {/* List Checked */}
                <ul className="list-checked list-checked-lg list-checked-success list-unstyled-py-4">
                  <li className="list-checked-item">
                    <span className="d-block font-weight-bold mb-1">All-in-one tool</span>
                    Accept payments, manage refunds & track your environmental impact all in one place.
                  </li>
                  <li className="list-checked-item">
                    <span className="d-block font-weight-bold mb-1">Free forever</span>
                    There are no minimum volumes, contract or any of that nonsense. You only pay per successful payment made through Vizopay.
                  </li>
                </ul>
                {/* End List Checked */}
                {/* End Row */}
              </div>
            </div>
            {/* End Cover */}
            <div className="col-lg-6 d-flex justify-content-center align-items-center min-vh-lg-100">
              <div className="w-100 pt-10 pt-lg-7 pb-7" style={{maxWidth: '90%'}}>
                <Row>
                    <Col>
                        <h3>Join your Team!</h3>
                    </Col>
                </Row>
                <Form >
                 <FormGroup>
                    <Input size="lg" className="form-input" type="text" placeholder="Jenny"/>
                </FormGroup>
                 <FormGroup>
                    <Input size="lg" className="form-input" type="text" placeholder="Doe"/>
                </FormGroup>
             <FormGroup>
                    <Input size="lg" className="form-input" type="email" placeholder="jenny.doe@company.com"/>
                </FormGroup>
                <FormGroup>
                    <Input  size="lg" className="form-input" type="password" placeholder="Please enter your password"/>
                </FormGroup>
                 <FormGroup>
                    <Input  size="lg" className="form-input" type="password" placeholder="Please confirm your password"/>
                </FormGroup>
                {
                    spinner ?

                    <Button className="green-button" basic loading>
                        Sign up
                    </Button>

                    :

                    <Button className="green-button" type='submit'>
                        Sign up
                    </Button>
                }
                </Form>
              </div>
            </div>
          </div>
          {/* End Row */}
        </div>
        {/* End Content */}
      </main>
    )
}

export default AddTeamSignup
