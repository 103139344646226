import { Close } from '@mui/icons-material';
import { Alert, IconButton, Snackbar } from '@mui/material';
import React from 'react'

type Props = {
    show: boolean;
    close?: boolean;
    autoHideDuration?: number;
    message: string;
    severity?: "success" | "warning" | "error" | "info";
}

const Toast = ({
    show,
    close,
    autoHideDuration,
    message,
    severity
}: Props) => {
    const [open, setOpen] = React.useState(false);

    React.useEffect(() => {
        setOpen(show);
    }, [show])
    
      const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpen(false);
      };
  
    const action = (
      <React.Fragment>
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={handleClose}
        >
          <Close fontSize="small" />
        </IconButton>
      </React.Fragment>
    );
  return (
    <div>
           <Snackbar
        open={open}
        autoHideDuration={autoHideDuration ? autoHideDuration : 3000}
        onClose={handleClose}
        message={message}
        action={action}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
        }}
      >
           <Alert variant='filled' onClose={handleClose} severity={severity ? severity : "info"} sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>
    </div>
  )
}

export default Toast