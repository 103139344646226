import React, { useEffect, useState,useMemo,useContext } from 'react';
import { DataGrid } from '@material-ui/data-grid';

import { makeStyles } from "@material-ui/core/styles";

import { Input, Spinner, Badge, Row, Col } from 'reactstrap';

import { useHistory } from 'react-router-dom';

import Cookies from 'js-cookie';
import url from '../../../api/url';
import axios from 'axios';
import AuthContext from '../../../context/AuthContext';
import './transactions.css'
import { Button, setRef } from '@material-ui/core';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUndo } from '@fortawesome/free-solid-svg-icons'
import DataTable from './DataTable';
import { FETCH_TRANSACTIONS } from '../../../apollo/queries';
import { useQuery } from '@apollo/client';
import PaymentTable from './PaymentTable';
import { IconButton, Snackbar } from '@mui/material';
import { Close } from '@mui/icons-material';
import Toast from '../../../elements/Notifications/Toast'; 



function Transactions({ paymentId, setPaymentId }) {

  const [spinner, setSpinner] = useState(true)
  const { auth, setAuth } = useContext(AuthContext);
  const [transactions, setTransactions] = useState();

  const [originalTransactionList, setOriginalTransactionList] = useState([
    {
      id: "",
      _id: "",
      status: "",
      amount: "",
      created: ""
    }
  ]);

  const [searchFilter, setSearchFilter] = useState('');

  const [refresh, setRefresh] = useState(false);

  const {data, loading, error, refetch} = useQuery(FETCH_TRANSACTIONS, {
    variables: {
      "query": {
        "status": "succeeded"
      },
      "sortBy": "CREATED_DESC",
      "limit": 500
    },
  });

  const refetchData = async() => {
    refetch();

    setRefresh(false);
  }

  useEffect(() => {
    if(refresh) {
      refetchData();
    }
  }, [refresh])

  const sortPayments = async(data) => {
    setTransactions(data.map(data => {

      const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

      let date = new Date(data.created)

      return {
        id: data.payment_id,
        _id: data.customer_vizopay_id,
        status: data.status,
        email: data.customer_email,
        amount: `£${(((data.original_amount * (1 - data.percentage_discount)) - data.points_reduction_amount) / 100).toFixed(2)}`,
        created: `${date.getDate()} ${monthNames[date.getMonth()]} ${date.toLocaleTimeString()}`
      }
    }).reverse());

    setSpinner(false);

    setOriginalTransactionList(data.map(data => {

      const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

      let date = new Date(data.created)


      return {
        id: data.payment_id,
        _id: data.customer_vizopay_id,
        status: data.status,
        email: data.customer_email,
        amount: `£${(((data.original_amount * (1 - data.percentage_discount)) - data.points_reduction_amount)  / 100).toPrecision(4)}`,
        created: `${date.getDate()} ${monthNames[date.getMonth()]} ${date.toLocaleTimeString()}`
      }
    }).reverse());
  }

  useEffect(() => {

    console.log("Fetch transactions again")

    if(error) {
      console.log(error, "error fetching payment gql")
    } else if(loading) {
      console.log("Fetching payments GraphQL...")
    } else {
      setTransactions(data.transactions);
      setOriginalTransactionList(data.transactions);
      setSpinner(false);
    }
  }, [data])


  const searchTable = (rows) => {
    return rows.filter((row) =>
      row.payment_id?.toString().toLowerCase().indexOf(searchFilter) > -1 ||
      row.status?.toString().toLowerCase().indexOf(searchFilter) > -1 ||
      row.customer_email?.toString().toLowerCase().indexOf(searchFilter) > -1 ||
      row.customer_vizopay_id?.toString().toLowerCase().indexOf(searchFilter) > -1 ||
      row.amount?.toString().toLowerCase().indexOf(searchFilter) > -1 ||
      row.created?.toString().toLowerCase().indexOf(searchFilter) > -1
    )
  }

  useEffect(() => {
    if(searchFilter.length) {
      let filter = searchTable(originalTransactionList)
    setTransactions(filter)
    } else {
      return;
    }
  }, [searchFilter])

  return (
    <div style={{display: "inline", width: "100% !important"}} className="section-container">

    {/* <Button onClick={() =>  refetch({
      "query": {
        "status": "succeeded"
      },
      "sortBy": "CREATED_DESC"
    })} mode="filled">Refresh</Button> */}
      {
        spinner ? 
        <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: "100%", width: "100%"}}>
          <Spinner style={{alignSelf: "center", justifySelf: "center", color: "#65ccb8"}} />
        </div>
        :

        // <PaymentTable data={data} />

          <div style={{justifySelf:"flex-start", alignSelf: "flex-start"}} className="card shadow-sm bg-white rounded container-fluid ">
            <Row className="card-header">
              <Col lg="3">
                <h2 className="payments-header">
                  Payments
                </h2>
              </Col>
              <Col lg="9">
                <div>
                  <Input onChange={(e) => setSearchFilter(e.target.value.toLowerCase())} value={searchFilter} style={{ border: 'none' }} size="lg" className="vizopay-input-search" type="text" placeholder="Filter table..." />
                </div>
              </Col>
            </Row>
            <Row>
              {/* <Col lg="2">
        <div className="table-filter-list">
            <Button style={{backgroundColor: statusButton.color}}>Status</Button>
            <Button>
              <div>
                Amount
              </div>
              <div id="change-button-1">
                
              </div>
            </Button>
            <Button>
            <div>
                Customer
              </div>
              <div className="change-button-hidden">
                Change
              </div>
            </Button>
            <Button>
            <div>
                Date
              </div>
              <div className="change-button-hidden">
                Change
              </div>
            </Button>
          
        </div>
      </Col> */}
              <Col style={{padding: '2px'}}>
                <div className="outer-container card-body">
                  <div className="table-container">

                    {/* <DataTable paymentId={paymentId} setPaymentId={setPaymentId} data={searchTable(transactions)} /> */}
                    <PaymentTable refresh={refresh} setRefresh={setRefresh} data={transactions} />
                  </div>
{/* 
                  <div className="table-footer">
                    <div>
                      <span>{searchTable(transactions)? searchTable(transactions).length : transactions.length} <span>results</span></span>
                    </div>
                  </div> */}

                </div>

              </Col>
            </Row>
          </div>


      }
      <Toast severity="success" show={data && !refresh} message="Showing transactions"/>
      
    </div>
  )
}

export default Transactions
