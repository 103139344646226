import React, {useState, useEffect,useContext} from 'react'
import ReactECharts from 'echarts-for-react';
import {makeWidthFlexible, XYPlot, MarkSeries, LineSeries, Crosshair, XAxis, VerticalGridLines, YAxis} from 'react-vis';
import * as d3Shape from 'd3-shape';
import AuthContext from "../../../context/AuthContext"
import {Spinner} from 'reactstrap'

import moment from 'moment'

import Cookies from 'js-cookie';
import url from '../../../api/url';
import axios from 'axios';


function Echarts(props) {

      const [count, setCount] = useState(0);

      function onChartClick(param, echarts) {
    // console.log(param.data, echarts);
    // setCount(count + 1);
  };
    const { auth, setAuth } = useContext(AuthContext);

    const [transactionsYesterday, setTransactionsYesterday] = useState([]);

    const [transactionsToday, setTransactionsToday] = useState([]);

const times = [{x: moment().startOf('day').toDate(), y:0}, {x: moment().endOf('day').toDate(), y: 0}]

    useEffect(() => {

        let token = Cookies.get('token');

        const getTransactions = async() => {
            await axios.get(`${url}/business/data/transactions/volume`, {
                headers: {
                    'x-auth-token': token
                }
            }).then(res => {
                
                setTransactionsYesterday(res.data.yesterday)
                setTransactionsToday(res.data.today)
                
            }).catch(err => 
              {
                console.log(err.response)
                if(err.response.data.message === "jwt expired") {
                  Cookies.remove('token');
                  setAuth(false);
                }

              });
        }

        getTransactions();

    }, [])



    const options = {
     grid: {
        left: 2,
        bottom: 10,
        right: 10,
        containLabel: true
    },
    xAxis: {
      type: 'category',
      show: true,
        // data: transactionsToday.map(d => d.x),
      data: ["00:00", "01:00", '2:00', '3:00', '4:00', '5:00', '6:00', '7:00', '8:00', '9:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00' ,'20:00', '21:00', '22:00', '23:00', '23:59'],
      splitLine:{//remove grid lines
			show:false
		},
                   splitArea : {show : false}
      
    },
    yAxis: {
      type: 'value',
      show: false,
       splitLine:{//remove grid lines
			show: true
		},
                   splitArea : {show : true}
    },
    series: [
      {
        data: transactionsYesterday.map(d => d.y),
        type: 'line',
        smooth: true,
        color: '#65ccb8',
        showSymbol: false
      },
    //   {
    //     data: transactionsYesterday.map(d => d.y),
    //     type: 'line',
    //     smooth: true,
    //     color: '#65ccb8',
    //     showSymbol: false
    //   },
    ],
    tooltip: {
      trigger: 'axis',
    },
  };

    return (
        <div>
            <ReactECharts 
            onEvents={{
          'mouseover': onChartClick,
         }}
             option={options} style={{height: '170px'}}/>
        </div>
    )
}

export default Echarts
