import AppMessage from "./AppMessage";
import { CampaignInformation } from "./CampaignInformation";
import { CampaignRewards } from "./CampaignRewards";
import { CampaignRules } from "./CampaignRules";
import { CampaignSummary } from "./CampaignSummary";

export const WizardData = [
    {
        step: 0,
        component: <CampaignInformation />
    },
    {
        step: 1,
        component: <CampaignRules />
    },
    {
        step: 2,
        component: <CampaignRewards />
    },
    {
        step: 3,
        component: <AppMessage />
    },
    {
        step: 4,
        component: <CampaignSummary /> 
    },
]